/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Divider, Button, Alert,
} from 'antd';
import Rates from '../shared/Rates';

import {
    fetchRateFieldCategories,
    updateRateField,
    fetchRateFields,
    fetchMarina,
    storeRateField,
    storeRateFieldCategory,
    updateMarina,
    deleteRateField,
    deleteRateFieldCategory,
} from '../../actions';

function RatesContainer(props) {
    const {
        updateMarina,
        shouldFetch,
        fetchMarina,
        marinaId,
        fetchRateFieldCategories,
        fetchRateFields,
        marina: {
            id, postcode, rates_freezed, ...rest
        },
        fields,
    } = props;

    useEffect(() => {
        if (shouldFetch) fetchMarina(marinaId);
        fetchRateFields(marinaId);
        fetchRateFieldCategories(marinaId);
    }, []);

    const onRequestComplete = () => {
        fetchRateFieldCategories(marinaId);
        fetchRateFields(marinaId);
    };

    const toggleFreeze = () => {
        updateMarina(marinaId, { ...rest, rates_freezed: !rates_freezed });
    };

    return (
        <>
            <Rates
                {...props}
                {...{ onRequestComplete }}
            />
            {!rates_freezed && fields.data.length ? (
                <div style={{ padding: '16px 24px' }}>
                    <Divider />
                    <Button type="primary" onClick={toggleFreeze}>Freeze Rates</Button>
                </div>
            ) : null}

            {rates_freezed && fields.data.length ? (
                <div style={{ padding: '16px 24px' }}>
                    <Divider />

                    <Alert
                        message="Warning"
                        description="Unfreezing and add/editing fields would possibly break existing rate calculation script. Please make sure to update it as well to align with the field updates."
                        type="warning"
                        showIcon
                        style={{ marginBottom: 10, width: '50%' }}
                    />
                    <Button type="primary" onClick={toggleFreeze} danger>Unfreeze Rates</Button>
                </div>
            ) : null}
        </>
    );
}

function mapStateToProps(state, ownProps) {
    const { marina: marinaId } = ownProps.match.params;
    const {
        marina: { profile: marina },
        rate: {
            categories, fields, field, category,
        },
    } = state;

    return {
        fields,
        categories,
        category,
        field,
        marina,
        canAddFields: true,
        shouldFetch: !marina.id || (marina.id !== +marinaId),
        marinaId: +marinaId,
    };
}
export default connect(mapStateToProps, {
    fetchRateFields,
    fetchMarina,
    fetchRateFieldCategories,
    updateRateField,
    storeRateField,
    storeRateFieldCategory,
    updateMarina,
    deleteRateField,
    deleteRateFieldCategory,
})(RatesContainer);
