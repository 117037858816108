/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParse from 'dayjs/plugin/customParseFormat';
import {
    Button, Form, Row, Col, Input, Divider, message, DatePicker, Modal,
} from 'antd';
import {
    PlusOutlined, SaveOutlined, DeleteOutlined, QuestionCircleOutlined,
} from '@ant-design/icons';
import { updateLock, storeLock, deleteLock } from '../../actions';

dayjs.extend(customParse).extend(utc);

const { confirm } = Modal;
let onDeleteSuccess;
let onDeleteFailed;
const getPromise = () => new Promise((resolve, reject) => {
    onDeleteSuccess = resolve;
    onDeleteFailed = reject;
});

const setFormErrors = (form, error, values) => {
    const { res: { errors: serverErrors, message: msg } } = error;
    message.error(msg);

    if (!serverErrors) return;

    const fields = [];
    Object.keys(serverErrors).forEach((name) => {
        const fieldErrors = serverErrors[name];
        fields.push({
            name,
            value: values[name],
            errors: fieldErrors,
        });
    });
    form.setFields(fields);
};

function LockClosure(props) {
    const {
        marinaId,
        isEdit,
        currentRecord,
        selectedDate,
        lock,
        storeLock,
        updateLock,
        deleteLock,
        onFinish: onComplete,
        onCancel: onEditCancel,
        hasPermission,
    } = props;
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);
    const [values, setValues] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!lock.error) return;
        setFormErrors(form, lock.error, values);
    }, [lock.error]);

    useEffect(() => {
        if (!deleteConfirmed || lock.isFetching) return;
        setDeleteConfirmed(false);
        if (!lock.error) {
            onDeleteSuccess();
            onComplete(true);
        }
        if (lock.error) {
            const { res: { message: msg } } = lock.error;
            message.error(msg);
            onDeleteFailed();
        }
    }, [lock.isFetching]);

    const showConfirm = ({ id }) => (e) => {
        e.preventDefault();

        confirm({
            title: 'Do you want to delete this lock record?',
            icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
            onOk() {
                setDeleteConfirmed(true);
                deleteLock(id);
                return getPromise();
            },
            onCancel(close) {
                setDeleteConfirmed(false);
                close();
            },
        });
    };

    const onFinish = (formValues) => {
        setValues(formValues);
        setSubmitted(true);
        if (isEdit) {
            updateLock(currentRecord.id, { ...formValues });
            return;
        }
        storeLock({ ...formValues, lock_date: formValues.lock_date.format('DD/MM/YYYY') }, marinaId);
    };

    useEffect(() => {
        if (!submitted || lock.isFetching) return;
        setSubmitted(false);
        if (!lock.error) {
            form.resetFields();
            message.success(isEdit ? 'Successfully updated' : 'Successfully created');
            onComplete(true);
        }
    }, [lock.isFetching]);

    useEffect(() => {
        if (!(isEdit && currentRecord)) return;
        const vals = { ...currentRecord, lock_date: dayjs.utc(currentRecord.lock_date) };
        form.setFieldsValue(vals);
    }, [isEdit, currentRecord]);

    useEffect(() => {
        if (isEdit || !selectedDate) return;
        form.setFieldsValue({
            lock_date: selectedDate,
        });
    }, [isEdit, selectedDate]);

    const cancelEdit = () => {
        form.resetFields();
        onEditCancel();
    };

    const LockClosureForm = (
        <Form
            name="lock-closure-form"
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {isEdit ? (
                        <Form.Item label="Lock Date" name="lock_date">
                            <DatePicker style={{ width: '100%' }} disabled={isEdit} format="DD/MM/YYYY" />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label="Lock Date"
                            name="lock_date"
                            rules={[{
                                type: 'object', required: true, message: 'Lock date is required', whitespace: true,
                            }]}
                            hasFeedback
                        >
                            <DatePicker style={{ width: '100%' }} disabled={isEdit} format="DD/MM/YYYY" />
                        </Form.Item>
                    )}
                    <Form.Item label="Lock Open" name="lock_open" hasFeedback>
                        <Input placeholder="Please enter lock open time" />
                    </Form.Item>
                    <Form.Item label="Lock Close" name="lock_close" hasFeedback>
                        <Input placeholder="Please enter lock open time" />
                    </Form.Item>
                    <Form.Item label="Stop Gate Open" name="lock_1_open" hasFeedback>
                        <Input placeholder="Please enter Stop gate open time" />
                    </Form.Item>
                    <Form.Item label="Stop Gate Close" name="lock_1_close" hasFeedback>
                        <Input placeholder="Please enter Stop gate close time" />
                    </Form.Item>
                    <Form.Item label="Notes" name="notes" hasFeedback>
                        <Input.TextArea placeholder="Please enter Stop gate close time" />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    {isEdit && hasPermission('delete.locks') ? (
                        <Button type="danger" onClick={showConfirm(currentRecord)}>
                            <DeleteOutlined />
                            {' '}
                            Delete
                        </Button>
                    ) : null}
                </div>
                <div>
                    {isEdit ? (
                        <Button type="danger" style={{ marginRight: 5 }} onClick={cancelEdit}>
                            Cancel
                        </Button>
                    ) : null}
                    <Button type="primary" htmlType="submit" loading={lock.isFetching}>
                        {isEdit ? <SaveOutlined /> : <PlusOutlined />}
                        {' '}
                        {isEdit ? 'Update' : 'Add'}
                    </Button>
                </div>
            </div>
        </Form>
    );

    return LockClosureForm;
}

const mapStateToProps = (state, ownProps) => {
    const { lock } = state;
    const { currentRecord, isEdit } = ownProps;

    return {
        lock,
        currentRecord,
        isEdit,
        ...ownProps,
    };
};

export default connect(mapStateToProps, {
    updateLock, storeLock, deleteLock,
})(LockClosure);
