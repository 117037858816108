import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParse from 'dayjs/plugin/customParseFormat';
import {
    Button, Form, Row, Col, Input, Divider, message, Badge,
} from 'antd';
import {
    SendOutlined,
} from '@ant-design/icons';
import { sendNotification } from '../../actions';

dayjs.extend(customParse).extend(utc);

const setFormErrors = (form, error, values) => {
    const { res: { errors: serverErrors, message: msg } } = error;
    message.error(msg);

    if (!serverErrors) return;

    const fields = [];
    Object.keys(serverErrors).forEach((name) => {
        const fieldErrors = serverErrors[name];
        fields.push({
            name,
            value: values[name],
            errors: fieldErrors,
        });
    });
    form.setFields(fields);
};

const makeProperCase = (text) => text.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

function NewNotification(props) {
    const {
        notification,
        sendNotification,
        onFinish: onComplete,
    } = props;
    const [values, setValues] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [titleCount, setTitleCount] = useState(60);
    const [bodyCount, setBodyCount] = useState(200);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!notification.error) return;
        setFormErrors(form, notification.error, values);
    }, [notification.error]);

    const onFinish = (formValues) => {
        setValues(formValues);
        setSubmitted(true);
        sendNotification({ ...formValues });
    };

    useEffect(() => {
        if (!submitted || notification.isFetching) return;
        setSubmitted(false);
        if (!notification.error) {
            setTitleCount(60);
            setBodyCount(200);
            form.resetFields();
            message.success('Successfully sent');
            onComplete(true);
        }
    }, [notification.isFetching]);

    const updateCount = (fn, max) => (e) => {
        fn(() => max - e.target.value.length);
    };

    const NewNotificationForm = (
        <Form
            name="new-notification-form"
            form={form}
            layout="vertical"
            onFinish={onFinish}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        label={(
                            <span>
                                Title
                                {' '}
                                <Badge count={titleCount} showZero />
                            </span>
                        )}
                        name="title"
                        rules={[{
                            required: true,
                            message: 'Title is required',
                            whitespace: true,
                        }, {
                            message: 'Title cannot be more than 60 letters',
                            max: 60,
                        }]}
                        normalize={makeProperCase}
                        hasFeedback
                    >
                        <Input
                            placeholder="Please enter notification title"
                            onChange={updateCount(setTitleCount, 60)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={(
                            <span>
                                Body
                                {' '}
                                <Badge count={bodyCount} showZero />
                            </span>
                        )}
                        name="message"
                        rules={[{
                            required: true,
                            message: 'Message body is required',
                            whitespace: true,
                        }, {
                            message: 'Body cannot be more than 200 letters',
                            max: 200,
                        }]}
                        hasFeedback
                    >
                        <Input.TextArea
                            rows={6}
                            placeholder="Please enter the message body"
                            onChange={updateCount(setBodyCount, 200)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <div style={{ float: 'right' }}>
                <Button type="primary" htmlType="submit" loading={notification.isFetching}>
                    <SendOutlined />
                    {' '}
                    Send
                </Button>
            </div>
        </Form>
    );

    return NewNotificationForm;
}

const mapStateToProps = (state, ownProps) => {
    const { notification, me: { marina_id: marinaId } } = state;

    return {
        notification,
        marinaId,
    };
};

export default connect(mapStateToProps, {
    sendNotification,
})(NewNotification);
