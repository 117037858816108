import {
    STATION_REQUEST,
    STATION_SUCCESS,
    STATION_FAILURE,
    DELETE_STATION_REQUEST,
    DELETE_STATION_SUCCESS,
    DELETE_STATION_FAILURE,
    CLEAR_STATION,
} from '../actions';

export const STATION_INITIAL_STATE = {
    id: null,
    station_id: null,
    name: '',
    country: '',
    continuous_height: '',
    footnote: '',
    latitude: '',
    longitude: '',
    sync_range_from: '',
    sync_range_to: '',
    isFetching: false,
    error: null,
};

const stationReducers = {
    [STATION_REQUEST]: (state, { id }) => ({
        ...state, id, isFetching: true, error: null,
    }),
    [STATION_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [STATION_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [DELETE_STATION_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [DELETE_STATION_SUCCESS]: (state) => ({ ...STATION_INITIAL_STATE }),
    [DELETE_STATION_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [CLEAR_STATION]: () => ({ ...STATION_INITIAL_STATE }),
};

const stationReducer = (state = STATION_INITIAL_STATE, action) => {
    const reducer = stationReducers[action.type] || ((state) => state);
    return reducer(state, action);
};

export default stationReducer;
