import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
    SyncOutlined, DownloadOutlined,
} from '@ant-design/icons';
import {
    message, PageHeader, Button, DatePicker,
} from 'antd';
import PaginatedList from '../shared/PaginatedList';
import {
    fetchTides, syncTides, clearTides, fetchStation,
} from '../../actions';

const { RangePicker } = DatePicker;
dayjs.extend(customParse).extend(utc).extend(timezone);

function Tides(props) {
    const [syncStarted, setSyncStarted] = useState(false);
    const [searchRange, setSearchRange] = useState([]);
    const [canSync, setCanSync] = useState(false);
    const [currentRange, setCurrentRange] = useState([]);
    const {
        fetchTides,
        syncTides,
        clearTides,
        tides,
        history,
        station,
        stationId,
        fetchStation,
    } = props;
    const { sync_range_from: currentFrom, sync_range_to: currentTo } = station;

    let stationName = '';
    if (station.name) {
        stationName = `${station.name}, ${station.country}`;
    }

    useEffect(() => {
        if (!(currentFrom && currentTo)) {
            setCurrentRange([]);
            return;
        }
        setCurrentRange([
            dayjs(currentFrom).tz('Europe/London').format('DD/MM/YYYY'),
            dayjs(currentTo).tz('Europe/London').format('DD/MM/YYYY'),
        ]);
    }, [currentFrom, currentTo]);

    useEffect(() => {
        if (!syncStarted || (syncStarted && tides.isFetching)) return;
        if (syncStarted && !tides.isFetching && !tides.error) {
            setSyncStarted(false);
        }
        if (syncStarted && tides.error) {
            setSyncStarted(false);
            message.error("Error in sync'ing from Admiralty");
        }
    }, [syncTides, syncStarted, tides.isFetching, fetchTides, tides.error]);

    useEffect(() => {
        if ((station.id === stationId) || station.isFetching) return;
        fetchStation(stationId);
    }, [fetchStation, station.id, station.isFetching, stationId]);

    const columns = [{
        title: 'Day',
        key: 'day',
        render: (text, record) => dayjs(record.tide_date).tz('Europe/London').format('dddd'),
    }, {
        title: 'Date',
        dataIndex: 'tide_date',
        render: (text, record) => dayjs(record.tide_date).tz('Europe/London').format('DD/MM/YYYY'),
    }, {
        title: 'Time',
        dataIndex: 'tide_date',
        render: (text, record) => dayjs(record.tide_date).tz('Europe/London').format('HH:mm'),
    }, {
        title: 'Tide Height',
        dataIndex: 'tide_height',
    }, {
        title: 'Tide State',
        dataIndex: 'event_type',
        render: (text) => (text === 'HighWater' ? 'High Water' : 'Low Water'),
    }];

    const sync = (e) => {
        e.preventDefault();
        setSyncStarted(true);
        if (!station) return;
        const [from, to] = searchRange;
        setCurrentRange([from, to]);
        clearTides();
        syncTides(station.id, from, to);
    };
    const fetchData = () => (page) => fetchTides(stationId, page);
    const onDateChange = (value) => {
        if (!(value && value.length)) {
            setCanSync(false);
            return;
        }
        const [from, to] = value;
        setCanSync(true);
        setSearchRange([dayjs(from).format('DD/MM/YYYY'), dayjs(to).format('DD/MM/YYYY')]);
    };
    const defaultExtra = [
        <Button key="3" type="primary" disabled={!tides.data.length}>
            <a href={`/stations/${stationId}/tides/csv`}>
                <DownloadOutlined />
                {' '}
                CSV
            </a>
        </Button>,
        <RangePicker key="2" onChange={onDateChange} format="DD/MM/YYYY" placeholder={['from', 'To']} />,
        <Button key="1" type="primary" onClick={sync} disabled={!(station && canSync)}>
            Sync from Admiralty
            {' '}
            <SyncOutlined spin={syncStarted} />
        </Button>,
    ];
    const getCurrentRange = () => {
        if (!currentRange.length) return '';
        const [from, to] = currentRange;
        return `${from} - ${to}`;
    };
    return (
        <PageHeader
            title={`Tides of ${stationName}`}
            className="tides-page-header"
            onBack={() => history.goBack()}
            subTitle={getCurrentRange()}
            extra={defaultExtra}
        >
            <PaginatedList
                fetchData={fetchData()}
                columns={columns}
                source={tides.data}
                {...props}
            />
        </PageHeader>

    );
}

function mapStateToProps(state, ownProps) {
    const { station: stationId } = ownProps.match.params;
    const { stations: { data: stations } } = state;
    const station = stations.find((s) => s.id === +stationId);

    return {
        loading: state.tides.isFetching,
        tides: state.tides,
        station: station || state.station,
        meta: state.tides.meta,
        stationId: +stationId,
    };
}
export default connect(mapStateToProps, {
    fetchTides, syncTides, clearTides, fetchStation,
})(Tides);
