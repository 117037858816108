import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    withRouter,
    NavLink,
} from 'react-router-dom';
import {
    Form, Input, Button, message, Result,
} from 'antd';

import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { fetchTokenData, signupUser } from '../actions';

const FormItem = Form.Item;

function Signup(props) {
    const [values, setValues] = useState(null);
    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);
    const {
        token: {
            name, email, isFetching, error: tokenError,
        },
        location: { search },
        fetchTokenData,
        signupUser,
        signup: { error, isFetching: signingUp },
        history,
    } = props;
    const [full, signupToken] = search.match(/token=(\w+)/) || ['', ''];
    const { errors, message: msg } = error;

    useEffect(() => {
        if (!signupToken) return;
        fetchTokenData(signupToken);
    }, [signupToken]);

    useEffect(() => {
        if ((submitted && signingUp) || !submitted) return;
        const { password, password_confirmation } = values;
        if (submitted && msg) {
            form.setFields([{
                name: 'password',
                value: password,
                errors: [msg],
            },
            {
                name: 'password_confirmation',
                value: password_confirmation,
                errors: [msg],
            }]);
            return;
        }
        setSubmitted(false);
        message.success('Signup Success, please login');
        setTimeout(() => {
            history.replace('/login');
        }, 1000);
    }, [submitted, signingUp, msg]);

    const onFinish = (formValues) => {
        setValues(formValues);
        signupUser({ ...formValues, token: signupToken });
        setSubmitted(true);
    };

    if (isFetching) return null;
    if (tokenError) {
        return (
            <Result
                status="warning"
                title="There are some problems accessing this page."
                subTitle="Probably the token is expired or invalid. Please contact administrator for futher information."
                extra={(
                    <NavLink to="/">
                        <Button type="primary" key="console">
                            Go Home
                        </Button>
                    </NavLink>
                )}
            />
        );
    }

    return (
        <div className="login-form">
            <NavLink to="/">
                <div className="login-logo">
                    <img src="/img/logo.jpg" alt="logo" />
                </div>
            </NavLink>
            <Form onFinish={onFinish} form={form}>
                <FormItem>
                    <Input disabled prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} value={email} />
                </FormItem>
                <FormItem
                    name="first_name"
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please input your First Name!' },
                    ]}
                >
                    <Input placeholder="First Name" />
                </FormItem>
                <FormItem
                    name="last_name"
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please input your Last Name!' },
                    ]}
                >
                    <Input placeholder="Last Name" />
                </FormItem>
                <FormItem
                    name="password"
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please input your Password!' },
                        { message: 'At least 8 characters!', min: 8 },
                    ]}
                >
                    <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" />
                </FormItem>
                <FormItem
                    name="password_confirmation"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please confirm your Password!' },
                        { message: 'At least 8 characters!', min: 8 },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Passwords do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirm Password" />
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                        Sign Up
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
}

const mapStateToProps = (state) => ({ token: { ...state.token }, signup: { ...state.signup } });
export default withRouter(connect(mapStateToProps, { fetchTokenData, signupUser })(Signup));
