import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import {
    storeSponsor, updateSponsor, fetchSponsor, resetSponsor,
} from '../../actions';
import Sponsor from './Sponsor';

function SponsorContainer(props) {
    const {
        setExtras,
        setTitle,
        isNew,
        sponsor,
        fetchSponsor,
        storeSponsor,
        updateSponsor,
        resetSponsor,
        history,
        match: { params: { sponsor: sponsorId, marina: marinaId } },
    } = props;
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setExtras([null]);
    }, [setExtras]);
    useEffect(() => {
        if (isNew) {
            setTitle('Create New Sponsor');
        } else {
            setTitle('Edit Sponsor');
        }
    }, [setTitle]);

    useEffect(() => {
        if (isNew && sponsor.id && !submitted) {
            resetSponsor();
            return;
        }

        if (isNew || (sponsor.id && sponsor.id === +sponsorId) || sponsor.isFetching) return;

        fetchSponsor(sponsorId);
    }, [sponsor.id]);

    useEffect(() => {
        if (!submitted || sponsor.isFetching) return;
        if (!sponsor.error) {
            if (isNew) {
                message.success('Successfully created');
                setTimeout(() => history.goBack(), 100);
                return;
            }
            if (!isNew) {
                setSubmitted(false);
                message.success('Successfully updated');
                setTimeout(() => history.goBack(), 100);
            }
        }
    }, [sponsor.isFetching]);

    const onFinish = (payload) => {
        setSubmitted(true);

        const fn = isNew ? storeSponsor : updateSponsor;
        const args = isNew ? [{ ...payload, marina_id: marinaId }] : [sponsorId, { ...payload }];
        return fn(...args);
    };

    const prosToPass = {
        isNew,
        marinaId,
        sponsor,
        onSubmit: onFinish,
    };

    // fetching in progress
    if (!isNew && !sponsor.id) {
        return null;
    }

    // navigating to new after editing another one
    // reset before render
    if (isNew && sponsor.id && !submitted) {
        return null;
    }

    return <Sponsor {...prosToPass} />;
}

const mapStateToProps = (state, ownProps) => {
    const { marina: { sponsor } } = state;

    return {
        ...ownProps,
        sponsor,
    };
};

export default connect(mapStateToProps, {
    storeSponsor, fetchSponsor, updateSponsor, resetSponsor,
})(SponsorContainer);
