import {
    ME_REQUEST,
    ME_SUCCESS,
    ME_FAILURE,
    RESET_ME,
} from '../actions';

export const ME_INITIAL_STATE = {
    name: null,
    email: null,
    first_name: null,
    last_name: null,
    scope: null,
    role: null,
    marina_id: null,
    isFetching: false,
    error: null,
};

const meReducers = {
    [ME_REQUEST]: (state) => ({
        ...ME_INITIAL_STATE,
        isFetching: true,
    }),
    [ME_SUCCESS]: (state, {
        res,
    }) => ({
        ...state,
        ...res,
        isFetching: false,
        error: null,
    }),
    [ME_FAILURE]: (state, { error }) => ({
        ...state,
        isFetching: false,
        error: { message: error.res.message, status: error.status },
    }),
    [RESET_ME]: (state) => ({
        ...ME_INITIAL_STATE,
    }),
};

export default function auth(state = ME_INITIAL_STATE, action) {
    const reducer = meReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
