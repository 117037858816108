import React, { useState, useEffect } from 'react';
import { Upload as AntUpload, message, Typography } from 'antd';
import { LoadingOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export default function Upload(props) {
    const [loading, setLoading] = useState(false);
    const [fileLocation, setFileLocation] = useState(null);
    const [fileList, setFileList] = useState([]);
    const {
        label, type, url, onUploadComplete, showUploadList = false, extra = {},
    } = props;
    let options = false;
    if (showUploadList) {
        options = {
            showPreviewIcon: false,
            showDownloadIcon: true,
            showRemoveIcon: false,
        };
    }

    useEffect(() => {
        if (!url) return;
        setFileLocation(url);
        onUploadComplete({ url }, true);

        if (showUploadList) {
            setFileList([{
                uid: 1, url, name: 'Lock Closures', status: 'done',
            }]);
        }
    }, [url]);

    const handleChange = ({ file, fileList }) => {
        const cloneList = [...fileList];
        setFileList(cloneList);
        if (file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (file.status === 'done') {
            // Get this url from response in real world.
            getBase64(file.originFileObj, (imageUrl) => {
                setLoading(false);
                setFileLocation(imageUrl);
            });
            if (showUploadList) {
                setFileList([{ uid: 1, url: file.response.url, name: 'Lock Closures' }]);
            }
            onUploadComplete(file.response, false);
        }
    };

    const onRemoveFile = (event) => {
        console.log(event);
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Upload</div>
        </div>
    );
    const preview = () => {
        if (type === 'image' && fileLocation) {
            return <img src={fileLocation} alt={label} style={{ width: '100%' }} />;
        }

        return uploadButton;
    };

    const propsToPass = {};
    if (showUploadList) {
        propsToPass.fileList = fileList;
        propsToPass.showUploadList = options;
    } else {
        propsToPass.showUploadList = false;
    }
    return (
        <div>
            <Text>{label}</Text>
            <AntUpload
                name="marina_file"
                listType="picture-card"
                className="avatar-uploader"
                action="/api/file/store"
                onChange={handleChange}
                withCredentials
                onRemove={onRemoveFile}
                data={extra}
                {...propsToPass}
            >
                {preview()}
            </AntUpload>
        </div>
    );
}
