import {
    combineReducers,
} from 'redux';

import auth from './auth';
import me from './me';
import marinas from './marinas';
import marina from './marina';
import locations from './locations';
import stations from './stations';
import station from './station';
import tides from './tides';
import businessTypes from './businessTypes';
import businessType from './businessType';
import token from './token';
import signup from './signup';
import user from './user';
import locks from './locks';
import rate from './rate';
import lock from './lock';
import notifications from './notifications';
import notification from './notification';
import resetPassword from './reset-password';
import checkout from './checkout';

const rootReducer = combineReducers({
    auth,
    me,
    marinas,
    marina,
    locations,
    stations,
    station,
    tides,
    businessTypes,
    businessType,
    token,
    signup,
    user,
    locks,
    rate,
    lock,
    notification,
    notifications,
    resetPassword,
    checkout,
});

export default rootReducer;
