/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
    PageHeader, List, Col, Row, Card, BackTop,
} from 'antd';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import NewNotification from './NewNotification';
import { fetchNotifications } from '../../actions';

dayjs.extend(customParse);

function Notifications({
    match: { url },
    history,
    location: { search },
    notifications: {
        data,
        meta,
        isFetching,
        error,
    },
    fetchNotifications,
}) {
    const [full, page] = search.match(/page=(\d+)/) || ['', null];
    const [currentPage, setCurrentPage] = useState(+page);

    useEffect(() => {
        if (!page) setCurrentPage(1);
    }, [page]);

    useEffect(() => {
        if (!currentPage) return;
        history.push(`${url}?page=${currentPage}`);
        fetchNotifications(currentPage);
    }, [currentPage]);

    const onFinish = () => {
        if (currentPage === 1) return fetchNotifications(1);
        return setCurrentPage(1);
    };

    return (
        <PageHeader
            title="Notifications"
            className="site-page-header"
            onBack={() => history.goBack()}
        >
            <Row gutter={[16, 16]}>
                <Col xl={16} lg={14}>
                    <BackTop />
                    <List
                        loading={isFetching}
                        size="small"
                        bordered
                        itemLayout="vertical"
                        pagination={{
                            onChange: (page) => {
                                setCurrentPage(page);
                            },
                            current: currentPage,
                            pageSize: 5,
                            total: meta.total,
                        }}
                        dataSource={data}
                        renderItem={({
                            title, message, sent_by: { first_name, last_name, email }, created_at: createAt,
                        }) => (
                            <List.Item
                                actions={[
                                    <span>
                                        By :
                                        {' '}
                                        {[first_name, last_name].join(' ')}
                                        {' < '}
                                        {email}
                                        {' > '}
                                    </span>,
                                    <span>
                                        On :
                                        {' '}
                                        {dayjs(createAt).format('DD/MM/YYYY h:mm:ss A')}
                                    </span>,
                                ]}
                            >
                                <List.Item.Meta
                                    title={title}
                                    description={message}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <Col xl={8} lg={10}>
                    <Card>
                        <NewNotification onFinish={onFinish} />
                    </Card>
                </Col>
            </Row>
        </PageHeader>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { notifications } = state;

    return {
        notifications,
    };
};

export default connect(mapStateToProps, {
    fetchNotifications,
})(Notifications);
