import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Table, Menu, Drawer, Button, DatePicker, Form, Row, Col, Badge, Input, Divider, Checkbox, message, Switch, Typography, Collapse } from 'antd';
import { BarsOutlined, RiseOutlined, ClockCircleOutlined, FileDoneOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const { Text } = Typography;
const { Panel } = Collapse;

class HouseDiary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locks: [],
            tides: [],
            bookVisits: [],
            lockScheduleBookVisits: [],
            swingBookings: [],
            lockSchedules: [],

            selectedDate: new Date(),
            // selectedDate: 'Thu Sept 30 2020 14:42:31 GMT+0530 (India Standard Time)',
            // selectedDate: 'Thu Apr 06 2022 14:42:31 GMT+0530 (India Standard Time)',
            selectedLockSchedule: null,
            selectedLockScheduleType: null,
            drawerVisible: false,
        };

        this.getLocks = this.getLocks.bind(this);
        this.getTides = this.getTides.bind(this);
        this.getVisitLockScehdules = this.getVisitLockScehdules.bind(this);
        this.getBookVisits = this.getBookVisits.bind(this);
        this.getLockScheduleBookVisits = this.getLockScheduleBookVisits.bind(this);
        this.getSwingBookings = this.getSwingBookings.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.filterRecords = this.filterRecords.bind(this);
    }

    closeDrawer() {
        this.setState({
            drawerVisible: false
        });
    };
    openDrawer(date, time, type) {
        this.setState({
            drawerVisible: true,
            selectedLockSchedule: time,
            selectedLockScheduleType: type
        });
        this.getLockScheduleBookVisits(date, time, type);
    };


    getLocks() {
        let locks = [];
        // return axios.get('/api/lock-closures?page=1&query=' + (moment(this.state.selectedDate).format("DD/MM/YYYY"))).then((
        return axios.get('/api/house_diary/get_lock_closures?date=' + (moment(this.state.selectedDate).format("YYYY-MM-DD"))).then((
            response
        ) => {
            if (response.data.length) {
                // locks = response.data.data;
                locks = response.data;
            }
            this.setState({
                locks: locks
            });
        });
    }

    getTides() {
        let tides = [];
        // return axios.post('/api/tides?page=1&station_id=345&query=' + (moment(this.state.selectedDate).format("DD/MM/YYYY"))).then((
        return axios.get('/api/house_diary/get_tides?date=' + (moment(this.state.selectedDate).format("YYYY-MM-DD"))).then((
            response
        ) => {
            if (response.data.length) {
                // tides = response.data.data;
                tides = response.data;
                this.getVisitLockScehdules(tides);
            }
            this.setState({
                tides: tides
            });
        });
    }

    getVisitLockScehdules(tides) {
        let lockSchedules = [];
        return axios.post('/api/house_diary/get_book_visit_lock_scehdules', {
            tides: tides
        }).then((
            response
        ) => {
            if (response.data.length) {
                lockSchedules = response.data;
            }
            this.setState({
                lockSchedules: lockSchedules
            });

        });
    }

    getBookVisits() {
        let bookVisits = [];
        return axios.post('/api/book_visit/get_book_visits', {
            day: moment(this.state.selectedDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                bookVisits = response.data;
            }
            this.setState({
                bookVisits: bookVisits
            });
        });
    }

    getLockScheduleBookVisits(date, time, type) {
        let lockScheduleBookVisits = [];
        return axios.post('/api/house_diary/get_book_visits', {
            date: moment(date).format("YYYY-MM-DD"),
            lock_time: time,
            type: type
        }).then((
            response
        ) => {
            if (response.data.length) {
                lockScheduleBookVisits = response.data
            }
            this.setState({
                lockScheduleBookVisits: lockScheduleBookVisits
            });
        });
    }

    getSwingBookings() {
        let swingBookings = [];
        let columns = [];
        return axios.post('/api/swings/get_swing_bookings', {
            day: moment(this.state.selectedDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                swingBookings = response.data;
            }
            this.setState({
                swingBookings: swingBookings,
            });
        });
    }

    filterRecords(e) {
        this.setState({
            selectedDate: e,

        }, () => {
            // alert(e)
            this.getLocks();
            this.getTides();
            this.getSwingBookings();
            this.getBookVisits();
        });

    };


    componentDidMount() {
        this.getLocks();
        this.getTides();
        this.getSwingBookings();
        this.getBookVisits();
    }

    render() {

        return (

            <div style={{
                marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10
            }}
            >

                <Menu mode="horizontal" style={{ textAlign: 'center' }}>
                    <Menu.Item key="app0" >
                        <DatePicker value={moment(this.state.selectedDate)} format="YYYY-MM-DD" onChange={this.filterRecords} />
                    </Menu.Item>
                    <Menu.Item key="app1" >
                        {dayjs(this.state.selectedDate).tz('Europe/London').format('dddd')}
                    </Menu.Item>

                    {/* <Menu.Item key="app1" icon={<BarsOutlined />} onClick={this.getLocks}>
                        Lock Closures
                    </Menu.Item>
                    <Menu.Item key="app2" icon={<RiseOutlined />} onClick={this.getTides}>
                        Tides Times
                    </Menu.Item>
                    <Menu.Item key="app3" icon={<ClockCircleOutlined />} onClick={this.getSwingBookings}>
                        Swing Bookings
                    </Menu.Item>
                    <Menu.Item key="app4" icon={<FileDoneOutlined />} onClick={this.getBookVisits}>
                        Book Visits
                    </Menu.Item> */}
                </Menu>

                <Collapse defaultActiveKey={['1', '2', '3', '4']} >
                    <Panel header={"Locks: " + dayjs(this.state.selectedDate).tz('Europe/London').format('dddd') + " " + dayjs(this.state.selectedDate).tz('Europe/London').format('DD-MM-YYYY')} key="1">
                        {/* <BarsOutlined style={{ fontSize: 30 }} /> */}
                        <Row>
                            <Col span={24} >
                                {this.state.locks.length ?
                                    <Row>
                                        <Col lg={12} xs={24} sm={24}  >
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <h3 style={{ color: '#1890ff', borderBottom: '1px solid #1890ff', paddingBottom: '5px' }}>Lock Activity</h3>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        {this.state.locks.map((lock, index1) => {
                                                            return <tr key={index1}>
                                                                <td>{index1 + 1}. Commences: {lock.lock_open}</td>
                                                                <td>Ceases: {lock.lock_close}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                        <Col lg={12} xs={24} sm={24} >
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <h3 style={{ color: '#1890ff', borderBottom: '1px solid #1890ff', paddingBottom: '5px' }}>Stop Gate</h3>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        {this.state.locks.map((lock, index1) => {
                                                            return <tr key={index1}>
                                                                <td>{index1 + 1}. Starts: {lock.lock_1_open}</td>
                                                                <td>Ends: {lock.lock_1_close}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                        {/* {this.state.locks.map((lock, index1) => {
                                        return <Col lg={12} xl={8}  >
                                            <div key={index1} style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <span style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '10px', backgroundColor: '#1890ff', color: '#fff', padding: '2px 3px' }}>{index1 + 1}</span>
                                                <table>
                                                    <tr>
                                                        <td>Date: </td>
                                                        <td>{lock.day} {lock.date}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Lock Activity: </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lock Open/Close: </td>
                                                        <td>{lock.lock_open} - {lock.lock_close}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Stop Gate Open/Close: </td>
                                                        <td>{lock.lock_1_open} - {lock.lock_1_close}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </Col>
                                    })} */}
                                    </Row>
                                    : <Text type="danger">No locks available for selected date</Text>}

                                {this.state.lockSchedules.length ?
                                    <Row>
                                        <Col lg={24} xs={24} sm={24}  >
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <h3 style={{ color: '#1890ff', borderBottom: '1px solid #1890ff', paddingBottom: '5px' }}>Lock Schedules</h3>
                                                <table style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '30%', padding: '6px 0px' }}>Lock Time</th>
                                                            <th style={{ textAlign: 'center', width: '30%' }}>Arrival Count</th>
                                                            <th style={{ textAlign: 'center', width: '40%' }}>Departure Count</th>
                                                        </tr>
                                                    </thead>

                                                    {this.state.lockSchedules.map((schedule, index) => {
                                                        return <tbody key={index} style={{ width: '100%' }}>
                                                            <tr>
                                                                <td style={{ width: '30%', padding: '6px 0px' }}>{schedule.lock_date1.time}</td>
                                                                <td style={{ width: '30%' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date1.time, 1)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                                    <Badge count={schedule.lock_date1.arrival_lock_visit_count ? schedule.lock_date1.arrival_lock_visit_count : '-'}
                                                                        style={schedule.lock_date1.arrival_lock_visit_count ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                                <td style={{ width: '40%' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date1.time, 2)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                                    <Badge count={schedule.lock_date1.departure_lock_visit_count ? schedule.lock_date1.departure_lock_visit_count : '-'}
                                                                        style={schedule.lock_date1.departure_lock_visit_count ? { backgroundColor: '#1890ff' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '30%', padding: '6px 0px' }}>{schedule.lock_date2.time}</td>
                                                                <td style={{ width: '30%' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date2.time, 1)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                                    <Badge count={schedule.lock_date2.arrival_lock_visit_count ? schedule.lock_date2.arrival_lock_visit_count : '-'}
                                                                        style={schedule.lock_date2.arrival_lock_visit_count ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                                <td style={{ width: '40%' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date2.time, 2)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                                    <Badge count={schedule.lock_date2.departure_lock_visit_count ? schedule.lock_date2.departure_lock_visit_count : '-'}
                                                                        style={schedule.lock_date2.departure_lock_visit_count ? { backgroundColor: '#1890ff' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '30%', padding: '6px 0px' }}>{schedule.lock_date3.time}</td>
                                                                <td style={{ width: '30%' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date3.time, 1)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                                    <Badge count={schedule.lock_date3.arrival_lock_visit_count ? schedule.lock_date3.arrival_lock_visit_count : '-'}
                                                                        style={schedule.lock_date3.arrival_lock_visit_count ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                                <td style={{ width: '40%' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date3.time, 2)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                                    <Badge count={schedule.lock_date3.departure_lock_visit_count ? schedule.lock_date3.departure_lock_visit_count : '-'}
                                                                        style={schedule.lock_date3.departure_lock_visit_count ? { backgroundColor: '#1890ff' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    })}
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                    : <Text type="danger">No lock schedules available for selected date</Text>}
                            </Col>
                        </Row>
                    </Panel>
                    <Panel header={"Tides: " + dayjs(this.state.selectedDate).tz('Europe/London').format('dddd') + " " + dayjs(this.state.selectedDate).tz('Europe/London').format('DD-MM-YYYY')} key="2">
                        {/* <RiseOutlined style={{ fontSize: 30 }} /> */}
                        {this.state.tides.length ?
                            <Row >
                                <Col lg={24} xs={24} sm={24} >
                                    <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                {this.state.tides.map((tide, index2) => {
                                                    return <tr key={index2}>
                                                        <td>{index2 + 1}. {tide.event_type === 'HighWater' ? 'High Water' : 'Low Water'}</td>
                                                        <td style={{ color: '#000000' }}>{tide.time}</td>
                                                        <td style={{ color: '#1890ff' }}>{tide.tide_height}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                            : <Text type="danger">No tides available for selected date</Text>}
                    </Panel>
                    <Panel header={"Swing Booking: " + dayjs(this.state.selectedDate).tz('Europe/London').format('dddd') + " " + dayjs(this.state.selectedDate).tz('Europe/London').format('DD-MM-YYYY')} key="3">
                        {/* <ClockCircleOutlined style={{ fontSize: 30 }} /> */}
                        <Row >
                            <Col span={24} >
                                <Row>
                                    {!this.state.swingBookings.length ? <Text type="danger">No swing booking available for selected date</Text> : ''}
                                    {this.state.swingBookings.map((swingBooking, index3) => {
                                        return <Col lg={12} xl={8} xs={24} sm={24} key={index3} >
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <span style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '10px', backgroundColor: '#1890ff', color: '#fff', padding: '2px 3px' }}>{index3 + 1}</span>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Date: </td>
                                                            <td>{swingBooking.day} @ {swingBooking.opened_time}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Travel Direction: </td>
                                                            <td>{swingBooking.travel_direction}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Vessel Name: </td>
                                                            <td>{swingBooking.vessel_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone: </td>
                                                            <td>{swingBooking.phone}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </Col>
                                    })}
                                </Row>
                            </Col>
                        </Row>

                    </Panel>

                    <Panel header={"Booked Visits: " + dayjs(this.state.selectedDate).tz('Europe/London').format('dddd') + " " + dayjs(this.state.selectedDate).tz('Europe/London').format('DD-MM-YYYY')} key="4">
                        {/* <FileDoneOutlined style={{ fontSize: 30 }} /> */}
                        <Row >
                            <Col span={24} >

                                {!this.state.bookVisits.length ? <Text type="danger">No booked visits available for selected date</Text> : ''}
                                <Row>
                                    {this.state.bookVisits.map((bookVisit, index4) => {
                                        return <Col lg={12} xl={8} xs={24} sm={24} key={index4}>
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <span style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '10px', backgroundColor: '#1890ff', color: '#fff', padding: '2px 3px' }}>{index4 + 1}</span>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Boat Length: </td>
                                                            <td>{bookVisit.boat_length} {bookVisit.length_unit}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Air Craft: </td>
                                                            <td>{bookVisit.air_draft}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Boat Name: </td>
                                                            <td>{bookVisit.boat_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Arriving From: </td>
                                                            <td>{bookVisit.arriving_from}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Arrival Date: </td>
                                                            <td>{dayjs(bookVisit.arrival_date).tz('Europe/London').format('DD/MM/YYYY')},
                                                                {dayjs(bookVisit.arrival_date).tz('Europe/London').format('dddd')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Departure Date: </td>
                                                            <td>{dayjs(bookVisit.departure_date).tz('Europe/London').format('DD/MM/YYYY')},
                                                                {dayjs(bookVisit.departure_date).tz('Europe/London').format('dddd')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>No of Days: </td>
                                                            <td>{bookVisit.number_of_days}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cost: </td>
                                                            <td>{bookVisit.cost}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Name: </td>
                                                            <td>{bookVisit.title + '. ' + bookVisit.first_name + ' ' + bookVisit.surname}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone: </td>
                                                            <td>{bookVisit.phone}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </Col>
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>,
                {/* <Table dataSource={this.state.dataSource} columns={this.state.columns} />; */}

                <Drawer
                    title={'Booked Visits on ' + moment(this.state.selectedDate).format("YYYY-MM-DD") + (this.state.selectedLockScheduleType == 1 ? ', Arrival Lock @ ' : ' Departure Lock @ ') + this.state.selectedLockSchedule}
                    width={500}
                    onClose={this.closeDrawer}
                    visible={this.state.drawerVisible}
                    maskClosable={true}
                    destroyOnClose>
                    {this.state.lockScheduleBookVisits.length ? (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Collapse bordered={false} className="site-collapse-custom-collapse ">
                                    {this.state.lockScheduleBookVisits.map((booking, index) => {
                                        return <Panel header={booking.boat_name + ' (' + booking.number_of_days + ' days from: '
                                            + booking.arrival_date + ' to: ' + booking.departure_date + ')'}
                                            key={index} style={{ backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 0, margin: 0 }}>
                                            <div style={{ color: '#888', padding: 0, margin: 2 }}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th>Boat Name: </th>
                                                            <td>{booking.boat_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Contact Name: </th>
                                                            <td>{booking.title} {booking.first_name} {booking.surname}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone: </th>
                                                            <td>{booking.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Email: </th>
                                                            <td>{booking.email}</td>
                                                        </tr>
                                                        <tr >
                                                            <th style={{ verticalAlign: 'top' }}>Address: </th>
                                                            <td>
                                                                <address>{booking.address_line1}, {booking.address_line2}, <br />
                                                                    {booking.address_line3}<br />
                                                                    {booking.postcode}
                                                                </address>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Boat Length: </th>
                                                            <td>{booking.boat_length} {booking.length_unit}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Air Draft: </th>
                                                            <td>{booking.air_draft} {booking.air_draft_unit}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival From: </th>
                                                            <td>{booking.arriving_from}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival: </th>
                                                            <td>{booking.arrival_date}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival Lock: </th>
                                                            <td>{booking.arrival_lock}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Departure: </th>
                                                            <td>{booking.departure_date}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Departure Lock: </th>
                                                            <td>{booking.departure_lock}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Number of Days: </th>
                                                            <td>{booking.number_of_days}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Cost: </th>
                                                            <td>£ {booking.cost}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Home Port: </th>
                                                            <td>{booking.home_port}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Hull Type: </th>
                                                            <td>{booking.hull_type}</td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </Panel>
                                    })}
                                </Collapse>
                            </Col>



                        </Row>
                    ) : <Text type="secondary">There are no book visits yet</Text>}
                    <Divider />

                </Drawer>

            </div >

        );
    }
}
export default HouseDiary;