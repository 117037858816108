import {
    BUSINESSES_REQUEST,
    BUSINESSES_SUCCESS,
    BUSINESSES_FAILURE,
} from '../actions';

export const BUSINESSES_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

const businessesReducers = {
    [BUSINESSES_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [BUSINESSES_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [BUSINESSES_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function businesses(state = BUSINESSES_INITIAL_STATE, action) {
    const reducer = businessesReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
