import { loadData, SESSION_KEY } from '../storage/local-storage';
import {
    LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS, SESSION_EXPIRED,
} from '../actions';

const session = loadData(SESSION_KEY) || {};
export const AUTH_INITIAL_STATE = {
    isFetching: false,
    isAuthenticated: session && session.authenticated,
    error: {},
    expired: false,
};

const authReducers = {
    [LOGIN_REQUEST]: (state, {
        creds,
    }) => ({
        ...state,
        isFetching: true,
        isAuthenticated: false,
        creds,
        error: {},
        expired: false,
    }),
    [LOGIN_SUCCESS]: (state, action) => ({
        ...state,
        creds: {},
        isFetching: false,
        isAuthenticated: true,
        error: {},
    }),
    [LOGIN_FAILURE]: (state, {
        error,
    }) => ({
        ...state,
        isFetching: false,
        isAuthenticated: false,
        error,
    }),
    [LOGOUT_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        expired: false,
    }),
    [LOGOUT_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
        isAuthenticated: false,
        session: {},
    }),
    [SESSION_EXPIRED]: (state, { message }) => ({
        ...AUTH_INITIAL_STATE,
        expired: true,
    }),
};

export default function auth(state = AUTH_INITIAL_STATE, action) {
    const reducer = authReducers[action.type] || ((state) => state);
    return reducer(state, action);
}

export const getAuth = (state) => ({ ...state });
