import React from 'react';
import {
    Form, Row, Col, Select, Input, Divider, Button,
} from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import Calculator from './CalculateRate';

function BristolForm({ onFinish, onCancel, isFetching }) {
    const [form] = Form.useForm();
    const [daysVisible, setDaysVisible] = React.useState(true);

    const onTypeChange = (value) => {
        setDaysVisible(value === 'visiting');
    };

    return (
        <Form
            name="calculate-form"
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{
                unit: 'metre',
            }}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        label="Boat Length"
                        style={{ marginBottom: 0 }}
                    >
                        <Form.Item
                            name="length"
                            style={{ display: 'inline-block', width: 'calc(66%)' }}
                            rules={[{
                                required: true,
                                pattern: '[\\d]+',
                                message: 'Required',
                                whitespace: true,
                            }]}
                            hasFeedback
                        >
                            <Input placeholder="Boat Length" />
                        </Form.Item>
                        <Form.Item
                            name="unit"
                            rules={[{
                                required: true,
                                message: 'Required',
                                whitespace: true,
                            }]}
                            style={{ display: 'inline-block', width: 'calc(30%)', float: 'right' }}
                            hasFeedback
                        >
                            <Select
                                placeholder="Unit"
                            >
                                <Select.Option value="metre">Metres</Select.Option>
                                <Select.Option value="feet">Feet</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item
                        label="Multi Hull"
                        name="multi_hull"
                        rules={[{
                            required: true,
                            message: 'Required',
                        }]}
                        hasFeedback
                    >
                        <Select
                            placeholder="Multi Hull"
                        >
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Berthing Type"
                        name="berth_type"
                        rules={[{
                            required: true,
                            message: 'Required',
                        }]}
                        hasFeedback
                    >
                        <Select
                            placeholder="Berthing Type"
                            onChange={onTypeChange}
                        >
                            <Select.Option value="visiting">Visiting</Select.Option>
                            <Select.Option value="annual">Annual</Select.Option>
                        </Select>
                    </Form.Item>
                    {daysVisible && (
                        <Form.Item
                            label="Number Of Days"
                            name="days"
                            rules={[{
                                required: true,
                                pattern: '[\\d]+',
                                message: 'Invalid Input',
                                whitespace: true,
                                max: 21,
                            }]}
                            hasFeedback
                        >
                            <Input placeholder="Number Of Days (Max 21)" />
                        </Form.Item>
                    )}
                </Col>
            </Row>
            <Divider />
            <div style={{ textAlign: 'right' }}>
                <Button type="danger" style={{ marginRight: 5 }} onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isFetching}>
                    <CalculatorOutlined />
                    {' '}
                    Calculate
                </Button>
            </div>
        </Form>
    );
}

export default function BristolCalculator(props) {
    return (
        <Calculator
            {...props}
            render={(onFinish, onCancel, isFetching) => <BristolForm {...{ onFinish, onCancel, isFetching }} />}
        />
    );
}
