import React from 'react';
import { Switch, Route } from 'react-router-dom';

import StationList from './Stations';
import TideList from './Tides';

export default function Stations({ match }) {
    return (
        <Switch>
            <Route key="1" path={`${match.url}/`} exact component={StationList} />
            <Route key="2" path={`${match.url}/:station/tides`} component={TideList} />
        </Switch>
    );
}
