import {
    STATIONS_REQUEST,
    STATIONS_SUCCESS,
    STATIONS_FAILURE,
    SYNC_STATIONS_REQUEST,
    SYNC_STATIONS_SUCCESS,
    SYNC_STATIONS_FAILURE,
} from '../actions';

export const STATIONS_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

const stationsReducers = {
    [STATIONS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [STATIONS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [STATIONS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [SYNC_STATIONS_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null,
    }),
    [SYNC_STATIONS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [SYNC_STATIONS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function stations(state = STATIONS_INITIAL_STATE, action) {
    const reducer = stationsReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
