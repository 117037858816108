import {
    FETCH_LOCATION_REQUEST,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATION_FAILURE,
    SYNC_LOCATION_REQUEST,
    SYNC_LOCATION_SUCCESS,
    SYNC_LOCATION_FAILURE,
    CLEAR_LOCATION,
} from '../actions';

const FETCH_LOCATION_INITIAL_STATE = {
    isFetching: false,
    error: null,
    data: [],
    links: {},
    meta: {},
};

const lookupReducers = {
    [FETCH_LOCATION_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null,
    }),
    [FETCH_LOCATION_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [FETCH_LOCATION_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [CLEAR_LOCATION]: (state, action) => ({ ...FETCH_LOCATION_INITIAL_STATE }),
    [SYNC_LOCATION_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null,
    }),
    [SYNC_LOCATION_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [SYNC_LOCATION_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

const locationReducer = (state = FETCH_LOCATION_INITIAL_STATE, action) => {
    const reducer = lookupReducers[action.type] || ((state) => state);
    return reducer(state, action);
};

export default locationReducer;
