/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { AutoComplete, Input } from 'antd';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { fetchStations } from '../../actions';

function Lookup(props) {
    const [options, setOptions] = useState([]);

    const {
        stations, onSearch, onChange, placeholder, style, isFetching, value = {}, disabled,
    } = props;
    const [currentValue, setCurrentValue] = useState(value.name);
    const debounced = debounce(onSearch, 300);

    useEffect(() => {
        setOptions(stations.map(({
            name, country, ...rest
        }) => ({ value: `${name}, ${country}`, ...rest })));
    }, [isFetching, stations]);

    const handleSelect = (value, option) => {
        onChange(option);
    };

    // eslint-disable-next-line no-underscore-dangle
    const _onSearch = (text) => {
        debounced(1, text);
    };

    const handleChange = (value, option) => {
        setCurrentValue(option.label);
    };

    return (
        <AutoComplete
            onSelect={handleSelect}
            onSearch={_onSearch}
            style={style}
            options={options}
            value={currentValue}
            onChange={handleChange}
            disabled={disabled}
        >
            <Input.Search placeholder={placeholder} />
        </AutoComplete>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { stations } = state;

    return {
        stations: stations.data,
        isFetching: stations.isFetching,
        error: stations.error,
        ...ownProps,
    };
};

export default connect(mapStateToProps, {
    onSearch: fetchStations,
})(Lookup);
