import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    Redirect,
    NavLink,
} from 'react-router-dom';
import {
    Form, Input, Button, Alert,
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { requestResetUser } from '../actions';

const FormItem = Form.Item;

function ResetRequest({
    isAuthenticated,
    requestResetUser,
}) {
    const [submitted, setSubmitted] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const onFinish = async (formValues) => {
        setRequesting(true);
        await requestResetUser(formValues);
        setRequesting(false);
        setSubmitted(true);
    };

    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <div className="login-form reset">
            <NavLink to="/">
                <div className="login-logo">
                    <img src="/img/logo.jpg" alt="My homeport logo" />
                </div>
            </NavLink>
            {submitted ? (
                <Alert
                    message="Password Reset requested"
                    description="You'll get an email with the instructions to reset password shortly if the given username exists in our system"
                    type="success"
                />
            ) : (
                <Form onFinish={onFinish}>
                    <FormItem
                        hasFeedback
                        rules={[
                            { required: true, message: 'Please input your email address!' },
                            { type: 'email', message: 'The input is not valid E-mail!' },
                        ]}
                        name="email"
                    >
                        <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email address" />
                    </FormItem>
                    <FormItem>
                        <Button loading={requesting} type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                            Reset Password
                        </Button>
                    </FormItem>
                </Form>
            )}
        </div>
    );
}
function mapStateToProps(state) {
    return { ...state.auth };
}
export default connect(mapStateToProps, { requestResetUser })(ResetRequest);
