export const SESSION_KEY = 'mhp-session';

export const loadData = (key) => {
    try {
        const serialized = localStorage.getItem(key);
        if (serialized === null) return {};
        return JSON.parse(serialized);
    } catch (e) {
        console.log('Error : ', e);
        return {};
    }
};

export const saveData = (key, payload) => {
    try {
        const serialized = JSON.stringify(payload);
        localStorage.setItem(key, serialized);
    } catch (e) {
        console.log('Error: ', e);
    }
};

export const removeData = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        console.log('Error: ', e);
    }
};
