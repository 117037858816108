/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
    Button, Form, Row, Col, Input, Divider, message, Select, Modal, Checkbox,
} from 'antd';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';

const setFormErrors = (form, error, values) => {
    const { res: { errors: serverErrors, message: msg } } = error;
    message.error(msg);

    if (!serverErrors) return;

    const fields = [];
    Object.keys(serverErrors).forEach((name) => {
        const fieldErrors = serverErrors[name];
        fields.push({
            name,
            value: values[name],
            errors: fieldErrors,
        });
    });
    form.setFields(fields);
};

function NewCategoryForm({
    visible, onCancel, loading, onCreate, error,
}) {
    const [form] = Form.useForm();
    const [values, setValues] = useState({});

    useEffect(() => {
        if (!error) return;
        setFormErrors(form, error, values);
    }, [error]);

    const onOk = () => {
        form.validateFields().then((values) => {
            form.resetFields();
            setValues(values);
            onCreate(values);
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    };

    return (
        <Modal
            title="Add new category"
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="horizontal"
                name="catForm"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                <Form.Item
                    hasFeedback
                    name="name"
                    label="Field Category"
                    rules={[{ required: true, message: 'Type name is required', whitespace: true }]}
                >
                    <Input placeholder="Field category name" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default function RateField(props) {
    const {
        isEdit,
        currentField,
        field,
        category,
        categories,
        fetchRateFieldCategories,
        storeRateFieldCategory,
        storeRateField,
        updateRateField,
        onFinish: onComplete,
        onCancel: onEditCancel,
        marina: { id: marinaId },
    } = props;
    const [values, setValues] = useState({});
    const [valueType, setValueType] = useState('numeric');
    const [submitted, setSubmitted] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!field.error) return;
        setFormErrors(form, field.error, values);
    }, [field.error]);

    const onFinish = (formValues) => {
        setValues(formValues);
        setSubmitted(true);
        if (isEdit) {
            updateRateField(currentField.id, { ...formValues, marina_id: marinaId });
            return;
        }
        storeRateField({ ...formValues, marina_id: marinaId });
    };

    const [visible, setVisible] = useState(false);
    const [addCategorySubmitted, setAddCategorySubmitted] = useState(false);
    const openAddModal = () => setVisible(true);
    const onCancel = () => setVisible(false);

    const onAddNewType = (values) => {
        setAddCategorySubmitted(true);
        storeRateFieldCategory({ ...values, marina_id: marinaId });
    };

    useEffect(() => {
        if (!addCategorySubmitted || category.isFetching) return;
        if (!category.error) {
            setAddCategorySubmitted(false);
            fetchRateFieldCategories(marinaId);
            setVisible(false);
        }
    }, [category.isFetching]);

    useEffect(() => {
        if (!category.id || categories.isFetching) return;
        form.setFieldsValue({ category_id: category.id });
    }, [category.id, categories.isFetching]);

    useEffect(() => {
        if (!submitted || field.isFetching) return;
        setSubmitted(false);
        if (!field.error) {
            if (!isEdit) form.resetFields();
            setValueType('numeric');
            message.success(isEdit ? 'Successfully updated' : 'Successfully created');
            onComplete(isEdit);
        }
    }, [field.isFetching]);

    useEffect(() => {
        if (!(isEdit && currentField)) return;
        const {
            label, type, category_id, used_in_calculation,
        } = currentField;
        const fieldValues = {
            label,
            type,
            category_id,
            used_in_calculation,
        };
        fieldValues[`${type}_value`] = currentField[`${type}_value`];
        setValueType(type);
        form.setFieldsValue(fieldValues);
    }, [isEdit, currentField]);

    const handleValueType = (value) => {
        setValueType(value);
    };

    const getInitialValues = () => ({
        type: 'numeric',
        used_in_calculation: true,
    });

    const cancelEdit = () => {
        form.resetFields();
        onEditCancel();
    };

    const RateFieldForm = (
        <Form
            name="rateFieldForm"
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={getInitialValues()}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item label="Field Label" name="label" rules={[{ required: !isEdit, message: 'Field Label required', whitespace: true }]} hasFeedback={!isEdit}>
                        <Input placeholder="Please enter field label" disabled={isEdit} />
                    </Form.Item>
                    <Form.Item label="Field Category" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(66%)' }} name="category_id" rules={[{ required: !isEdit, message: 'Business type is required' }]} hasFeedback={!isEdit}>
                            <Select
                                showSearch
                                placeholder="Field Category"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                disabled={isEdit}
                            >
                                {categories.data.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', float: 'right' }}>
                            <Button onClick={openAddModal} type="primary" disabled={isEdit}>
                                <PlusOutlined />
                                {' '}
                                Add New
                            </Button>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Field Type" name="type" rules={[{ required: !isEdit, message: 'Field type is required' }]} hasFeedback={!isEdit}>
                        <Select
                            showSearch
                            placeholder="Field Type"
                            onChange={handleValueType}
                            disabled={isEdit}
                        >
                            {[
                                { id: 1, name: 'string', label: 'String' },
                                { id: 2, name: 'numeric', label: 'Number' },
                                { id: 3, name: 'text', label: 'Text' },
                            ].map(({ id, name, label }) => <Select.Option value={name} key={id}>{label}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Value" name={`${valueType}_value`} rules={[{ required: true, message: 'Value required', whitespace: true }]} hasFeedback>
                        <Input placeholder="Please enter field value" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6, span: 18 }} name="used_in_calculation" valuePropName="checked">
                        <Checkbox disabled={isEdit}>Used In Calculation</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <div style={{ float: 'right' }}>
                {isEdit ? (
                    <Button type="danger" style={{ marginRight: 5 }} onClick={cancelEdit}>
                        Cancel
                    </Button>
                ) : null}
                <Button type="primary" htmlType="submit" loading={field.isFetching}>
                    {isEdit ? <SaveOutlined /> : <PlusOutlined />}
                    {' '}
                    {isEdit ? 'Update' : 'Add'}
                </Button>
            </div>
        </Form>
    );

    return (
        <>
            {RateFieldForm}
            <NewCategoryForm
                visible={visible}
                onCancel={onCancel}
                onCreate={onAddNewType}
                error={category.error}
                loading={category.isFetching}
            />
        </>
    );
}
