import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { PageHeader, Drawer, Button, DatePicker, Form, Row, Col, Input, Divider, Checkbox, message, Switch, Typography, Collapse, InputNumber } from 'antd';
import { BoldOutlined, PlusOutlined, SaveOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
const { Text } = Typography;
const { Panel } = Collapse;

class BerthHolder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            berthHolder: {},
            berthHolders: [],
            drawerVisible: false,
            isSaving: false
        };

        this.getBerthHolders = this.getBerthHolders.bind(this);
        this.saveBerthHolder = this.saveBerthHolder.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
    }

    closeDrawer() {
        this.setState({
            drawerVisible: false
        });
    };
    openDrawer(e) {
        this.setState({
            drawerVisible: true,
        });
    };

    saveBerthHolder(val) {
        val.licence_expiry_date =  moment(val.licence_expiry_date).format("YYYY-MM-DD")
        this.setState({
            berthHolder: val,
            isSaving: true,
        });
        console.log(val)
        axios
            .post('/api/berth_holder/save_berth_holder', val)
            .then(response => {
                console.log('from handle submit', response);
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.setState({
                    isSaving: false,
                });
                this.getBerthHolders();
                this.closeDrawer();
            });
    }

    getBerthHolders() {
        return axios.get('/api/berth_holder/get_berth_holders').then((
            response
        ) => {
            if (response.data.length) {
                this.setState({
                    berthHolders: response.data
                });
            }
        });
    }

    componentDidMount() {
        this.getBerthHolders();
    }

    render() {
        return (

            <PageHeader
                title="Berth Holders"
                className="locks-page-header"
                onBack={() => window.history.back()}>
                <div style={{ float: 'right', marginBottom: 8, position: 'absolute', top: 16, right: 24, }}>
                    <Button type="primary" onClick={this.openDrawer}><PlusOutlined /> New Berth Holder</Button>
                </div>

                <div>

                    {/* {display list of berth holders} */}
                    <div className='ant-table-content'>
                        {this.state.berthHolders.length ?
                            <table style={{ width: '100%' }}>
                                <thead className='ant-table-thead'>
                                    <tr>
                                        <th>Boat Name</th>
                                        <th>Berth Holder</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Air Draft (m)</th>
                                        <th>Length (m)</th>
                                        <th>Licence Expiry</th>
                                    </tr>
                                </thead>
                                <tbody className='ant-table-tbody'>
                                    {this.state.berthHolders.map((berthHolder, index) => {
                                        return <tr key={index}>
                                            <td>{berthHolder.boat_name}</td>
                                            <td>{berthHolder.berth_holder_name}</td>
                                            <td style={{ color: '#1890ff' }}>{berthHolder.email}</td>
                                            <td>{berthHolder.phone}</td>
                                            <td>{berthHolder.air_draft}</td>
                                            <td>{berthHolder.length}</td>
                                            <td>{berthHolder.licence_expiry_date}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            : <Text type="danger">No berth holders available</Text>}
                    </div>
                    {/* {end display list of berth holders} */}

                    <Drawer
                        title={'Add New Berth Holder'}
                        width={500}
                        onClose={this.closeDrawer}
                        visible={this.state.drawerVisible}
                        maskClosable={true}
                        destroyOnClose>

                        <Form
                            name="berth-holder-form"
                            layout="horizontal"
                            onFinish={this.saveBerthHolder}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item label="Boat Name" name="boat_name"
                                        rules={[{ required: true, message: 'Boat name is required' }]} hasFeedback>
                                        <Input placeholder="Please enter boat name" value={this.state.berthHolder.boat_name} />
                                    </Form.Item>
                                    <Form.Item label="Berth Holder Name" name="berth_holder_name"
                                        rules={[{ required: true, message: 'Berth holder name is required' }]} hasFeedback>
                                        <Input placeholder="Please enter berth holder name" value={this.state.berthHolder.berth_holder_name} />
                                    </Form.Item>
                                    <Form.Item label="Email" name="email"
                                        rules={[{ type: 'email', required: true, message: 'Email is required' }]} hasFeedback>
                                        <Input placeholder="Please enter email" value={this.state.berthHolder.email} />
                                    </Form.Item>
                                    <Form.Item label="Phone" name="phone"
                                        rules={[{ required: true, message: 'Phone is required' }]} hasFeedback>
                                        <Input placeholder="Please enter phone number" value={this.state.berthHolder.phone} />
                                    </Form.Item>
                                    <Form.Item label="Air Draft (m)" name="air_draft"
                                        rules={[{ required: true, message: 'Air draft is required' }]} hasFeedback>
                                        <InputNumber placeholder="Please enter air draft" value={this.state.berthHolder.air_draft} style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item label="Length (m)" name="length"
                                        rules={[{ required: true, message: 'Length is required' }]} hasFeedback>
                                        <InputNumber placeholder="Please enter length" value={this.state.berthHolder.length} style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item label="Licence Expiry" name="licence_expiry_date"
                                    rules={[{ required: true, message: 'Length is required' }]} hasFeedback>
                                        <DatePicker placeholder="Please select licence expiry date" format="YYYY-MM-DD" value={moment(this.state.berthHolder.licence_expiry_date)} style={{ width: '100%' }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <div style={{ textAlign: 'right' }}>
                                <Button type="primary" htmlType="submit" loading={this.state.isSaving}>
                                    <SaveOutlined /> Save
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                </div >
            </PageHeader>
        );
    }
}
export default BerthHolder;