/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import { List } from 'antd';
import {
    fetchTokens, deleteToken,
} from '../../actions';

dayjs.extend(customParse);

function AccessToken(props) {
    const {
        userId,
        addedNew,
        tokens: { isFetching, error, data },
        deleteToken,
        fetchTokens,
    } = props;

    useEffect(() => {
        fetchTokens(userId);
    }, [userId]);

    useEffect(() => {
        if (!addedNew) return;
        fetchTokens(userId);
    }, [addedNew]);

    const onRevoke = (id) => (e) => {
        e.preventDefault();
        deleteToken(userId, id);
    };

    const TokenList = (
        <List
            loading={isFetching}
            size="small"
            bordered
            itemLayout="horizontal"
            dataSource={data}
            renderItem={({
                id,
                name,
                token,
                created_at: createAt,
                last_used_at: lastUsedAt,
            }) => (
                <List.Item
                    actions={[<a key="revoke" onClick={onRevoke(id)}>Revoke</a>]}
                >
                    <List.Item.Meta
                        title={name}
                        description={(
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>
                                    Created :
                                    {' '}
                                    {dayjs(createAt).format('DD/MM/YYYY h:mm:ss A')}
                                </span>
                                <span>
                                    Last Used :
                                    {' '}
                                    {lastUsedAt ? dayjs(lastUsedAt).format('DD/MM/YYYY h:mm:ss A') : 'Unused'}
                                </span>
                            </div>
                        )}
                    />
                </List.Item>
            )}
        />
    );

    return TokenList;
}

const mapStateToProps = (state, ownProps) => {
    const { user: { tokens } } = state;
    const { userId } = ownProps;

    return {
        ...ownProps,
        tokens,
        userId,
    };
};

export default connect(mapStateToProps, {
    fetchTokens, deleteToken,
})(AccessToken);
