import { combineReducers } from 'redux';
import businesses from './businesses';
import sponsors from './sponsors';
import business from './business';
import sponsor from './sponsor';

import {
    MARINA_REQUEST,
    MARINA_SUCCESS,
    MARINA_FAILURE,
    STORE_MARINA_REQUEST,
    STORE_MARINA_SUCCESS,
    STORE_MARINA_FAILURE,
    UPDATE_MARINA_REQUEST,
    UPDATE_MARINA_SUCCESS,
    UPDATE_MARINA_FAILURE,
    APPROVE_MARINA_REQUEST,
    APPROVE_MARINA_SUCCESS,
    APPROVE_MARINA_FAILURE,
    CLEAR_MARINA,
    DELETE_MARINA_REQUEST,
    DELETE_MARINA_SUCCESS,
    DELETE_MARINA_FAILURE,
    PROCESS_LOCK_REQUEST,
    PROCESS_LOCK_SUCCESS,
    PROCESS_LOCK_FAILURE,
} from '../actions';

export const MARINA_INITIAL_STATE = {
    id: null,
    station_id: null,
    app_name: '',
    welcome_text: '',
    marina_name: '',
    canonical_marina_name: '',
    address_1: '',
    address_2: '',
    address_3: '',
    postcode: '',
    contact_name: '',
    username: '',
    phone: '',
    email: '',
    web: '',
    facebook_link: '',
    flickr_link: '',
    twitter_link: '',
    open_time_weekdays: '',
    open_time_saturday: '',
    open_time_sunday: '',
    location_id: '',
    loc_lat: '',
    loc_long: '',
    security: '',
    channel_name: '',
    channel_number: '',
    channel_name_1: '',
    channel_number_1: '',
    position: '',
    approved: false,
    approved_by: null,
    cancelled: false,
    cancelled_by: null,
    icon_location_app: '',
    icon_location_marina: '',
    file_location_tides_locks: '',
    created_at: null,
    isFetching: false,
    error: null,
    station: null,
    location: null,
    fcm_credentials: null,
    rate_script: null,
    rate_prompt_definition: null,
    rates_freezed: false,
};

const marinaReducers = {
    [STORE_MARINA_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null, draft: true,
    }),
    [STORE_MARINA_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [STORE_MARINA_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [MARINA_REQUEST]: (state, { id }) => ({
        ...state, id, isFetching: true, error: null,
    }),
    [MARINA_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [MARINA_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [UPDATE_MARINA_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [UPDATE_MARINA_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [UPDATE_MARINA_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [CLEAR_MARINA]: (state, action) => ({ ...MARINA_INITIAL_STATE }),
    [DELETE_MARINA_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [DELETE_MARINA_SUCCESS]: (state) => ({ ...MARINA_INITIAL_STATE }),
    [DELETE_MARINA_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [APPROVE_MARINA_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [APPROVE_MARINA_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [APPROVE_MARINA_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [PROCESS_LOCK_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [PROCESS_LOCK_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [PROCESS_LOCK_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

const marinaReducer = (state = MARINA_INITIAL_STATE, action) => {
    const reducer = marinaReducers[action.type] || ((state) => state);
    return reducer(state, action);
};

export default combineReducers({
    profile: marinaReducer,
    adverts: businesses,
    sponsors,
    sponsor,
    advert: business,
});
