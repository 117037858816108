import { sessionExpired } from '../actions';

export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const METHOD_PUT = 'PUT';
export const METHOD_PATCH = 'PATCH';
export const METHOD_DELETE = 'DELETE';

export const API_ROOT = '/api';
export const AUTH_ROOT = '/auth';
export const TYPE_JSON = 'json';
export const TYPE_FORM = 'form';

const configureFetch = (endpoint, actionData, method = METHOD_GET, requireAuth, type) => () => {
    const fullURL = (endpoint.indexOf(API_ROOT) === -1) && (endpoint.indexOf(AUTH_ROOT) === -1) ? `${API_ROOT}${endpoint}` : endpoint;

    let body;
    if (type === TYPE_FORM) {
        body = actionData.body;
    } else {
        body = typeof actionData === 'string' ? actionData : JSON.stringify(actionData);
    }

    const config = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            accept: 'application/json',
        },
    };

    if (type === TYPE_FORM) {
        delete config.headers['Content-Type'];
    }

    if (requireAuth) {
        config.credentials = 'include';
    }

    // Request with GET/HEAD method cannot have body
    if (method !== METHOD_GET) {
        config.body = body;
    }

    return fetch(fullURL, config).then((res) => res.json().then((json) => ({
        json,
        res,
    }))).then(({
        json,
        res,
    }) => {
        if (!res.ok) {
            const {
                status,
            } = res;
            return Promise.reject({ status, res: json });
        }
        return { ...json };
    });
};

export const CALL_API = Symbol('Call API');

export default (store) => (next) => (action) => {
    const callAPI = action[CALL_API];
    if (!callAPI) {
        return next(action);
    }

    let {
        endpoint,
    } = callAPI;
    const {
        method,
        types,
        requireAuth = true,
        type = TYPE_JSON,
    } = callAPI;

    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState());
    }

    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.');
    }

    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types');
    }

    if (!types.every((type) => typeof type === 'string')) {
        throw new Error('Expected action types to be string');
    }

    function actionWith(data) {
        const finalAction = { ...action, ...data };
        delete finalAction[CALL_API];
        return finalAction;
    }

    const fetchAPI = configureFetch(endpoint, actionWith({}), method, requireAuth, type);
    const [requestType, successType, failureType] = types;

    next(actionWith({
        type: requestType,
    }));
    return fetchAPI().then(
        (res) => next(actionWith({
            res,
            type: successType,
        })),
        (error) => {
            const { status, res: { message } } = error;
            if (status === 401 || status === 419) {
                return next(sessionExpired(message));
            }
            return next(actionWith({
                error,
                type: failureType,
            }));
        },
    );
};
