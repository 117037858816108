import React from 'react';
import {
    Form, Row, Col, Select, Input, Divider, Button,
} from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import Calculator from './CalculateRate';

function SwanseaForm({ onFinish, onCancel, isFetching }) {
    const [form] = Form.useForm();

    return (
        <Form
            name="calculate-form"
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{
                unit: 'metre',
            }}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        label="Boat Length"
                        style={{ marginBottom: 0 }}
                    >
                        <Form.Item
                            name="length"
                            style={{ display: 'inline-block', width: 'calc(66%)' }}
                            rules={[{
                                required: true,
                                pattern: '[\\d]+',
                                message: 'Required',
                                whitespace: true,
                            }]}
                            hasFeedback
                        >
                            <Input placeholder="Boat Length" />
                        </Form.Item>
                        <Form.Item
                            name="unit"
                            rules={[{
                                required: true,
                                message: 'Required',
                                whitespace: true,
                            }]}
                            style={{ display: 'inline-block', width: 'calc(30%)', float: 'right' }}
                            hasFeedback
                        >
                            <Select
                                placeholder="Unit"
                            >
                                <Select.Option value="metre">Metres</Select.Option>
                                <Select.Option value="feet">Feet</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <div style={{ textAlign: 'right' }}>
                <Button type="danger" style={{ marginRight: 5 }} onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isFetching}>
                    <CalculatorOutlined />
                    {' '}
                    Calculate
                </Button>
            </div>
        </Form>
    );
}

export default function SwanseaCalculator(props) {
    return (
        <Calculator
            {...props}
            render={(onFinish, onCancel, isFetching) => <SwanseaForm {...{ onFinish, onCancel, isFetching }} />}
        />
    );
}
