import {
    SEND_NOTIFICATION_REQUEST,
    SEND_NOTIFICATION_SUCCESS,
    SEND_NOTIFICATION_FAILURE,
} from '../actions';

export const NOTIFICATION_INITIAL_STATE = {
    title: '',
    body: '',
    isFetching: false,
    error: null,
};

const notificationReducers = {
    [SEND_NOTIFICATION_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null, draft: true,
    }),
    [SEND_NOTIFICATION_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [SEND_NOTIFICATION_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

const notificationReducer = (state = NOTIFICATION_INITIAL_STATE, action) => {
    const reducer = notificationReducers[action.type] || ((state) => state);
    return reducer(state, action);
};

export default notificationReducer;
