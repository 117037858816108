import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Redirect,
    NavLink,
} from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { loginUser } from '../actions';

const FormItem = Form.Item;

function Login(props) {
    const { error: { errors, message } } = props;
    const [values, setValues] = useState({});
    const [form] = Form.useForm();

    useEffect(() => {
        const { email, password } = values;
        if (errors) {
            form.setFields([{
                name: 'email',
                value: email,
                errors: [message],
            },
            {
                name: 'password',
                value: password,
                errors: [message],
            }]);
        }
    }, [errors]);

    const onFinish = (values) => {
        const { loginUser } = props;
        setValues(values);
        return loginUser(values);
    };

    const { isAuthenticated } = props;
    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <div className="login-form">
            <NavLink to="/">
                <div className="login-logo">
                    <img src="/img/logo.jpg" alt="My homeport logo" />
                </div>
            </NavLink>
            <Form onFinish={onFinish} form={form}>
                <FormItem
                    hasFeedback
                    name="email"
                    rules={[
                        { required: true, message: 'Please input your email address!' },
                        { type: 'email', message: 'The input is not valid E-mail!' },
                    ]}
                >
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email address" />
                </FormItem>
                <FormItem hasFeedback name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                    <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                </FormItem>
                <FormItem style={{ marginBottom: 0 }}>
                    <NavLink to="/forgot-password" style={{ float: 'right' }}>
                        Forgot password
                    </NavLink>
                    <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                        Log in
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return { ...state.auth };
}
export default connect(mapStateToProps, { loginUser })(Login);
