import {
    BUSINESS_REQUEST,
    BUSINESS_SUCCESS,
    BUSINESS_FAILURE,
    STORE_BUSINESS_REQUEST,
    STORE_BUSINESS_SUCCESS,
    STORE_BUSINESS_FAILURE,
    UPDATE_BUSINESS_REQUEST,
    UPDATE_BUSINESS_SUCCESS,
    UPDATE_BUSINESS_FAILURE,
    DELETE_BUSINESS_REQUEST,
    DELETE_BUSINESS_SUCCESS,
    DELETE_BUSINESS_FAILURE,
    CLEAR_BUSINESS,
} from '../actions';

export const BUSINESS_INITIAL_STATE = {
    id: null,
    type_id: null,
    marina_id: null,
    businessType: null,
    business_name: '',
    business_address: '',
    business_loc_lat: '',
    business_loc_long: '',
    contact_name: '',
    contact_title: '',
    contact_first_name: '',
    contact_surname: '',
    contact_phpne: '',
    contact_email: '',
    contact_web: '',
    contact_address: '',
    isFetching: false,
    error: null,
};

const businessReducers = {
    [STORE_BUSINESS_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null, draft: true,
    }),
    [STORE_BUSINESS_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [STORE_BUSINESS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [BUSINESS_REQUEST]: (state, { id }) => ({
        ...state, id, isFetching: true, error: null,
    }),
    [BUSINESS_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [BUSINESS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [UPDATE_BUSINESS_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [UPDATE_BUSINESS_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [UPDATE_BUSINESS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [DELETE_BUSINESS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [DELETE_BUSINESS_SUCCESS]: (state) => ({ ...BUSINESS_INITIAL_STATE }),
    [DELETE_BUSINESS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [CLEAR_BUSINESS]: () => ({ ...BUSINESS_INITIAL_STATE }),
};

const businessReducer = (state = BUSINESS_INITIAL_STATE, action) => {
    const reducer = businessReducers[action.type] || ((state) => state);
    return reducer(state, action);
};

export default businessReducer;
