import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import Root from './components/Root';
import configureStore from './store';

Sentry.init({ dsn: 'https://f35c900139aa44e0b12ad11854498da2@o425934.ingest.sentry.io/5367190' });

const store = configureStore();

const render = (Component) => {
    ReactDOM.render(
        <Component store={store} />,
        document.getElementById('app'),
    );
};

render(Root);
