import {
    MARINAS_REQUEST,
    MARINAS_SUCCESS,
    MARINAS_FAILURE,
} from '../actions';

export const MARINAS_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

const companiesReducers = {
    [MARINAS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [MARINAS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [MARINAS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function companies(state = MARINAS_INITIAL_STATE, action) {
    const reducer = companiesReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
