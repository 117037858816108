import { combineReducers } from 'redux';

import {
    USERS_REQUEST,
    USERS_SUCCESS,
    USERS_FAILURE,
    PERMISSIONS_REQUEST,
    PERMISSIONS_SUCCESS,
    PERMISSIONS_FAILURE,
    INVITE_USER_REQUEST,
    INVITE_USER_SUCCESS,
    INVITE_USER_FAILURE,
    USER_REQUEST,
    USER_SUCCESS,
    USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    TOKENS_REQUEST,
    TOKENS_SUCCESS,
    TOKENS_FAILURE,
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,
    DELETE_TOKEN_FAILURE,
    NEW_TOKEN_REQUEST,
    NEW_TOKEN_SUCCESS,
    NEW_TOKEN_FAILURE,
    CLEAR_TOKEN,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE,
    RESEND_EMAIL_REQUEST,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_FAILURE,
} from '../actions';

export const USER_LIST_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

export const PERMISSIONS_INITIAL_STATE = {
    isFetching: false,
    permissions: [],
    links: {},
    meta: {},
    error: null,
};

export const INVITE_INITIAL_STATE = {
    isFetching: false,
    permissions: '',
    email: '',
    error: null,
};

const userListReducers = {
    [USERS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [USERS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [USERS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

const permissionsReducer = {
    [PERMISSIONS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [PERMISSIONS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [PERMISSIONS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

const inviteReducer = {
    [INVITE_USER_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [INVITE_USER_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [INVITE_USER_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export const USER_INITIAL_STATE = {
    id: null,
    first_name: '',
    last_name: '',
    active: false,
    permissions: '',
    scope: '',
    role: '',
    can_resend_email: false,
    isFetching: false,
    error: null,
};

const userReducers = {
    [USER_REQUEST]: (state, { id }) => ({
        ...state, id, isFetching: true, error: null,
    }),
    [USER_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [USER_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [DELETE_USER_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [DELETE_USER_SUCCESS]: (state) => ({ ...USER_INITIAL_STATE }),
    [DELETE_USER_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [UPDATE_USER_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [UPDATE_USER_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [UPDATE_USER_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [ADD_USER_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [ADD_USER_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [ADD_USER_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export const TOKEN_LIST_INITIAL_STATE = {
    isFetching: false,
    data: [],
    error: null,
};

const tokenListReducers = {
    [TOKENS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [TOKENS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [TOKENS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [DELETE_TOKEN_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [DELETE_TOKEN_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [DELETE_TOKEN_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export const TOKEN_INITIAL_STATE = {
    id: null,
    name: '',
    accessToken: '',
    plainTextToken: '',
    isFetching: false,
    error: null,
};

const tokenReducers = {
    [NEW_TOKEN_REQUEST]: (state, { id }) => ({
        ...state, id, isFetching: true, error: null,
    }),
    [NEW_TOKEN_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [NEW_TOKEN_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [CLEAR_TOKEN]: () => ({ ...TOKEN_INITIAL_STATE }),
};

export const RESEND_EMAIL_INITIAL_STATE = {
    isFetching: false,
    error: null,
};

const resendEmailReducers = {
    [RESEND_EMAIL_REQUEST]: (state) => ({
        ...state, isFetching: true, error: null,
    }),
    [RESEND_EMAIL_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [RESEND_EMAIL_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

function token(state = RESEND_EMAIL_INITIAL_STATE, action) {
    const reducer = tokenReducers[action.type] || ((state) => state);
    return reducer(state, action);
}

function tokens(state = TOKEN_LIST_INITIAL_STATE, action) {
    const reducer = tokenListReducers[action.type] || ((state) => state);
    return reducer(state, action);
}

function profile(state = USER_INITIAL_STATE, action) {
    const reducer = userReducers[action.type] || ((state) => state);
    return reducer(state, action);
}

function list(state = USER_LIST_INITIAL_STATE, action) {
    const reducer = userListReducers[action.type] || ((state) => state);
    return reducer(state, action);
}

function permissions(state = PERMISSIONS_INITIAL_STATE, action) {
    const reducer = permissionsReducer[action.type] || ((state) => state);
    return reducer(state, action);
}

function invite(state = INVITE_INITIAL_STATE, action) {
    const reducer = inviteReducer[action.type] || ((state) => state);
    return reducer(state, action);
}

function resendEmail(state = RESEND_EMAIL_INITIAL_STATE, action) {
    const reducer = resendEmailReducers[action.type] || ((state) => state);
    return reducer(state, action);
}

export default combineReducers({
    list,
    permissions,
    invite,
    profile,
    tokens,
    token,
    resendEmail,
});
