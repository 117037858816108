import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
let onDeleteSuccess;
let onDeleteFailed;
const getPromise = () => new Promise((resolve, reject) => {
    onDeleteSuccess = resolve;
    onDeleteFailed = reject;
});

export default function useConfirm(target, onRequestComplete = () => {}) {
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);

    useEffect(() => {
        if (!deleteConfirmed || target.isFetching) return;
        setDeleteConfirmed(false);
        if (!target.error) {
            onDeleteSuccess();
            onRequestComplete();
        }
        if (target.error) {
            const { res: { message: msg } } = target.error;
            message.error(msg);
            onDeleteFailed();
        }
    }, [target.isFetching]);

    const showConfirm = (title, onOk) => {
        confirm({
            title,
            icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
            onOk() {
                setDeleteConfirmed(true);
                onOk();
                return getPromise();
            },
            onCancel(close) {
                setDeleteConfirmed(false);
                close();
            },
        });
    };

    return showConfirm;
}
