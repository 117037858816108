import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, Button, DatePicker, Form, Row, Col, Input, Divider, Checkbox, message, Switch, Typography, Collapse } from 'antd';
import { BoldOutlined, PlusOutlined, SaveOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
const { Text } = Typography;
const { Panel } = Collapse;

class BookVisits extends Component {

    constructor(props) {
        super(props);

        this.state = {
            day: '',
            name: '',
            bookVisits: [],
            countBookVisits: [],
            events: [],
            drawerVisible: false,
            editVisible: false,
            drawerReportVisible: false,
            selectedDate: new Date(),
            currentDate: new Date(),
            startDate: moment().subtract(1, 'months'),
            endDate: new Date(),
            currentView: 'month',
            displayedDateRange: {},
        };

        this.getBookVisits = this.getBookVisits.bind(this);
        this.getCountBookVisits = this.getCountBookVisits.bind(this);

        this.showEdit = this.showEdit.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);

        this.setCurrentDate = this.setCurrentDate.bind(this);
        this.setCurrentView = this.setCurrentView.bind(this);

        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.downloadBookVisitsReport = this.downloadBookVisitsReport.bind(this);
    }

    setStartDate(e) {
        this.setState({
            startDate: e,
        });
    };
    setEndDate(e) {
        this.setState({
            endDate: e,
        });
    };
    downloadBookVisitsReport() {
        return axios.get('/api/book_visit/download_book_visits_report_csv?start_date=' + moment(this.state.startDate).format("YYYY-MM-DD") +
            '&end_date=' + moment(this.state.endDate).format("YYYY-MM-DD"), {
            start_date: moment(this.state.startDate).format("YYYY-MM-DD"),
            end_date: moment(this.state.endDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {

            }
        });
    }

    showEdit(e) {
        e.preventDefault();
        this.setState({
            editVisible: true,
            drawerVisible: true
        });
    };
    closeDrawer() {
        this.setState({
            drawerVisible: false
        });
        if (this.state.editVisible) {
            this.setState({
                editVisible: false,
                drawerVisible: false
            });
        }
    };
    openDrawer(e) {
        this.setState({
            drawerVisible: true,
            selectAll: false,
            selectedDate: moment(e.start).format("YYYY-MM-DD")

        });
        console.log(this.state.selectedDate);
        this.getBookVisits();
    };

    getCountBookVisits() {

        let start = moment(this.state.currentDate).startOf(this.state.currentView);
        let end = moment(this.state.currentDate).endOf(this.state.currentView);
        if (this.state.currentView == 'month') {
            start = start.startOf('week');
            end = end.endOf('week');
        }
        start = start.format("YYYY-MM-DD");
        end = end.format("YYYY-MM-DD");
        console.log(this.state.currentDate);
        console.log(start);

        return axios.post('/api/book_visit/count_book_visits', {
            start: start,
            end: end
        }).then((
            response
        ) => {
            let countBookVisits = [];
            let i = 0;

            for (var key in response.data) {
                countBookVisits[i] = {};
                countBookVisits[i].title = (response.data[key] ? response.data[key] + ' bookings' : '');
                countBookVisits[i].allDay = false;
                countBookVisits[i].start = new Date(key);
                countBookVisits[i].end = new Date(key);
                i++;
            }
            this.setState({
                countBookVisits: [...countBookVisits]
            });
        });
    }

    getBookVisits() {
        let bookVisits = [];
        return axios.post('/api/book_visit/get_book_visits', {
            day: moment(this.state.selectedDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                bookVisits = response.data;
            }
            this.setState({
                bookVisits: bookVisits
            });
        });
    }

    eventStyleGetter(event, start, end, isSelected) {
        var style = {
            background: 'rgba(255,255,255,0)',
            borderRadius: '0px',
            color: '#1890FF',
            border: '0px',
            display: 'block',
            fontSize: 12,
            whiteSpace: 'pre-line',
            marginTop: 15,
            cursor: "auto"
        };
        return {
            style: style
        };
    }

    /** for navigation **/
    async setCurrentDate(date) {
        await this.setState({ currentDate: date });
        this.computeDisplayedDateRange();
        this.getCountBookVisits();
    }
    async setCurrentView(view) {
        await this.setState({ currentView: view });
        this.computeDisplayedDateRange();
    }

    computeDisplayedDateRange() {
        const { currentDate, currentView } = this.state;
        let start = moment(currentDate).startOf(currentView);
        let end = moment(currentDate).endOf(currentView);
        if (currentView == 'month') {
            start = start.startOf('week');
            end = end.endOf('week');
        }
        this.setState({ displayedDateRange: { start: start.toString(), end: end.toString() } })
    }
    /** end for navigation **/

    componentDidMount() {
        this.computeDisplayedDateRange();
        this.getCountBookVisits();
    }

    render() {
        const { currentDate, currentView, displayedDateRange } = this.state;
        return (

            <div style={{
                marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10
            }}
            >
                <div>
                    <div style={{ position: 'absolute', top: 10, right: 10 }}>
                        <DatePicker value={moment(this.state.startDate)} format="YYYY-MM-DD" onChange={this.setStartDate} />&nbsp;
                        <DatePicker value={moment(this.state.endDate)} format="YYYY-MM-DD" onChange={this.setEndDate} />&nbsp;
                        <Button type="primary" onClick={this.downloadBookVisitsReport}>
                            <a href={"/api/book_visit/download_book_visits_report_csv?start_date=" + moment(this.state.startDate).format("YYYY-MM-DD") + "&end_date=" + moment(this.state.endDate).format("YYYY-MM-DD")}><DownloadOutlined />  Download</a>
                        </Button>
                    </div>
                    <Calendar
                        localizer={localizer}
                        events={this.state.countBookVisits}
                        style={{ height: 600, fontSize: 16, whiteSpace: "pre-line", cursor: "pointer" }}
                        views={['month']}
                        onSelectSlot={this.openDrawer}
                        selectable={true}
                        eventPropGetter={(this.eventStyleGetter)}
                        startAccessor="start"
                        endAccessor="end"
                        date={currentDate}
                        view={currentView}
                        onNavigate={this.setCurrentDate}
                        onView={this.setCurrentView}

                    />
                </div>

                <Drawer
                    title={'Booked Visits on ' + this.state.selectedDate}
                    width={500}
                    onClose={this.closeDrawer}
                    visible={this.state.drawerVisible}
                    maskClosable={true}
                    destroyOnClose>
                    {this.state.bookVisits.length ? (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Collapse bordered={false} className="site-collapse-custom-collapse ">
                                    {this.state.bookVisits.map((booking, index) => {
                                        return <Panel header={booking.boat_name + ' (' + booking.number_of_days + ' days from: '
                                            + booking.arrival_date + ' to: ' + booking.departure_date + ')'}
                                            key={index} style={{ backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 0, margin: 0 }}>
                                            <div style={{ color: '#888', padding: 0, margin: 2 }}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th>Boat Name: </th>
                                                            <td>{booking.boat_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Contact Name: </th>
                                                            <td>{booking.title} {booking.first_name} {booking.surname}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone: </th>
                                                            <td>{booking.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Email: </th>
                                                            <td>{booking.email}</td>
                                                        </tr>
                                                        <tr >
                                                            <th style={{ verticalAlign: 'top' }}>Address: </th>
                                                            <td>
                                                                <address>{booking.address_line1}, {booking.address_line2}, <br />
                                                                    {booking.address_line3}<br />
                                                                    {booking.postcode}
                                                                </address>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Boat Length: </th>
                                                            <td>{booking.boat_length} {booking.length_unit}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Air Draft: </th>
                                                            <td>{booking.air_draft} {booking.air_draft_unit}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival From: </th>
                                                            <td>{booking.arriving_from}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival: </th>
                                                            <td>{booking.arrival_date}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival Lock: </th>
                                                            <td>{booking.arrival_lock}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Departure: </th>
                                                            <td>{booking.departure_date}</td>
                                                        </tr>
                                                         <tr>
                                                            <th>Departure Lock: </th>
                                                            <td>{booking.departure_lock}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Number of Days: </th>
                                                            <td>{booking.number_of_days}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Cost: </th>
                                                            <td>£ {booking.cost}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Home Port: </th>
                                                            <td>{booking.home_port}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Hull Type: </th>
                                                            <td>{booking.hull_type}</td>
                                                        </tr>
                                                       
                                                       
                                                        </tbody>
                                                </table>
                                            </div>
                                        </Panel>
                                    })}
                                </Collapse>
                            </Col>



                        </Row>
                    ) : <Text type="secondary">There are no book visits yet</Text>}
                    <Divider />

                </Drawer>
            </div >

        );
    }
}
export default BookVisits;