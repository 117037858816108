/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button, Form, Row, Col, Input, Divider, TimePicker, message, PageHeader, Radio,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import { updateMarina, fetchMarina } from '../../actions';

dayjs.extend(customParse);

const { RangePicker } = TimePicker;

function EditMarina(props) {
    const {
        marinaId,
        marina,
        updateMarina,
        fetchMarina,
        history,
    } = props;
    const { id, error, isFetching } = marina;
    const [values, setValues] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [form] = Form.useForm();
    const {
        open_time_weekdays: openWeekdays,
        open_time_saturday: openSaturday,
        open_time_sunday: openSunday,
    } = marina;
    const [openTimes, setOpenTimes] = useState({
        weekdays: (openWeekdays !== 'CLOSED') ? 'OPEN' : 'CLOSED',
        saturday: (openSaturday !== 'CLOSED') ? 'OPEN' : 'CLOSED',
        sunday: (openSunday !== 'CLOSED') ? 'OPEN' : 'CLOSED',
    });

    useEffect(() => {
        if (!error) return;

        const { res: { errors: serverErrors, message: msg } } = error;
        message.error(msg);

        if (!serverErrors) return;

        const fields = [];
        Object.keys(serverErrors).forEach((name) => {
            const fieldErrors = serverErrors[name];
            fields.push({
                name,
                value: values[name],
                errors: fieldErrors,
            });
        });
        form.setFields(fields);
    }, [error]);

    useEffect(() => {
        if (!submitted || isFetching) return;
        if (!error) {
            setSubmitted(false);
            message.success('Successfully updated');
            setTimeout(() => history.goBack(), 100);
        }
    }, [isFetching]);

    useEffect(() => {
        if (!id) {
            fetchMarina(marinaId);
        }
    }, []);

    const parseTime = (val) => {
        if (typeof val !== 'string') return val;
        if (val === 'CLOSED') return '';
        return val.split(',').map((t) => dayjs(t, 'h:mm A'));
    };
    const formatTime = (timeRange, field) => {
        if (openTimes[field] === 'CLOSED') {
            return 'CLOSED';
        }

        return timeRange.map((t) => dayjs(t).format('h:mm A')).join(',');
    };

    const getInitialValues = () => {
        const marinaCopy = { ...marina };

        marinaCopy.open_time_weekdays = parseTime(marinaCopy.open_time_weekdays);
        marinaCopy.open_time_saturday = parseTime(marinaCopy.open_time_saturday);
        marinaCopy.open_time_sunday = parseTime(marinaCopy.open_time_sunday);
        marinaCopy.loc_lat = `${marinaCopy.loc_lat}`;
        marinaCopy.loc_long = `${marinaCopy.loc_long}`;

        return marinaCopy;
    };

    const makeProperCase = (text) => text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    const onFinish = (formValues) => {
        setSubmitted(true);
        setValues(formValues);

        const formVals = { ...formValues };
        formVals.open_time_weekdays = formatTime(formVals.open_time_weekdays, 'weekdays');
        formVals.open_time_saturday = formatTime(formVals.open_time_saturday, 'saturday');
        formVals.open_time_sunday = formatTime(formVals.open_time_sunday, 'sunday');
        formVals.location = marina.location;

        updateMarina(marinaId, formVals);
    };

    if (!marina.id) return null;

    const handleOpenCloseChange = (field) => (e) => {
        e.preventDefault();
        setOpenTimes({ ...openTimes, [field]: e.target.value });
    };

    const renderOpenTimes = (name, placeholder, field) => (
        <div>
            <Form.Item
                style={{ display: 'inline-block' }}
                name={name}
                rules={[{ required: openTimes[field] !== 'CLOSED', message: 'Required' }]}
                hasFeedback
            >
                <RangePicker
                    use12Hours
                    placeholder={placeholder}
                    format="h:mm A"
                    disabled={openTimes[field] === 'CLOSED'}
                />
            </Form.Item>
            <Radio.Group
                onChange={handleOpenCloseChange(field)}
                buttonStyle="solid"
                style={{ marginLeft: 8 }}
                defaultValue={openTimes[field]}
            >
                <Radio.Button value="OPEN">OPEN</Radio.Button>
                <Radio.Button value="CLOSED">CLOSED</Radio.Button>
            </Radio.Group>
        </div>
    );

    const CreateForm = (
        <Form
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={getInitialValues()}
            colon={false}
        >
            <Row gutter={[16, 16]}>
                <Col xl={12} lg={24}>
                    <Form.Item label="Marina Name" name="marina_name" rules={[{ required: true, message: 'Marina is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                        <Input placeholder="Please provide Marina Name" />
                    </Form.Item>
                    <Form.Item label="Location" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }} name="loc_lat" rules={[{ required: true, message: 'Location Lattitude is required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Lattitude" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="loc_long" rules={[{ required: true, message: 'Location Longitude is required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Longitude" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Address" name="address_1" rules={[{ required: true, message: 'Address is required', whitespace: true }]} hasFeedback>
                        <Input placeholder="Please provide Address of Marina" />
                    </Form.Item>
                    <Form.Item label="Opening Hours" style={{ marginBottom: 0 }}>
                        {renderOpenTimes('open_time_weekdays', ['Monday', 'Friday'], 'weekdays')}
                        {renderOpenTimes('open_time_saturday', ['Saturday', ''], 'saturday')}
                        {renderOpenTimes('open_time_sunday', ['Sunday', ''], 'sunday')}
                    </Form.Item>
                    <Form.Item label="Contact Name" name="contact_name" rules={[{ required: true, message: 'Contact is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                        <Input placeholder="Please provide Marina contact name" />
                    </Form.Item>
                    <Form.Item label="Phone" style={{ marginBottom: 0 }}>
                        <Form.Item
                            name="phone"
                            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }}
                            rules={[{
                                required: true, message: 'Phone number is required', whitespace: true,
                            }, { pattern: /\d{11}$/, message: 'Invalid phone number' }]}
                            hasFeedback
                        >
                            <Input placeholder="Please provide Marina contact phone no" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="phone_label" rules={[{ required: true, message: 'Required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Label" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Phone 1" style={{ marginBottom: 0 }}>
                        <Form.Item
                            name="phone_1"
                            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }}
                            rules={[{ pattern: /\d{11}$/, message: 'Invalid phone number' }]}
                            hasFeedback
                        >
                            <Input placeholder="Additional Phone no 1" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="phone_1_label" hasFeedback>
                            <Input placeholder="Label" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Phone 2" style={{ marginBottom: 0 }}>
                        <Form.Item
                            name="phone_2"
                            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }}
                            rules={[{ pattern: /\d{11}$/, message: 'Invalid phone number' }]}
                            hasFeedback
                        >
                            <Input placeholder="Additional Phone No 2" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="phone_2_label" hasFeedback>
                            <Input placeholder="Label" />
                        </Form.Item>
                    </Form.Item>
                </Col>
                <Col xl={12} lg={24}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{
                            required: true, message: 'Email is required', type: 'email', whitespace: true,
                        }]}
                        hasFeedback
                    >
                        <Input placeholder="Please provide contact email" />
                    </Form.Item>
                    <Form.Item label="Web Address" name="web" hasFeedback>
                        <Input placeholder="Please provide Marina web address" />
                    </Form.Item>
                    <Form.Item label="Facebook Link" name="facebook_link" hasFeedback>
                        <Input placeholder="Please provide Marina Facebook page link" />
                    </Form.Item>
                    <Form.Item label="Flickr Link" name="flickr_link" hasFeedback>
                        <Input placeholder="Please provide Marina Flickr link" />
                    </Form.Item>
                    <Form.Item label="Twitter Link" name="twitter_link" hasFeedback>
                        <Input placeholder="Please provide Marina Twitter link" />
                    </Form.Item>
                    <Form.Item label="VHF Channel 1" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }} name="channel_name" rules={[{ required: true, message: 'Required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Channel Name" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="channel_number" rules={[{ required: true, message: 'Required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Channel number" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="VHF Channel 2" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }} name="channel_name_1" hasFeedback>
                            <Input placeholder="Channel Name" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="channel_number_1" hasFeedback>
                            <Input placeholder="Channel number" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Waypoint position" name="position" rules={[{ required: true, message: 'Required', whitespace: true }]} hasFeedback>
                        <Input placeholder="" />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <div>
                <Button type="primary" htmlType="submit" loading={marina.isFetching} style={{ float: 'right' }}>
                    {marina.isFetching ? null : <SaveOutlined />}
                    {' '}
                    Update
                </Button>
            </div>
        </Form>
    );

    return (
        <PageHeader
            title="Edit Profile"
            className="site-page-header"
            onBack={() => history.goBack()}
        >
            {CreateForm}
        </PageHeader>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { marina: { profile }, me } = state;

    return {
        ...ownProps,
        marina: profile,
        marinaId: me.marina_id,
    };
};

export default connect(mapStateToProps, {
    updateMarina, fetchMarina,
})(EditMarina);
