import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import {
    PageHeader,
} from 'antd';
import PaginatedList from '../shared/PaginatedList';
import {
    fetchTides, fetchMarina,
} from '../../actions';

dayjs.extend(customParse).extend(utc);

function Tides(props) {
    const {
        fetchTides,
        fetchMarina,
        tides,
        history,
        marina,
        marinaId,
    } = props;
    const { station } = marina;

    useEffect(() => {
        if (marina.id) return;
        fetchMarina(marinaId);
    }, []);

    const columns = [{
        title: 'Day',
        key: 'day',
        render: (text, record) => dayjs(record.tide_date).tz('Europe/London').format('dddd'),
    }, {
        title: 'Date',
        dataIndex: 'tide_date',
        render: (text, record) => dayjs(record.tide_date).tz('Europe/London').format('DD/MM/YYYY'),
    }, {
        title: 'Time',
        dataIndex: 'tide_date',
        render: (text, record) => dayjs(record.tide_date).tz('Europe/London').format('HH:mm:ss'),
    }, {
        title: 'Tide Height',
        dataIndex: 'tide_height',
    }, {
        title: 'Tide State',
        dataIndex: 'event_type',
        render: (text) => (text === 'HighWater' ? 'High Water' : 'Low Water'),
    }];

    if (!marina.id) return null;

    const fetchData = () => (page) => fetchTides(station.id, page);
    const getSubtitle = () => {
        const { sync_range_from: from, sync_range_to: to } = station;
        return `${dayjs(from).tz('Europe/London').format('DD/MM/YYYY')} - ${dayjs(to).tz('Europe/London').format('DD/MM/YYYY')}`;
    };

    return (
        <PageHeader
            title="Tide Times"
            className="tides-page-header"
            onBack={() => history.goBack()}
            subTitle={getSubtitle()}
        >
            <PaginatedList
                fetchData={fetchData()}
                columns={columns}
                source={tides.data}
                {...props}
            />
        </PageHeader>

    );
}

function mapStateToProps(state) {
    return {
        marinaId: state.me.marina_id,
        tides: state.tides,
        marina: state.marina.profile,
        loading: state.tides.isFetching,
        meta: state.tides.meta,
    };
}
export default connect(mapStateToProps, {
    fetchTides, fetchMarina,
})(Tides);
