import { TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE } from '../actions';

export const TOKEN_INITIAL_STATE = {
    email: '',
    name: '',
    isFetching: false,
    error: null,
};

const tokenReducers = {
    [TOKEN_REQUEST]: (state) => ({ ...state, isFetching: true }),
    [TOKEN_SUCCESS]: (state, { res }) => ({
        ...state,
        ...res,
        isFetching: false,
    }),
    [TOKEN_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function auth(state = TOKEN_INITIAL_STATE, action) {
    const reducer = tokenReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
