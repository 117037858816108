/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    PlusOutlined, CrownOutlined,
} from '@ant-design/icons';
import {
    Button, Table, Tag, PageHeader, Form, message, Modal, Input, Select, Badge,
} from 'antd';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import {
    fetchUsers, fetchPermissions, inviteUser, addUser, fetchMarina,
} from '../../actions';

const { Option } = Select;

dayjs.extend(customParse);

const setFormErrors = (form, error, values) => {
    const { res: { errors: serverErrors, message: msg } } = error;
    message.error(msg);

    if (!serverErrors) return;

    const fields = [];
    Object.keys(serverErrors).forEach((name) => {
        const fieldErrors = serverErrors[name];
        fields.push({
            name,
            value: values[name],
            errors: fieldErrors,
        });
    });
    form.setFields(fields);
};

function NewUserForm({
    visible, onCancel, loading, onCreate, error, permissions, marinaId,
}) {
    const [form] = Form.useForm();
    const [values, setValues] = useState({});

    useEffect(() => {
        if (!error) return;
        setFormErrors(form, error, values);
    }, [error]);

    const onOk = () => {
        form.validateFields().then((values) => {
            setValues(values);
            onCreate(values);
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    };

    return (
        <Modal
            title={`${marinaId ? 'Add' : 'Invite'} New User`}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            confirmLoading={loading}
            maskClosable={false}
            afterClose={() => form.resetFields()}
        >
            <Form
                form={form}
                layout="horizontal"
                name="typeForm"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                <Form.Item
                    hasFeedback
                    name="email"
                    label="Email"
                    rules={[{
                        required: true, message: 'Email is required', type: 'email', whitespace: true,
                    }]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                {permissions === false ? null : (
                    <Form.Item
                        hasFeedback
                        name="permissions"
                        label="Permissions"
                        rules={[{
                            required: true, message: 'Permissions are required', whitespace: true, type: 'array',
                        }]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                        >
                            {Object.keys(permissions).map((k) => <Option key={k} value={k}>{permissions[k]}</Option>)}
                        </Select>
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
}

function Users({
    fetchUsers,
    fetchPermissions,
    inviteUser,
    addUser,
    fetchMarina,
    users,
    permissions,
    loading,
    history,
    invite,
    me: { scope },
    match,
    location: { search = {} },
    marinaId,
    profile,
    marina,
}) {
    const [visible, setVisible] = useState(false);
    const [inviteUserSubmitted, setInviteUserSubmitted] = useState(false);
    const [addUserSubmitted, setAddUserSubmitted] = useState(false);
    const openAddModal = () => setVisible(true);
    const onCancel = () => setVisible(false);
    useEffect(() => {
        fetchUsers(marinaId);
        if (scope === 'marina' || (scope === 'homeport' && marinaId)) {
            fetchPermissions();
        }
        if (marinaId && (!marina.id || marina.id !== marinaId)) {
            fetchMarina(marinaId);
        }
    }, []);

    useEffect(() => {
        if (!inviteUserSubmitted || invite.isFetching) return;
        if (!invite.error) {
            setInviteUserSubmitted(false);
            fetchUsers();
            setVisible(false);
        }
    }, [invite.isFetching]);

    useEffect(() => {
        if (!addUserSubmitted || profile.isFetching) return;
        if (!profile.error) {
            setAddUserSubmitted(false);
            fetchUsers(marinaId);
            setVisible(false);
        }
    }, [profile.isFetching]);

    const onInvite = (values) => {
        setInviteUserSubmitted(true);
        const payload = { ...values };
        if (scope === 'marina') {
            payload.permissions = payload.permissions.join(',');
        }
        inviteUser(payload);
    };

    const onAdd = (values) => {
        setAddUserSubmitted(true);
        const payload = { ...values, marina_id: marinaId };
        payload.permissions = payload.permissions.join(',');
        addUser(payload);
    };

    const columns = [{
        title: '#',
        key: 'id',
        dataIndex: 'id',
        width: '2%',
    }, {
        title: 'Email',
        dataIndex: 'email',
        width: '15%',
        render: (text, { id, role }) => (
            <>
                {role === 'admin' ? (
                    <Badge count={<CrownOutlined style={{ color: '#f5222d' }} />}>
                        <Link to={{
                            pathname: `${match.url}/${id}`,
                            state: {
                                from: search || 'page=1',
                            },
                        }}
                        >
                            {text}
                        </Link>
                    </Badge>
                ) : (
                    <Link to={{
                        pathname: `${match.url}/${id}`,
                        state: {
                            from: search || 'page=1',
                        },
                    }}
                    >
                        {text}
                    </Link>
                )}
            </>
        ),
    }, {
        title: 'Name',
        dataIndex: 'contact_name',
        render: (text, { first_name, last_name }) => ([first_name, last_name].join(' ')),
    }, {
        title: 'Created At',
        dataIndex: 'created_at',
        render: (text) => dayjs(text, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    }, {
        title: 'Status',
        dataIndex: 'active',
        width: '10%',
        render: (active) => {
            if (active) return <Tag color="green">Active</Tag>;
            return <Tag color="orange">Inactive</Tag>;
        },
    }];

    const extra = [
        <Button key="1" onClick={openAddModal} type="primary">
            <PlusOutlined />
            {' '}
            {`${marinaId ? 'Add' : 'Invite'} New User`}
        </Button>,
    ];

    return (
        <PageHeader
            title={`${marinaId ? marina.marina_name : ''} Users`}
            className="site-page-header"
            onBack={() => history.goBack()}
            extra={extra}
        >
            <Table
                columns={columns}
                dataSource={users}
                loading={loading}
                rowKey={(record) => record.id}
                size="small"
                pagination={false}
            />
            <NewUserForm
                visible={visible}
                onCancel={onCancel}
                onCreate={marinaId ? onAdd : onInvite}
                error={marinaId ? profile.error : invite.error}
                loading={marinaId ? profile.isFetching : invite.isFetching}
                permissions={(scope === 'marina') || marinaId ? permissions : false}
                marinaId={marinaId}
            />
        </PageHeader>

    );
}

function mapStateToProps(state, { match: { params: { marina: marinaId } } }) {
    return {
        me: state.me,
        users: state.user.list.data,
        profile: state.user.profile,
        permissions: state.user.permissions.permissions,
        loading: state.user.list.isFetching,
        invite: state.user.invite,
        marinaId,
        marina: state.marina.profile,
    };
}
export default connect(
    mapStateToProps,
    {
        fetchUsers,
        fetchPermissions,
        inviteUser,
        addUser,
        fetchMarina,
    },
)(Users);
