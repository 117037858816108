import React from 'react';
import { connect } from 'react-redux';
import {
    Switch,
} from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import PrivateRoute from '../PrivateRoute';
import Layout from '../Layout';
import Sidebar from './Sidebar';
import Profile from './Profile';
import Edit from './Edit';
import Tides from './Tides';
import Users from '../shared/Users';
import User from '../shared/User';
import Locks from './LocksContainer';
import Rates from './RatesContainer';
import Notifications from './Notifications';
/**hassan edit */
import Swings from './Swings';
import BookVisits from './BookVisits';
import HouseDiary from './HouseDiary';
import BerthHolder from './BerthHolder';
/**end hassan edit */
import { permissionChecker } from '../../utils/permission';

function Root(props) {
    const { me: { role, permissions } } = props;

    const renderRoutes = ({ match }) => {
        const hasPermission = permissionChecker(role, permissions);
        return (
            <Switch>
                {(
                    hasPermission('view.rates')
                    || hasPermission('calculate.rates')
                    || hasPermission('edit.rates')
                ) ? <PrivateRoute path="/rates" component={Rates} /> : null}
                {hasPermission('view.locks') ? <PrivateRoute path="/locks" component={Locks} /> : null}
                {hasPermission('view.tides') ? <PrivateRoute path="/tides" component={Tides} /> : null}
                {hasPermission('view.users') ? <PrivateRoute path="/users/:user" component={User} /> : null}
                {hasPermission('view.users') ? <PrivateRoute path="/users" component={Users} /> : null}
                {hasPermission('view.notifications') ? <PrivateRoute path="/notifications" component={Notifications} /> : null}
                {hasPermission('view.swings') ? <PrivateRoute path="/swings" component={Swings} /> : null}
                {hasPermission('view.book_visits') ? <PrivateRoute path="/book_visits" component={BookVisits} /> : null}
                {hasPermission('view.house_diary') ? <PrivateRoute path="/house_diary" component={HouseDiary} /> : null}
                 {hasPermission('view.berth_holders') ? <PrivateRoute path="/berth_holders" component={BerthHolder} /> : null}
                {hasPermission('edit.marina.profile') ? <PrivateRoute path="/profile/edit" component={Edit} /> : null}
                {hasPermission('view.marina.profile') ? <PrivateRoute path="/" component={Profile} /> : null}
            </Switch>
        );
    };

    const renderLoading = () => {
        const icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
            }}
            >
                <Spin size="large" tip="LOADING" indicator={icon} />
            </div>
        );
    };

    const { me: { isFetching } } = props;
    const renderSidebar = (props) => <Sidebar {...props} />;

    return (
        <Layout renderSidebar={renderSidebar} renderFooter={false}>
            {isFetching ? renderLoading() : renderRoutes(props)}
        </Layout>
    );
}

function mapStateToProps(state) {
    return {
        me: state.me,
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(Root);
