import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from '../actions';

export const RESET_PASSWORD_INITIAL_STATE = {
    isFetching: false,
    error: {},
};

const resetPasswordReducers = {
    [RESET_PASSWORD_REQUEST]: (state) => ({ isFetching: true, error: {} }),
    [RESET_PASSWORD_SUCCESS]: (state, { res }) => ({
        ...state,
        ...res,
        isFetching: false,
    }),
    [RESET_PASSWORD_FAILURE]: (state, { error: { res } }) => ({
        ...state,
        isFetching: false,
        error: res,
    }),
};

export default function resetPassword(state = RESET_PASSWORD_INITIAL_STATE, action) {
    const reducer = resetPasswordReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
