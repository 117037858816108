import {
    BUSINESS_TYPE_REQUEST,
    BUSINESS_TYPE_SUCCESS,
    BUSINESS_TYPE_FAILURE,
    STORE_BUSINESS_TYPE_REQUEST,
    STORE_BUSINESS_TYPE_SUCCESS,
    STORE_BUSINESS_TYPE_FAILURE,
    UPDATE_BUSINESS_TYPE_REQUEST,
    UPDATE_BUSINESS_TYPE_SUCCESS,
    UPDATE_BUSINESS_TYPE_FAILURE,
    DELETE_BUSINESS_TYPE_REQUEST,
    DELETE_BUSINESS_TYPE_SUCCESS,
    DELETE_BUSINESS_TYPE_FAILURE,
} from '../actions';

export const BUSINESS_TYPE_INITIAL_STATE = {
    id: null,
    name: '',
    isFetching: false,
    error: null,
};

const businessTypeReducers = {
    [STORE_BUSINESS_TYPE_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null,
    }),
    [STORE_BUSINESS_TYPE_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [STORE_BUSINESS_TYPE_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [BUSINESS_TYPE_REQUEST]: (state, { id }) => ({
        ...state, id, isFetching: true, error: null,
    }),
    [BUSINESS_TYPE_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [BUSINESS_TYPE_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [UPDATE_BUSINESS_TYPE_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [UPDATE_BUSINESS_TYPE_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [UPDATE_BUSINESS_TYPE_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [DELETE_BUSINESS_TYPE_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [DELETE_BUSINESS_TYPE_SUCCESS]: (state) => ({ ...BUSINESS_TYPE_INITIAL_STATE }),
    [DELETE_BUSINESS_TYPE_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

const businessTypeReducer = (state = BUSINESS_TYPE_INITIAL_STATE, action) => {
    const reducer = businessTypeReducers[action.type] || ((state) => state);
    return reducer(state, action);
};

export default businessTypeReducer;
