import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import {
    syncLocations, storeMarina, updateMarina, fetchMarina, resetMarina, syncStations,
} from '../../actions';
import Marina from './Marina';

function MarinaContainer(props) {
    const {
        setExtras,
        setTitle,
        isNew,
        syncLocations,
        marina,
        isFetchingLocations,
        isFetchingStations,
        fetchMarina,
        fetchingMarina,
        resetMarina,
        history,
        syncStations,
    } = props;
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setExtras([null]);
    }, [setExtras]);
    useEffect(() => {
        if (isNew) {
            setTitle('Create New Marina');
        } else {
            setTitle('Edit Marina');
        }
    }, [setTitle]);

    useEffect(() => {
        if (isNew && marina.id && !submitted) {
            resetMarina();
            return;
        }

        const { marina: marinaId } = props.match.params;
        if (isNew || (marina.id && marina.id === +marinaId) || fetchingMarina) return;

        fetchMarina(+marinaId);
    }, [marina.id]);

    useEffect(() => {
        if (!submitted || marina.isFetching) return;
        if (!marina.error) {
            if (isNew) {
                message.success('Successfully created');
                setTimeout(() => history.goBack(), 100);
                return;
            }
            if (!isNew) {
                setSubmitted(false);
                message.success('Successfully updated');
                setTimeout(() => history.goBack(), 100);
            }
        }
    }, [marina.isFetching]);

    const onFinish = (payload) => {
        setSubmitted(true);
        const {
            storeMarina,
            updateMarina,
            marina,
            isNew,
        } = props;

        const fn = isNew ? storeMarina : updateMarina;
        const args = isNew ? [payload] : [marina.id, { ...payload, id: marina.id }];
        return fn(...args);
    };

    const prosToPass = {
        isNew,
        marina,
        syncLocations,
        syncStations,
        isFetchingLocations,
        isFetchingStations,
        onSubmit: onFinish,
    };

    // fetching in progress
    if (!isNew && !marina.id) {
        return null;
    }

    // navigating to new after editing another one
    // reset before render
    if (isNew && marina.id && !submitted) {
        return null;
    }

    return <Marina {...prosToPass} />;
}

const mapStateToProps = (state, ownProps) => {
    const { locations, stations, marina: { profile } } = state;

    return {
        isFetchingLocations: locations.isFetching,
        isFetchingStations: stations.isFetching,
        ...ownProps,
        marina: { ...profile },
        fetchingMarina: profile.isFetching,
    };
};

export default connect(mapStateToProps, {
    syncLocations, storeMarina, updateMarina, fetchMarina, resetMarina, syncStations,
})(MarinaContainer);
