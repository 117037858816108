import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    withRouter,
    NavLink,
} from 'react-router-dom';
import {
    Form, Input, Button, message,
} from 'antd';

import { LockOutlined } from '@ant-design/icons';
import { resetPassword } from '../actions';

const FormItem = Form.Item;

function ResetPassword({
    history,
    location: { search },
    resetPassword,
    reset: { error, isFetching: resetting },
}) {
    const [values, setValues] = useState(null);
    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);
    const [full, token, email] = search.match(/token=(\w+)(?:&email=(.+))?/) || ['', '', ''];

    useEffect(() => {
        if (!submitted || resetting) return;
        const { password, password_confirmation } = values;
        if (submitted && error.message) {
            message.error(error.message);
            form.setFields([{
                name: 'password',
                value: password,
                errors: [error.message],
            },
            {
                name: 'password_confirmation',
                value: password_confirmation,
                errors: [error.message],
            }]);
            return;
        }
        setSubmitted(false);
        message.success('Reset success, please login');
        setTimeout(() => {
            history.replace('/login');
        }, 1000);
    }, [resetting, error.message]);

    const onFinish = (formValues) => {
        setValues(formValues);
        setSubmitted(true);
        resetPassword({ ...formValues, token, email });
    };

    return (
        <div className="login-form">
            <NavLink to="/">
                <div className="login-logo">
                    <img src="/img/logo.jpg" alt="My homeport logo" />
                </div>
            </NavLink>
            <Form onFinish={onFinish}>
                <FormItem
                    name="password"
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please input your Password!' },
                        { message: 'At least 8 characters!', min: 8 },
                    ]}
                >
                    <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" />
                </FormItem>
                <FormItem
                    name="password_confirmation"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please confirm your Password!' },
                        { message: 'At least 8 characters!', min: 8 },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Passwords do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirm Password" />
                </FormItem>
                <FormItem>
                    <Button loading={resetting} type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                        Reset Password
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    reset: state.resetPassword,
});

export default withRouter(connect(mapStateToProps, {
    resetPassword,
})(ResetPassword));
