import {
    TIDES_REQUEST,
    TIDES_SUCCESS,
    TIDES_FAILURE,
    SYNC_TIDES_REQUEST,
    SYNC_TIDES_SUCCESS,
    SYNC_TIDES_FAILURE,
    CLEAR_TIDES,
} from '../actions';

export const TIDES_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

const tidesReducers = {
    [TIDES_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [TIDES_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [TIDES_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [CLEAR_TIDES]: (state, action) => ({ ...TIDES_INITIAL_STATE }),
    [SYNC_TIDES_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null,
    }),
    [SYNC_TIDES_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [SYNC_TIDES_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function tides(state = TIDES_INITIAL_STATE, action) {
    const reducer = tidesReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
