import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    fetchLocks,
} from '../../actions';
import Locks from '../shared/LockClosures';
import { permissionChecker } from '../../utils/permission';

function LocksContainer(props) {
    const {
        setExtras,
        setTitle,
        locks,
        fetchLocks,
        role,
        scope,
        permissions,
        match: { params: { marina: marinaId } },
    } = props;

    useEffect(() => {
        setExtras([null]);
    }, [setExtras]);
    useEffect(() => {
        setTitle('Lock Closures');
    }, [setTitle]);

    const fetchData = (page, query) => {
        fetchLocks(page, query, marinaId);
    };
    const hasPermission = permissionChecker(role, permissions, scope);

    const prosToPass = {
        locks,
        fetchData,
        headerRequired: false,
        hasPermission,
        ...props,
    };

    return <Locks {...prosToPass} />;
}

const mapStateToProps = (state, ownProps) => {
    const { locks, me: { role, permissions, scope } } = state;

    return {
        ...ownProps,
        locks,
        role,
        scope,
        permissions,
    };
};

export default connect(mapStateToProps, {
    fetchLocks,
})(LocksContainer);
