/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
    Button, Form, Row, Col, Input, Divider, message, Select, TimePicker, Radio,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';

import Upload from '../shared/Upload';

dayjs.extend(customParse);

const { RangePicker } = TimePicker;

const setFormErrors = (form, error, values) => {
    const { res: { errors: serverErrors, message: msg } } = error;
    message.error(msg);

    if (!serverErrors) return;

    const fields = [];
    Object.keys(serverErrors).forEach((name) => {
        const fieldErrors = serverErrors[name];
        fields.push({
            name,
            value: values[name],
            errors: fieldErrors,
        });
    });
    form.setFields(fields);
};

export default function Sponsor(props) {
    const {
        isNew,
        sponsor,
        onSubmit,
        marinaId,
    } = props;
    const [values, setValues] = useState({});
    const [position, setPosition] = useState('top_right');
    const [sponsorIconPath, setSponsorIconPath] = useState('');
    const [form] = Form.useForm();
    const {
        open_time_weekdays: openWeekdays,
        open_time_saturday: openSaturday,
        open_time_sunday: openSunday,
    } = sponsor;
    const [openTimes, setOpenTimes] = useState({
        weekdays: isNew || (openWeekdays !== 'CLOSED') ? 'OPEN' : 'CLOSED',
        saturday: isNew || (openSaturday !== 'CLOSED') ? 'OPEN' : 'CLOSED',
        sunday: isNew || (openSunday !== 'CLOSED') ? 'OPEN' : 'CLOSED',
    });

    useEffect(() => {
        if (!sponsor.error) return;
        setFormErrors(form, sponsor.error, values);
    }, [sponsor.error]);

    const parseTime = (val) => {
        if (typeof val !== 'string') return val;
        if (val === 'CLOSED') return '';
        return val.split(',').map((t) => dayjs(t, 'h:mm A'));
    };
    const formatTime = (timeRange, field) => {
        if (openTimes[field] === 'CLOSED') {
            return 'CLOSED';
        }

        return timeRange.map((t) => dayjs(t).format('h:mm A')).join(',');
    };

    const getInitialValues = () => {
        if (isNew) {
            return {
                icon_alignment: 'Top Right',
            };
        }
        const sponsorCopy = { ...sponsor };
        sponsorCopy.open_time_weekdays = parseTime(sponsorCopy.open_time_weekdays);
        sponsorCopy.open_time_saturday = parseTime(sponsorCopy.open_time_saturday);
        sponsorCopy.open_time_sunday = parseTime(sponsorCopy.open_time_sunday);
        sponsorCopy.loc_lat = `${sponsorCopy.loc_lat}`;
        sponsorCopy.loc_long = `${sponsorCopy.loc_long}`;

        return sponsorCopy;
    };

    const makeProperCase = (text) => text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    const onFinish = (formValues) => {
        setValues(formValues);
        const formVals = { ...formValues };

        formVals.open_time_weekdays = formatTime(formVals.open_time_weekdays, 'weekdays');
        formVals.open_time_saturday = formatTime(formVals.open_time_saturday, 'saturday');
        formVals.open_time_sunday = formatTime(formVals.open_time_sunday, 'sunday');
        formVals.contact_name = `${formVals.contact_title}. ${formVals.contact_first_name} ${formVals.contact_surname}`;
        formVals.icon_location_sponsor = sponsorIconPath;

        if (!sponsorIconPath) {
            message.error('Forgot to upload required Sponsor Icon?');
            return;
        }

        onSubmit(formVals);
    };

    const handleFileUploadComplete = (updateFn) => ({ url }) => {
        updateFn(url);
    };

    const handlePositionChange = (val) => {
        setPosition(val === 'Top Right' ? 'top_right' : 'top_left');
    };

    const handleOpenCloseChange = (field) => (e) => {
        e.preventDefault();
        setOpenTimes({ ...openTimes, [field]: e.target.value });
    };

    const renderOpenTimes = (name, placeholder, field) => (
        <>
            <Form.Item
                style={{ display: 'inline-block', width: '62%' }}
                name={name}
                rules={[{ required: openTimes[field] !== 'CLOSED', message: 'Required' }]}
                hasFeedback
            >
                <RangePicker
                    style={{ width: '100%' }}
                    use12Hours
                    placeholder={placeholder}
                    format="h:mm A"
                    disabled={openTimes[field] === 'CLOSED'}
                />
            </Form.Item>
            <Radio.Group
                onChange={handleOpenCloseChange(field)}
                buttonStyle="solid"
                style={{ float: 'right' }}
                defaultValue={openTimes[field]}
            >
                <Radio.Button value="OPEN">OPEN</Radio.Button>
                <Radio.Button value="CLOSED">CLOSED</Radio.Button>
            </Radio.Group>
        </>
    );

    const SponsorForm = (
        <Form
            name="sponsorForm"
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={getInitialValues()}
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item label="Sponsor Logo" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(48% - 2px)', marginRight: 20 }} name="icon_alignment" rules={[{ required: true, message: 'Reqired', whitespace: true }]} hasFeedback>
                            <Select
                                placeholder="Select Alignment"
                                onChange={handlePositionChange}
                            >
                                <Select.Option value="Top Right">Top Right</Select.Option>
                                <Select.Option value="Top Left">Top Left</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block' }}>
                            <Upload
                                label=""
                                url={sponsor.icon_location_sponsor}
                                type="image"
                                onUploadComplete={handleFileUploadComplete(setSponsorIconPath)}
                                extra={{
                                    marina: marinaId,
                                    name: `sponsor_icon_${position}`,
                                }}
                            />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Sponsor Name" name="sponsor_name" rules={[{ required: true, message: 'Sponsor Name is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                        <Input placeholder="Please enter sponsor name" />
                    </Form.Item>
                    <Form.Item label="Address" name="address_1" rules={[{ required: true, message: 'Address is required', whitespace: true }]} hasFeedback>
                        <Input placeholder="Please provide sponsor's address" />
                    </Form.Item>
                    <Form.Item label="Location" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }} name="loc_lat" rules={[{ required: true, message: 'Location Lattitude is required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Lattitude" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="loc_long" rules={[{ required: true, message: 'Location Longitude is required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Longitude" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Opening Hours" style={{ marginBottom: 0 }}>
                        {renderOpenTimes('open_time_weekdays', ['Moday', 'Friday'], 'weekdays')}
                        {renderOpenTimes('open_time_saturday', ['Saturday', ''], 'saturday')}
                        {renderOpenTimes('open_time_sunday', ['Sunday', ''], 'sunday')}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Contact Name" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(18%)', marginRight: 8 }} name="contact_title" rules={[{ required: true, message: 'Reqired', whitespace: true }]} hasFeedback>
                            <Select
                                placeholder="Title"
                            >
                                <Select.Option value="Mr">Mr</Select.Option>
                                <Select.Option value="Mrs">Mrs</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(41% - 8px)', marginRight: 8 }} name="contact_first_name" rules={[{ required: true, message: 'Contact First Name is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(41% - 8px)' }} name="contact_surname" rules={[{ required: true, message: 'Contact Surname is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                            <Input placeholder="Surname" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[{
                            required: true, message: 'Phone number is required', whitespace: true,
                        }, { pattern: /\d{11}$/, message: 'Invalid phone number' }]}
                        hasFeedback
                    >
                        <Input placeholder="Please provide phone no" />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{
                            required: isNew, message: 'Email is required', type: 'email', whitespace: true,
                        }]}
                        hasFeedback
                    >
                        <Input placeholder="Please provide email" />
                    </Form.Item>
                    <Form.Item label="Web Address" name="web" hasFeedback>
                        <Input placeholder="Please provide web address" />
                    </Form.Item>
                    <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Message is required', whitespace: true }]}>
                        <Input.TextArea placeholder="Please provide message" />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <div>
                <Button type="primary" htmlType="submit" loading={sponsor.isFetching} style={{ float: 'right' }}>
                    {sponsor.isFetching ? null : <SaveOutlined />}
                    {' '}
                    {isNew ? 'Create' : 'Update'}
                </Button>
            </div>
        </Form>
    );

    return SponsorForm;
}
