import React, { useEffect } from 'react';
import { Provider, connect } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    withRouter,
    Switch,
} from 'react-router-dom';
import { notification } from 'antd';

import AdminRoot from './admin/Root';
import MarinaRoot from './marina/Root';
import Login from './Login';
import Layout from './Layout';
import NoMatch from './NoMatch';
import Signup from './Signup';
import ResetRequest from './ResetRequest';
import ResetPassword from './ResetPassword';
import { fetchMe, logoutUser } from '../actions';
import PrivateRoute from './PrivateRoute';
import { saveData, loadData, removeData } from '../storage/local-storage';
import Checkout from './Checkout';

function App({
    auth: { isAuthenticated, expired }, me: {
        scope, role, isFetching,
    }, logoutUser, location: { pathname },
}) {
    useEffect(() => {
        if (!expired) return;
        notification.error({
            message: 'Session Expired',
            description: 'Your current session has timed out. Redirecting to login.',
        });

        saveData('mhp', { returnTo: pathname });
        setTimeout(logoutUser, 1000);
    }, [expired]);

    if ((!role && isAuthenticated) || isFetching) return null;

    return (
        <Switch>
            {!isAuthenticated ? (
                <Route exact path="/">
                    <Layout>
                        <div id="landing-hero">
                            <span className="title">My Home Port LTD</span>
                        </div>
                    </Layout>
                </Route>
            ) : null}

            <Route
                exact
                path="/login"
                render={(props) => {
                    if (isAuthenticated) {
                        const { returnTo } = loadData('mhp');
                        if (returnTo) {
                            removeData('mhp');
                            return <Redirect to={returnTo} />;
                        }
                        return <Redirect to="/" />;
                    }
                    return <Login />;
                }}
            />
            <Route exact path="/forgot-password" component={ResetRequest} />
            <Route
                exact
                path="/reset-password"
                render={() => {
                    if (isAuthenticated) {
                        return <Redirect to="/" />;
                    }
                    return <ResetPassword />;
                }}
            />
            <Route
                exact
                path="/signup"
                render={() => {
                    if (isAuthenticated) {
                        return <Redirect to="/" />;
                    }
                    return <Signup />;
                }}
            />
            <Route exact path="/payment/checkout/:ref" component={Checkout} />
            {isAuthenticated && (scope === 'homeport') ? <PrivateRoute path="/" component={AdminRoot} /> : null}
            {isAuthenticated && (scope === 'marina') ? <PrivateRoute path="/" component={MarinaRoot} /> : null}
            <Route
                path="*"
                render={(props) => {
                    const { returnTo } = loadData('mhp');
                    if (returnTo && !isAuthenticated) {
                        return <Redirect to="/login" />;
                    }
                    if (expired) return null;
                    return <NoMatch />;
                }}
            />
        </Switch>
    );
}

const mapStateToProps = (state, ownProps) => ({ auth: state.auth, me: state.me, ...ownProps });
const Wrapped = withRouter(connect(mapStateToProps, { logoutUser })(App));

export default function Root({ store }) {
    useEffect(() => {
        const { auth: { isAuthenticated } } = store.getState();

        if (isAuthenticated) {
            store.dispatch(fetchMe());
        }
    }, [store]);

    return (
        <Provider store={store}>
            <Router>
                <Wrapped />
            </Router>
        </Provider>
    );
}
