/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Descriptions, Row, Col, Avatar, PageHeader, Button,
} from 'antd';
import {
    EditOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import {
    fetchMarina,
} from '../../actions';
import { permissionChecker } from '../../utils/permission';

dayjs.extend(customParse);

function MarinaProfile(props) {
    const {
        history,
        marina: {
            id: marina_id,
            app_name,
            welcome_text,
            marina_name,
            address_1,
            address_2,
            address_3,
            postcode,
            contact_name,
            phone,
            phone_label,
            phone_1,
            phone_1_label,
            phone_2,
            phone_2_label,
            email,
            web,
            facebook_link,
            flickr_link,
            twitter_link,
            open_time_weekdays,
            open_time_saturday,
            open_time_sunday,
            location,
            loc_lat,
            loc_long,
            channel_name,
            channel_number,
            channel_name_1,
            channel_number_1,
            position,
            approved,
            icon_location_app,
            icon_location_marina,
            error,
            isFetching,
        },
        fetchMarina,
        id,
        role,
        permissions,
    } = props;

    const locationName = location ? location.location_name : '-';
    const formatTimeRange = (val) => {
        if (typeof val !== 'string') return null;
        return val.split(',').join(' TO ');
    };
    const formatAddress = (...vals) => vals.filter((v) => !!v).join(', ');

    useEffect(() => {
        if (marina_id) return;
        fetchMarina(id);
    }, []);

    if (!marina_id) return null;
    const hasPermission = permissionChecker(role, permissions);

    const extra = [];
    if (hasPermission('edit.marina.profile')) {
        extra.push((
            <Button key="1" type="primary">
                <Link to="/profile/edit">
                    <EditOutlined />
                    {' '}
                    Edit
                </Link>
            </Button>
        ));
    }
    return (
        <PageHeader
            title="Profile"
            className="site-page-header"
            onBack={() => history.goBack()}
            extra={extra}
        >
            <Row gutter={[16, 16]}>
                <Col xl={3} lg={4}>
                    <Descriptions layout="vertical" column={1} size="small">
                        <Descriptions.Item label="Marina Icon">
                            <Avatar size={128} src={icon_location_marina} shape="square" alt="Marina Icon" style={{ marginBottom: 10, padding: 5, marginLeft: -5 }} />
                        </Descriptions.Item>
                        <Descriptions.Item label="App Icon">
                            <Avatar size={128} src={icon_location_app} shape="square" alt="App Icon" style={{ marginBottom: 10, padding: 5, marginLeft: -5 }} />
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col xl={21} lg={20}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Descriptions layout="vertical" column={4} size="small">
                                <Descriptions.Item label="Marina Name">{marina_name}</Descriptions.Item>
                                <Descriptions.Item label="Address">{formatAddress(address_1, address_2, address_3, postcode)}</Descriptions.Item>
                                <Descriptions.Item label="App Name">{app_name}</Descriptions.Item>
                                <Descriptions.Item label="Welcome To Text">{welcome_text}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Descriptions layout="vertical" column={4} size="small">
                                <Descriptions.Item label="Weather Location">{locationName}</Descriptions.Item>
                                <Descriptions.Item label="Location latitude">{loc_lat}</Descriptions.Item>
                                <Descriptions.Item label="Location longitude">{loc_long}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Descriptions title="Opening Hours" layout="vertical" column={4} size="small">
                                <Descriptions.Item label="Moday to Friday">{formatTimeRange(open_time_weekdays)}</Descriptions.Item>
                                <Descriptions.Item label="Saturday">{formatTimeRange(open_time_saturday)}</Descriptions.Item>
                                <Descriptions.Item label="Sunday">{formatTimeRange(open_time_sunday)}</Descriptions.Item>
                            </Descriptions>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Descriptions title="Contact Details" layout="vertical" column={4} size="small">
                                <Descriptions.Item label="Contact Name">{contact_name}</Descriptions.Item>
                                <Descriptions.Item label={`Contact Phone No (${phone_label})`}>{phone}</Descriptions.Item>
                                {phone_1 ? (<Descriptions.Item label={`Contact Phone No (${phone_1_label})`}>{phone_1}</Descriptions.Item>) : null}
                                {phone_2 ? (<Descriptions.Item label={`Contact Phone No (${phone_2_label})`}>{phone_2}</Descriptions.Item>) : null}
                                <Descriptions.Item label="Contact Email">{email}</Descriptions.Item>
                                <Descriptions.Item label="Website">{web}</Descriptions.Item>
                            </Descriptions>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Descriptions title="Other Details" layout="vertical" column={4} size="small">
                                <Descriptions.Item label="VHF Channel 1">
                                    {channel_name}
                                    {' '}
                                    {channel_number}
                                </Descriptions.Item>
                                <Descriptions.Item label="VHF Channel 2">
                                    {channel_name_1}
                                    {' '}
                                    {channel_number_1}
                                </Descriptions.Item>
                                <Descriptions.Item label="Waypoint position">{position}</Descriptions.Item>
                            </Descriptions>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Descriptions title="Social Media" layout="vertical" column={4} size="small">
                                <Descriptions.Item label="Facebook">{facebook_link}</Descriptions.Item>
                                <Descriptions.Item label="Flickr">{flickr_link}</Descriptions.Item>
                                <Descriptions.Item label="Twitter">{twitter_link}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PageHeader>
    );
}

function mapStateToProps(state) {
    const marinaId = state.me.marina_id;
    const marina = state.marina.profile;
    const { me: { role, permissions } } = state;

    return {
        marina,
        role,
        permissions,
        id: marinaId,
    };
}
export default connect(mapStateToProps, {
    fetchMarina,
})(MarinaProfile);
