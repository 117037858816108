import React from 'react';
import {
    Form, Row, Col, Select, Input, Divider, Button, Statistic, Skeleton, Alert, Typography,
} from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

export default function Calculate({
    def,
    onFinish,
    onCancel,
    computed: {
        isFetching, values, type, message, error, notes,
    },
    render,
}) {
    const [form] = Form.useForm();
    const renderDef = ({
        type, label, name, rules, placeholder, style = {}, options = [], fields = [],
    }) => {
        if (type === 'select') {
            return (
                <Form.Item
                    key={`select-${name}`}
                    {...{
                        label, name, rules, style,
                    }}
                    hasFeedback
                >
                    <Select
                        placeholder={placeholder}
                    >
                        {options.map(({
                            id,
                            name,
                            label,
                        }) => <Select.Option value={name} key={id}>{label}</Select.Option>)}
                    </Select>
                </Form.Item>
            );
        }
        if (type === 'input') {
            return (
                <Form.Item
                    key={`input-${name}`}
                    {...{
                        label, name, rules, style,
                    }}
                    hasFeedback
                >
                    <Input placeholder={placeholder} />
                </Form.Item>
            );
        }
        if (type === 'nested') {
            return (
                <Form.Item
                    key={`nested-${name}`}
                    label={label}
                    style={style}
                >
                    {fields.map(renderDef)}
                </Form.Item>
            );
        }
        return null;
    };

    let fieldDef = null;
    try {
        fieldDef = JSON.parse(def);
    } catch (e) {
        console.error(e);
    }
    const calculator = () => {
        if (typeof render === 'function') {
            return render(onFinish, onCancel, isFetching);
        }

        return (
            <Form
                name="calculate-form"
                layout="horizontal"
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        {fieldDef ? fieldDef.map(renderDef) : null}
                    </Col>
                </Row>
                <Divider />
                <div style={{ textAlign: 'right' }}>
                    <Button type="danger" style={{ marginRight: 5 }} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" loading={isFetching}>
                        <CalculatorOutlined />
                        {' '}
                        Calculate
                    </Button>
                </div>
            </Form>
        );
    };

    const getDescription = () => {
        if (error) {
            const { res: { errors: serverErrors } } = error;
            return Object.keys(serverErrors).map((name) => {
                const fieldErrors = serverErrors[name];
                return <li key={name}>{fieldErrors}</li>;
            });
        }
        return null;
    };

    return (
        <>
            <Row gutter={[16, 16]}>
                <Skeleton loading={isFetching}>
                    {type === 'error' || error ? (
                        <Col span={24}>
                            <Alert
                                message={error ? error.res.message : message}
                                description={getDescription()}
                                type="error"
                                showIcon
                            />
                        </Col>
                    ) : values.map(({ label, value }) => (
                        <Col span={12} key={label}>
                            <Statistic
                                title={label}
                                value={value}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                prefix="£"
                            />
                        </Col>
                    ))}
                    {notes ? (
                        <Col span={24}>
                            <Alert
                                message="Notes"
                                description={(
                                    <div>
                                        {notes.map((note, id) => <Paragraph key={id}>{note}</Paragraph>)}
                                    </div>
                                )}
                                type="info"
                                showIcon
                            />
                        </Col>
                    ) : null}
                </Skeleton>
            </Row>

            {values.length || (type === 'error') || error ? (
                <Divider />
            ) : null}

            {calculator()}
        </>
    );
}
