/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    PlusOutlined,
} from '@ant-design/icons';
import {
    Button, Table,
} from 'antd';
import { fetchBusinesses, fetchMarina } from '../../actions';

function Adverts({
    match, setExtras, setTitle, fetchAdverts, fetchMarina, adverts, marina, loading,
}) {
    const { id: marina_id, marina_name } = marina;
    useEffect(() => {
        if (!marina_name) return;
        setExtras([
            <Button key="1" type="primary">
                <Link to={`${match.url}/new`}>
                    <PlusOutlined />
                    {' '}
                    Add New Business
                </Link>
            </Button>,
        ]);
    }, [setExtras, marina_name]);
    useEffect(() => {
        if (!marina_name) return;
        setTitle(`${marina_name} Businesses`);
    }, [setTitle, marina_name]);

    useEffect(() => {
        if (+match.params.marina !== marina.id) {
            fetchMarina(match.params.marina);
        }
        fetchAdverts(match.params.marina);
    }, [match.params.marina]);

    const columns = [{
        title: '#',
        key: 'id',
        dataIndex: 'id',
        width: '2%',
    }, {
        title: 'Business Name',
        dataIndex: 'business_name',
        key: 'business_name',
        render: (text, record) => (
            <Link to={`${match.url}/${record.id}`}>{text}</Link>
        ),
    }, {
        title: 'Business Type',
        key: 'business_type',
        render: (text, record) => record.business_type.name,
    }, {
        title: 'Contact Name',
        dataIndex: 'contact_name',
        key: 'contact_name',
    }, {
        title: 'Contact Phone',
        dataIndex: 'contact_phone',
        key: 'contact_phone',
    }, {
        title: 'Contact Email',
        dataIndex: 'contact_email',
        key: 'contact_email',
    }];

    if (!marina_id) return null;

    return (
        <Table
            columns={columns}
            dataSource={adverts}
            loading={loading}
            rowKey={(record) => record.id}
            size="small"
            pagination={false}
        />
    );
}

function mapStateToProps(state) {
    return {
        marina: state.marina.profile,
        adverts: state.marina.adverts.data,
        loading: state.marina.adverts.isFetching,
    };
}
export default connect(mapStateToProps, { fetchAdverts: fetchBusinesses, fetchMarina })(Adverts);
