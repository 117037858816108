import { VERIFY_PAYMENT_REQUEST, VERIFY_PAYMENT_SUCCESS, VERIFY_PAYMENT_FAILURE } from '../actions';

export const VERIFY_PAYMENT_INITIAL_STATE = {
    isFetching: false,
    error: null,
};

const capturePaymentReducers = {
    [VERIFY_PAYMENT_REQUEST]: (state) => ({ isFetching: true, error: null }),
    [VERIFY_PAYMENT_SUCCESS]: (state, { res }) => ({
        ...state,
        ...res,
        isFetching: false,
    }),
    [VERIFY_PAYMENT_FAILURE]: (state, { error: { res } }) => ({
        ...state,
        isFetching: false,
        error: res,
    }),
};

export default function capturePayment(state = VERIFY_PAYMENT_INITIAL_STATE, action) {
    const reducer = capturePaymentReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
