/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
    Button, Form, Row, Col, Input, Divider, TimePicker, Tooltip, message, Radio,
} from 'antd';
import { SaveOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';

import Upload from '../shared/Upload';
import LocationLookup from '../shared/LocationLookup';
import StationLookup from '../shared/StationLookup';

dayjs.extend(customParse);

const { RangePicker } = TimePicker;

export default function Marina(props) {
    const {
        marina,
        isNew,
        syncLocations,
        isFetchingLocations,
        onSubmit,
        syncStations,
        isFetchingStations,
    } = props;
    const {
        station,
        open_time_weekdays: openWeekdays,
        open_time_saturday: openSaturday,
        open_time_sunday: openSunday,
    } = marina;
    const [openTimes, setOpenTimes] = useState({
        weekdays: isNew || (openWeekdays !== 'CLOSED') ? 'OPEN' : 'CLOSED',
        saturday: isNew || (openSaturday !== 'CLOSED') ? 'OPEN' : 'CLOSED',
        sunday: isNew || (openSunday !== 'CLOSED') ? 'OPEN' : 'CLOSED',
    });
    const [appIconPath, setAppIconPath] = useState('');
    const [marinaIconPath, setMarinaIconPath] = useState('');
    const [values, setValues] = useState({});
    const [form] = Form.useForm();

    useEffect(() => {
        if (!marina.error) return;

        const { res: { errors: serverErrors, message: msg } } = marina.error;
        message.error(msg);

        if (!serverErrors) return;

        const fields = [];
        Object.keys(serverErrors).forEach((name) => {
            const fieldErrors = serverErrors[name];
            fields.push({
                name,
                value: values[name],
                errors: fieldErrors,
            });
        });
        form.setFields(fields);
    }, [marina.error]);

    const parseTime = (val) => {
        if (typeof val !== 'string') return val;
        if (val === 'CLOSED') return '';
        return val.split(',').map((t) => dayjs(t, 'h:mm A'));
    };
    const formatTime = (timeRange, field) => {
        if (openTimes[field] === 'CLOSED') {
            return 'CLOSED';
        }

        return timeRange.map((t) => dayjs(t).format('h:mm A')).join(',');
    };

    const getInitialValues = () => {
        if (isNew) return {};
        const marinaCopy = { ...marina };

        marinaCopy.open_time_weekdays = parseTime(marinaCopy.open_time_weekdays);
        marinaCopy.open_time_saturday = parseTime(marinaCopy.open_time_saturday);
        marinaCopy.open_time_sunday = parseTime(marinaCopy.open_time_sunday);
        marinaCopy.loc_lat = `${marinaCopy.loc_lat}`;
        marinaCopy.loc_long = `${marinaCopy.loc_long}`;

        return marinaCopy;
    };

    const makeProperCase = (text) => text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    const onFinish = (formValues) => {
        setValues(formValues);

        const formVals = { ...formValues };
        formVals.open_time_weekdays = formatTime(formVals.open_time_weekdays, 'weekdays');
        formVals.open_time_saturday = formatTime(formVals.open_time_saturday, 'saturday');
        formVals.open_time_sunday = formatTime(formVals.open_time_sunday, 'sunday');

        const payload = {
            ...formVals,
            icon_location_app: appIconPath,
            icon_location_marina: marinaIconPath,
        };

        if (!(appIconPath && marinaIconPath)) {
            message.error('Forgot to upload required App, Marina Icons?');
            return;
        }

        onSubmit(payload);
    };

    const handleLocationChange = (location) => {
        form.setFields([{
            name: 'loc_lat',
            value: location.latitude,
        },
        {
            name: 'loc_long',
            value: location.longitude,
        }]);
    };

    const handleFileUploadComplete = (updateFn) => ({ url }) => {
        updateFn(url);
    };

    const handleOpenCloseChange = (field) => (e) => {
        e.preventDefault();
        setOpenTimes({ ...openTimes, [field]: e.target.value });
    };

    const renderOpenTimes = (name, placeholder, field) => (
        <div>
            <Form.Item
                style={{ display: 'inline-block' }}
                name={name}
                rules={[{ required: openTimes[field] !== 'CLOSED', message: 'Required' }]}
                hasFeedback
            >
                <RangePicker
                    use12Hours
                    placeholder={placeholder}
                    format="h:mm A"
                    disabled={openTimes[field] === 'CLOSED'}
                />
            </Form.Item>
            <Radio.Group
                onChange={handleOpenCloseChange(field)}
                buttonStyle="solid"
                style={{ marginLeft: 8 }}
                defaultValue={openTimes[field]}
            >
                <Radio.Button value="OPEN">OPEN</Radio.Button>
                <Radio.Button value="CLOSED">CLOSED</Radio.Button>
            </Radio.Group>
        </div>
    );

    const CreateForm = (
        <Form
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={getInitialValues()}
            colon={false}
        >
            <Row gutter={[16, 16]}>
                <Col xl={12} lg={24}>
                    <Form.Item label="App Name" name="app_name" rules={[{ required: true, message: 'App Name is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                        <Input placeholder="Please enter app name" />
                    </Form.Item>
                    <Form.Item label="Welcome Text" name="welcome_text" rules={[{ required: true, message: 'Welcome Text is required', whitespace: true }]} hasFeedback>
                        <Input placeholder="Please enter welcome text" />
                    </Form.Item>
                    <Form.Item label="Tide Station" style={{ marginBottom: 0 }} rules={[{ required: true, message: 'Tide station is required', whitespace: true }]}>
                        <Form.Item
                            name="station"
                            rules={[{
                                required: true,
                                message: 'Station is required',
                                type: 'object',
                            }]}
                            style={{ width: 'calc(90%)', display: 'inline-block', marginRight: 5 }}
                        >
                            <StationLookup
                                placeholder="Please select tide station to connect with"
                                disabled={!isNew && !!station.id}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 'calc(10% - 5px)', display: 'inline-block' }}>
                            <Tooltip title="Sync stations if list is empty/outdated">
                                <Button onClick={syncStations} disabled={!isNew && !!station.id}><SyncOutlined spin={isFetchingStations} /></Button>
                            </Tooltip>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item
                        label="Weather Location"
                        style={{ marginBottom: 0 }}
                    >
                        <Form.Item
                            style={{ width: 'calc(90%)', display: 'inline-block', marginRight: 5 }}
                            name="location"
                            rules={[{
                                required: true,
                                message: 'Weather location is required',
                                type: 'object',
                            }]}
                        >
                            <LocationLookup
                                onUpdate={handleLocationChange}
                                placeholder="Please select weather location"
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 'calc(10% - 5px)', display: 'inline-block' }}>
                            <Tooltip title="Sync locations if list is empty/outdated">
                                <Button onClick={syncLocations}><SyncOutlined spin={isFetchingLocations} /></Button>
                            </Tooltip>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Location" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }} name="loc_lat" rules={[{ required: true, message: 'Location Latitude is required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Latitude" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="loc_long" rules={[{ required: true, message: 'Location Longitude is required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Longitude" />
                        </Form.Item>
                    </Form.Item>
                    {isNew ? (
                        <Form.Item
                            label="Username (Login email)"
                            name="username"
                            rules={[{
                                required: true, message: 'Username is required', whitespace: true,
                            }, {
                                message: 'Username should be a valid email', type: 'email',
                            }]}
                            hasFeedback
                        >
                            <Input placeholder="Please provide Marina Admin User Name" />
                        </Form.Item>
                    ) : null}
                    <Form.Item label="Marina Name" name="marina_name" rules={[{ required: true, message: 'Marina is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                        <Input placeholder="Please provide Marina Name" />
                    </Form.Item>
                    <Form.Item label="Address" name="address_1" rules={[{ required: true, message: 'Address is required', whitespace: true }]} hasFeedback>
                        <Input placeholder="Please provide Address of Marina" />
                    </Form.Item>
                    <Form.Item label="Opening Hours" style={{ marginBottom: 0 }}>
                        {renderOpenTimes('open_time_weekdays', ['Monday', 'Friday'], 'weekdays')}
                        {renderOpenTimes('open_time_saturday', ['Saturday', ''], 'saturday')}
                        {renderOpenTimes('open_time_sunday', ['Sunday', ''], 'sunday')}
                    </Form.Item>
                    <Form.Item label="Contact Name" name="contact_name" rules={[{ required: true, message: 'Contact is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                        <Input placeholder="Please provide Marina contact name" />
                    </Form.Item>
                    <Form.Item label="Phone" style={{ marginBottom: 0 }}>
                        <Form.Item
                            name="phone"
                            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }}
                            rules={[{
                                required: true, message: 'Phone number is required', whitespace: true,
                            }, { pattern: /\d{11}$/, message: 'Invalid phone number' }]}
                            hasFeedback
                        >
                            <Input placeholder="Please provide Marina contact phone no" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="phone_label" rules={[{ required: true, message: 'Required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Label" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Phone 1" style={{ marginBottom: 0 }}>
                        <Form.Item
                            name="phone_1"
                            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }}
                            rules={[{ pattern: /\d{11}$/, message: 'Invalid phone number' }]}
                            hasFeedback
                        >
                            <Input placeholder="Additional Phone no 1" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="phone_1_label" hasFeedback>
                            <Input placeholder="Label" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Phone 2" style={{ marginBottom: 0 }}>
                        <Form.Item
                            name="phone_2"
                            style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }}
                            rules={[{ pattern: /\d{11}$/, message: 'Invalid phone number' }]}
                            hasFeedback
                        >
                            <Input placeholder="Additional Phone No 2" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="phone_2_label" hasFeedback>
                            <Input placeholder="Label" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{
                            required: isNew, message: 'Email is required', type: 'email', whitespace: true,
                        }]}
                        hasFeedback
                    >
                        <Input placeholder="Please provide Marina contact email" />
                    </Form.Item>
                </Col>
                <Col xl={12} lg={24}>
                    <Form.Item label="Web Address" name="web" hasFeedback>
                        <Input placeholder="Please provide Marina web address" />
                    </Form.Item>
                    <Form.Item label="Facebook Link" name="facebook_link" hasFeedback>
                        <Input placeholder="Please provide Marina Facebook page link" />
                    </Form.Item>
                    <Form.Item label="Flickr Link" name="flickr_link" hasFeedback>
                        <Input placeholder="Please provide Marina Flickr link" />
                    </Form.Item>
                    <Form.Item label="Twitter Link" name="twitter_link" hasFeedback>
                        <Input placeholder="Please provide Marina Twitter link" />
                    </Form.Item>
                    <Form.Item label="VHF Channel 1" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }} name="channel_name" rules={[{ required: true, message: 'Required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Channel Name" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="channel_number" rules={[{ required: true, message: 'Required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Channel number" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="VHF Channel 2" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }} name="channel_name_1" hasFeedback>
                            <Input placeholder="Channel Name" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="channel_number_1" hasFeedback>
                            <Input placeholder="Channel number" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Waypoint position" name="position" rules={[{ required: true, message: 'Required', whitespace: true }]} hasFeedback>
                        <Input placeholder="" />
                    </Form.Item>
                    <Divider style={{ marginTop: 0 }}>Files</Divider>
                    <Row gutter={[16, 16]} justify="space-around" align="middle" id="marina-files">
                        <Col>
                            <Upload
                                label="App Icon"
                                url={marina.icon_location_app}
                                type="image"
                                onUploadComplete={handleFileUploadComplete(setAppIconPath)}
                                extra={isNew ? {} : {
                                    marina: marina.id,
                                    name: 'app_icon',
                                }}
                            />
                        </Col>
                        <Col>
                            <Upload
                                label="Marina Icon"
                                url={marina.icon_location_marina}
                                type="image"
                                onUploadComplete={handleFileUploadComplete(setMarinaIconPath)}
                                extra={isNew ? {} : {
                                    marina: marina.id,
                                    name: 'marina_icon',
                                }}
                            />
                        </Col>
                    </Row>
                    <Divider style={{ marginTop: 0 }}>Config</Divider>
                    <Form.Item label="FCM Credentials" name="fcm_credentials" hasFeedback>
                        <Input.TextArea placeholder="Please paste firebase_credentials.json content" rows={6} />
                    </Form.Item>
                </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <div>
                <Button type="primary" htmlType="submit" loading={marina.isFetching} style={{ float: 'right' }}>
                    {marina.isFetching ? null : <SaveOutlined />}
                    {' '}
                    {isNew ? 'Create' : 'Update'}
                </Button>
            </div>
        </Form>
    );

    return CreateForm;
}
