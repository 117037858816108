import {
    LOCKS_REQUEST,
    LOCKS_SUCCESS,
    LOCKS_FAILURE,
} from '../actions';

export const LOCKS_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

const locksReducers = {
    [LOCKS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [LOCKS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [LOCKS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function locks(state = LOCKS_INITIAL_STATE, action) {
    const reducer = locksReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
