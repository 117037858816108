/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    PlusOutlined,
} from '@ant-design/icons';
import {
    Button, Table, Avatar,
} from 'antd';
import { fetchSponsors, fetchMarina } from '../../actions';

function Adverts({
    match, setExtras, setTitle, fetchSponsors, fetchMarina, sponsors, marina, loading,
}) {
    const { id: marina_id, marina_name } = marina;
    useEffect(() => {
        if (!marina_name) return;
        setExtras([
            <Button key="1" type="primary">
                <Link to={`${match.url}/new`}>
                    <PlusOutlined />
                    {' '}
                    Add New Sponsor
                </Link>
            </Button>,
        ]);
    }, [setExtras, marina_name]);
    useEffect(() => {
        if (!marina_name) return;
        setTitle(`${marina_name} Sponsors`);
    }, [setTitle, marina_name]);

    useEffect(() => {
        if (+match.params.marina !== marina.id) {
            fetchMarina(match.params.marina);
        }
        fetchSponsors(match.params.marina);
    }, [match.params.marina]);

    const columns = [{
        title: '#',
        key: 'id',
        dataIndex: 'id',
        width: '2%',
    }, {
        title: 'Sponsor Name',
        dataIndex: 'sponsor_name',
        render: (text, record) => (
            <span>
                <Avatar src={record.icon_location_sponsor} size="small" />
                {' '}
                <Link to={`${match.url}/${record.id}`}>{text}</Link>
            </span>
        ),
    }, {
        title: 'Contact Name',
        dataIndex: 'contact_name',
    }, {
        title: 'Icon Alignment',
        dataIndex: 'icon_alignment',
    }, {
        title: 'Phone',
        dataIndex: 'phone',
    }, {
        title: 'Email',
        dataIndex: 'email',
    }];

    if (!marina_id) return null;

    return (
        <Table
            columns={columns}
            dataSource={sponsors}
            loading={loading}
            rowKey={(record) => record.id}
            size="small"
            pagination={false}
        />
    );
}

function mapStateToProps(state) {
    return {
        marina: state.marina.profile,
        sponsors: state.marina.sponsors.data,
        loading: state.marina.sponsors.isFetching,
    };
}
export default connect(mapStateToProps, { fetchSponsors, fetchMarina })(Adverts);
