import {
    SPONSOR_REQUEST,
    SPONSOR_SUCCESS,
    SPONSOR_FAILURE,
    STORE_SPONSOR_REQUEST,
    STORE_SPONSOR_SUCCESS,
    STORE_SPONSOR_FAILURE,
    UPDATE_SPONSOR_REQUEST,
    UPDATE_SPONSOR_SUCCESS,
    UPDATE_SPONSOR_FAILURE,
    DELETE_SPONSOR_REQUEST,
    DELETE_SPONSOR_SUCCESS,
    DELETE_SPONSOR_FAILURE,
    CLEAR_SPONSOR,
} from '../actions';

export const SPONSOR_INITIAL_STATE = {
    id: null,
    marina_id: null,
    sponsor_name: '',
    address_1: '',
    address_2: '',
    address_3: '',
    postcode: '',
    loc_lat: '',
    loc_long: '',
    contact_name: '',
    contact_title: '',
    contact_first_name: '',
    contact_surname: '',
    phpne: '',
    email: '',
    web: '',
    open_time_weekdays: '',
    open_time_saturday: '',
    open_time_sunday: '',
    icon_alignment: '',
    icon_location_sponsor: '',
    message: '',
    isFetching: false,
    error: null,
};

const sponsorReducers = {
    [STORE_SPONSOR_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null, draft: true,
    }),
    [STORE_SPONSOR_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [STORE_SPONSOR_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [SPONSOR_REQUEST]: (state, { id }) => ({
        ...state, id, isFetching: true, error: null,
    }),
    [SPONSOR_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [SPONSOR_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [UPDATE_SPONSOR_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [UPDATE_SPONSOR_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [UPDATE_SPONSOR_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [DELETE_SPONSOR_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [DELETE_SPONSOR_SUCCESS]: (state) => ({ ...SPONSOR_INITIAL_STATE }),
    [DELETE_SPONSOR_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [CLEAR_SPONSOR]: () => ({ ...SPONSOR_INITIAL_STATE }),
};

const sponsorReducer = (state = SPONSOR_INITIAL_STATE, action) => {
    const reducer = sponsorReducers[action.type] || ((state) => state);
    return reducer(state, action);
};

export default sponsorReducer;
