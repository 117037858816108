/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import {
    DollarTwoTone, FlagTwoTone, EditTwoTone, DeleteTwoTone, GroupOutlined, UserOutlined,
} from '@ant-design/icons';
import {
    Avatar, Tag,
} from 'antd';
import PaginatedList from '../shared/PaginatedList';
import { fetchMarinas, deleteMarina } from '../../actions';
import useConfirm from '../shared/hooks/useConfirm';

function MarinaList(props) {
    const {
        fetchMarinas, deleteMarina, marinas, match, location: { search = {} }, marina,
    } = props;
    const confirmMarinaDelete = useConfirm(
        marina,
        () => {
            const [full, page] = search.match(/page=(\d+)/) || ['', 1];
            fetchMarinas(+page);
        },
    );

    const onMarinaDelete = ({ id, marina_name }) => (e) => {
        e.preventDefault();
        confirmMarinaDelete(
            `Do you want to delete ${marina_name}?`,
            () => deleteMarina(id),
        );
    };

    const columns = [{
        title: '#',
        key: 'id',
        dataIndex: 'id',
        width: '2%',
    }, {
        title: 'Name',
        dataIndex: 'marina_name',
        key: 'marina_name',
        render: (text, record) => (
            <span>
                <Avatar src={record.icon_location_app} size="small" />
                {' '}
                <Link to={{
                    pathname: `${match.url}/${record.id}`,
                    state: {
                        from: search || 'page=1',
                    },
                }}
                >
                    {text}

                </Link>
            </span>
        ),
    }, {
        title: 'Tide Station',
        key: 'station',
        render: (text, record) => {
            if (record.station) {
                return (
                    <Link to={{
                        pathname: `/stations/${record.station_id}/tides`,
                        state: {
                            from: search || 'page=1',
                        },
                    }}
                    >
                        {record.station.name}
                    </Link>
                );
            }
            return 'N/A';
        },
    }, {
        title: 'Lock Closures',
        key: 'locks',
        render: (text, record) => (record.station ? (
            <Link to={{
                pathname: `${match.url}/${record.id}/lock-closures`,
                state: {
                    from: search || 'page=1',
                },
            }}
            >
                Locks
            </Link>
        ) : 'N/A'),
    }, {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    }, {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
    }, {
        title: 'Registered at',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (createdAt, record) => (
            <span>
                {dayjs(createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </span>
        ),
    }, {
        title: 'Status',
        key: 'status',
        render: (text, record) => {
            const { approved, cancelled } = record;
            return (
                <span>
                    {
                        approved ? <Tag color="green">Approved</Tag>
                            : (cancelled ? <Tag color="red">Cancelled</Tag> : <Tag color="orange">Pending</Tag>)
                    }
                </span>
            );
        },
    }, {
        title: (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <span>Adverts</span>
                <span>Sponsors</span>
                <span>Rates</span>
                <span>Users</span>
            </div>
        ),
        key: 'adverts',
        render: (text, record) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Link to={{
                    pathname: `${match.url}/${record.id}/adverts`,
                    state: {
                        from: search || 'page=1',
                    },
                }}
                >
                    <FlagTwoTone />
                </Link>
                {' '}
                <Link to={{
                    pathname: `${match.url}/${record.id}/sponsors`,
                    state: {
                        from: search || 'page=1',
                    },
                }}
                >
                    <DollarTwoTone />
                </Link>
                {' '}
                <Link to={{
                    pathname: `${match.url}/${record.id}/rates`,
                    state: {
                        from: search || 'page=1',
                    },
                }}
                >
                    <GroupOutlined />
                </Link>
                {' '}
                <Link to={{
                    pathname: `${match.url}/${record.id}/users`,
                    state: {
                        from: search || 'page=1',
                    },
                }}
                >
                    <UserOutlined />
                </Link>
            </div>
        ),
    }, {
        title: (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <span>Actions</span>
            </div>
        ),
        key: 'actions',
        render: (text, record) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Link to={{
                    pathname: `${match.url}/${record.id}/edit`,
                    state: {
                        from: search || 'page=1',
                    },
                }}
                >
                    <EditTwoTone />
                </Link>
                {' '}
                <a href="#" onClick={onMarinaDelete({ ...record })}><DeleteTwoTone twoToneColor="#eb2f96" /></a>

            </div>
        ),
    }];

    return (
        <PaginatedList
            fetchData={fetchMarinas}
            columns={columns}
            source={marinas}
            {...props}
        />
    );
}

function mapStateToProps(state) {
    return {
        loading: state.marinas.isFetching,
        marinas: state.marinas.data,
        meta: state.marinas.meta,
        marina: state.marina.profile,
    };
}
export default connect(mapStateToProps, { fetchMarinas, deleteMarina })(MarinaList);
