import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import {
    storeBusiness, updateBusiness, fetchBusiness, resetBusiness,
} from '../../actions';
import Advert from './Advert';

function AdvertContainer(props) {
    const {
        setExtras,
        setTitle,
        isNew,
        business,
        fetchBusiness,
        storeBusiness,
        updateBusiness,
        resetBusiness,
        history,
        match: { params: { business: businessId, marina: marinaId } },
    } = props;
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setExtras([null]);
    }, [setExtras]);
    useEffect(() => {
        if (isNew) {
            setTitle('Create New Business');
        } else {
            setTitle('Edit Business');
        }
    }, [setTitle]);

    useEffect(() => {
        if (isNew && business.id && !submitted) {
            resetBusiness();
            return;
        }

        if (isNew || (business.id && business.id === +businessId) || business.isFetching) return;

        fetchBusiness(businessId);
    }, [business.id]);

    useEffect(() => {
        if (!submitted || business.isFetching) return;
        if (!business.error) {
            if (isNew) {
                message.success('Successfully created');
                setTimeout(() => history.goBack(), 100);
                return;
            }
            if (!isNew) {
                setSubmitted(false);
                message.success('Successfully updated');
                setTimeout(() => history.goBack(), 100);
            }
        }
    }, [business.isFetching]);

    const onFinish = (payload) => {
        setSubmitted(true);

        const fn = isNew ? storeBusiness : updateBusiness;
        const args = isNew ? [{ ...payload, marina_id: marinaId }] : [businessId, { ...payload }];
        return fn(...args);
    };

    const prosToPass = {
        isNew,
        marinaId,
        business,
        onSubmit: onFinish,
    };

    // fetching in progress
    if (!isNew && !business.id) {
        return null;
    }

    // navigating to new after editing another one
    // reset before render
    if (isNew && business.id && !submitted) {
        return null;
    }

    return <Advert {...prosToPass} />;
}

const mapStateToProps = (state, ownProps) => {
    const { marina: { advert } } = state;

    return {
        ...ownProps,
        business: advert,
    };
};

export default connect(mapStateToProps, {
    storeBusiness, fetchBusiness, updateBusiness, resetBusiness,
})(AdvertContainer);
