import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE } from '../actions';

export const SIGNUP_INITIAL_STATE = {
    isFetching: false,
    error: {},
};

const signupReducers = {
    [SIGNUP_REQUEST]: (state) => ({ ...state, isFetching: true }),
    [SIGNUP_SUCCESS]: (state, { res }) => ({
        ...state,
        ...res,
        isFetching: false,
    }),
    [SIGNUP_FAILURE]: (state, { error: { res } }) => ({ ...state, isFetching: false, error: res }),
};

export default function signupUser(state = SIGNUP_INITIAL_STATE, action) {
    const reducer = signupReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
