/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button, Form, Row, Col, Input, Divider, message, Select, Modal,
} from 'antd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { fetchBusinessTypes, storeBusinessType } from '../../actions';

const setFormErrors = (form, error, values) => {
    const { res: { errors: serverErrors, message: msg } } = error;
    message.error(msg);

    if (!serverErrors) return;

    const fields = [];
    Object.keys(serverErrors).forEach((name) => {
        const fieldErrors = serverErrors[name];
        fields.push({
            name,
            value: values[name],
            errors: fieldErrors,
        });
    });
    form.setFields(fields);
};

function NewTypeForm({
    visible, onCancel, loading, onCreate, error,
}) {
    const [form] = Form.useForm();
    const [values, setValues] = useState({});

    useEffect(() => {
        if (!error) return;
        setFormErrors(form, error, values);
    }, [error]);

    const onOk = () => {
        form.validateFields().then((values) => {
            form.resetFields();
            setValues(values);
            onCreate(values);
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    };

    return (
        <Modal
            title="Add new business type"
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            confirmLoading={loading}
        >
            <Form form={form} layout="horizontal" name="typeForm">
                <Form.Item
                    hasFeedback
                    name="name"
                    label="Business type Name"
                    rules={[{ required: true, message: 'Type name is required', whitespace: true }]}
                >
                    <Input placeholder="Business type name" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

function Advert(props) {
    const {
        isNew,
        business,
        onSubmit,
        businessTypes,
        businessType,
        fetchBusinessTypes,
        storeBusinessType,
    } = props;
    const [values, setValues] = useState({});
    const [form] = Form.useForm();

    useEffect(() => {
        if (!business.error) return;
        setFormErrors(form, business.error, values);
    }, [business.error]);

    useEffect(() => {
        fetchBusinessTypes();
    }, []);

    const getInitialValues = () => {
        if (isNew) return {};
        const businessCopy = { ...business };
        businessCopy.business_loc_lat = `${businessCopy.business_loc_lat}`;
        businessCopy.business_loc_long = `${businessCopy.business_loc_long}`;

        return businessCopy;
    };

    const makeProperCase = (text) => text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    const onFinish = (formValues) => {
        setValues(formValues);
        const formVals = { ...formValues };
        formVals.contact_name = `${formVals.contact_title}. ${formVals.contact_first_name} ${formVals.contact_surname}`;

        onSubmit(formVals);
    };

    const [visible, setVisible] = useState(false);
    const [addTypeSubmitted, setAddTypeSubmitted] = useState(false);
    const openAddModal = () => setVisible(true);
    const onCancel = () => setVisible(false);

    const onAddNewType = (values) => {
        setAddTypeSubmitted(true);
        storeBusinessType(values);
    };

    useEffect(() => {
        if (!addTypeSubmitted || businessType.isFetching) return;
        if (!businessType.error) {
            setAddTypeSubmitted(false);
            fetchBusinessTypes();
            setVisible(false);
        }
    }, [businessType.isFetching]);

    useEffect(() => {
        if (!businessType.id || businessTypes.isFetching) return;
        form.setFieldsValue({ type_id: businessType.id });
    }, [businessType.id, businessTypes.isFetching]);

    const BusinessForm = (
        <Form
            name="businessForm"
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={getInitialValues()}
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item label="Business Name" name="business_name" rules={[{ required: true, message: 'Business Name is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                        <Input placeholder="Please enter business name" />
                    </Form.Item>
                    <Form.Item label="Business Type" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(76%)', marginRight: 8 }} name="type_id" rules={[{ required: true, message: 'Business type is required' }]} hasFeedback>
                            <Select
                                showSearch
                                placeholder="Business Type"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {businessTypes.data.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block' }}>
                            <Button onClick={openAddModal} type="primary">
                                <PlusOutlined />
                                {' '}
                                Add New
                            </Button>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Business Location" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 8 }} name="business_loc_lat" rules={[{ required: true, message: 'Location Lattitude is required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Lattitude" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 5px)' }} name="business_loc_long" rules={[{ required: true, message: 'Location Longitude is required', whitespace: true }]} hasFeedback>
                            <Input placeholder="Longitude" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Business Address" name="business_address" rules={[{ required: isNew, message: 'Business address is required', whitespace: true }]} hasFeedback>
                        <Input placeholder="Please provide business address" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Contact Name" style={{ marginBottom: 0 }}>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(18%)', marginRight: 8 }} name="contact_title" rules={[{ required: true, message: 'Reqired', whitespace: true }]} hasFeedback>
                            <Select
                                placeholder="Title"
                            >
                                <Select.Option value="Mr">Mr</Select.Option>
                                <Select.Option value="Mrs">Mrs</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(41% - 8px)', marginRight: 8 }} name="contact_first_name" rules={[{ required: true, message: 'Contact First Name is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: 'calc(41% - 8px)' }} name="contact_surname" rules={[{ required: true, message: 'Contact Surname is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                            <Input placeholder="Surname" />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Contact Address" name="contact_address" rules={[{ required: isNew, message: 'Contact address is required', whitespace: true }]} hasFeedback>
                        <Input placeholder="Please provide contact address" />
                    </Form.Item>
                    <Form.Item
                        label="Contact Phone"
                        name="contact_phone"
                        rules={[{
                            required: true, message: 'Phone number is required', whitespace: true,
                        }, { pattern: /\d{11}$/, message: 'Invalid phone number' }]}
                        hasFeedback
                    >
                        <Input placeholder="Please provide contact phone no" />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="contact_email"
                        rules={[{
                            required: isNew, message: 'Email is required', type: 'email', whitespace: true,
                        }]}
                        hasFeedback
                    >
                        <Input placeholder="Please provide contact email" />
                    </Form.Item>
                    <Form.Item label="Web Address" name="contact_web" hasFeedback>
                        <Input placeholder="Please provide contact web address" />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <div>
                <Button type="primary" htmlType="submit" loading={business.isFetching} style={{ float: 'right' }}>
                    {business.isFetching ? null : <SaveOutlined />}
                    {' '}
                    {isNew ? 'Create' : 'Update'}
                </Button>
            </div>
        </Form>
    );

    return (
        <>
            {BusinessForm}
            <NewTypeForm
                visible={visible}
                onCancel={onCancel}
                onCreate={onAddNewType}
                error={businessType.error}
                loading={businessType.isFetching}
            />
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { businessTypes, businessType } = state;
    return {
        ...ownProps,
        businessTypes,
        businessType,
    };
};

export default connect(mapStateToProps, { fetchBusinessTypes, storeBusinessType })(Advert);
