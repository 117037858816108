import {
    createStore,
    applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';

import api from './middleware/api';
import appReducer from './reducers';

const rootReducer = (state, action) => appReducer(state, action);

export default function configureStore(preloadedState) {
    return createStore(rootReducer, preloadedState, applyMiddleware(thunk, api));
}
