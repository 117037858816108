import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    EnvironmentTwoTone, SyncOutlined,
} from '@ant-design/icons';
import {
    message, PageHeader, Button, Input,
} from 'antd';
import PaginatedList from '../shared/PaginatedList';
import { fetchStations, syncStations, deleteStation } from '../../actions';

const { Search } = Input;

function MarinaList(props) {
    const [syncStarted, setSyncStarted] = useState(false);
    const [query, setQuery] = useState('');
    const {
        fetchStations,
        syncStations,
        stations,
        history,
        match,
        location: { search = {} },
    } = props;

    useEffect(() => {
        if (!syncStarted || (syncStarted && stations.isFetching)) return;
        if (syncStarted && !stations.isFetching && !stations.error) {
            setSyncStarted(false);
        }
        if (syncStarted && stations.error) {
            setSyncStarted(false);
            message.error("Error in sync'ing from Admiralty");
        }
    }, [syncStations, syncStarted, stations.isFetching, stations.error]);

    const columns = [{
        title: '#',
        dataIndex: 'station_id',
        width: '3%',
    }, {
        title: 'Name',
        dataIndex: 'name',
        render: (text, record) => (
            <Link to={{
                pathname: `${match.url}/${record.id}/tides`,
                state: {
                    from: search || 'page=1',
                },
            }}
            >
                {text}

            </Link>
        ),
    }, {
        title: 'Marina',
        key: 'marina',
        render: (text, record) => {
            if (record.marina) {
                return (
                    <Link to={{
                        pathname: `/marina/${record.marina.id}`,
                    }}
                    >
                        {record.marina.marina_name}
                    </Link>
                );
            }
            return 'N/A';
        },
    }, {
        title: 'Country',
        dataIndex: 'country',
    }, {
        title: 'Continuous Heights',
        dataIndex: 'continuous_height',
        render: (text) => (text ? 'True' : 'False'),
    }, {
        title: 'Latitude',
        dataIndex: 'latitude',
    }, {
        title: 'Longitude',
        dataIndex: 'longitude',
    }, {
        title: (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <span>Actions</span>
            </div>
        ),
        key: 'actions',
        render: (text, { latitude, longitude }) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <a href={`https://maps.google.com/?q=${latitude},${longitude}`} target="_blank"><EnvironmentTwoTone /></a>
            </div>
        ),
    }];

    const sync = (e) => {
        e.preventDefault();
        setSyncStarted(true);
        syncStations();
    };

    const onSearch = (e) => {
        e.preventDefault();
        setQuery(e.target.value);
    };
    const defaultExtra = [
        <Search
            key="2"
            placeholder="Search"
            onChange={onSearch}
            style={{ width: 200 }}
        />,
        <Button key="1" type="primary" onClick={sync}>
            Sync from Admiralty
            {' '}
            <SyncOutlined spin={syncStarted} />
        </Button>,
    ];
    return (
        <PageHeader
            title="Stations"
            className="stations-page-header"
            onBack={() => history.goBack()}
            subTitle=""
            extra={defaultExtra}
        >
            <PaginatedList
                fetchData={fetchStations}
                columns={columns}
                source={stations.data}
                query={query}
                {...props}
            />
        </PageHeader>

    );
}

function mapStateToProps(state) {
    return {
        loading: state.stations.isFetching,
        stations: state.stations,
        station: state.station,
        meta: state.stations.meta,
    };
}
export default connect(mapStateToProps, { fetchStations, syncStations, deleteStation })(MarinaList);
