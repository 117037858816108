import React, { useState, useEffect } from 'react';
import { PageHeader, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link, Switch, Route } from 'react-router-dom';

import MarinaList from './MarinaList';
import MarinaProfile from './MarinaProfile';
import Marina from './MarinaContainer';
import Adverts from './AdvertList';
import Sponsors from './SponsorList';
import Advert from './AdvertContainer';
import Sponsor from './SponsorContainer';
import Locks from './LocksContainer';
import RatesContainer from './RatesContainer';
import Users from '../shared/Users';
import User from '../shared/User';

export default function Marinas({ match, location, history }) {
    const [title, setTitle] = useState('Marinas');
    const defaultExtra = [
        <Button key="1" type="primary">
            <Link to={`${match.url}/new`}>
                <PlusOutlined />
                {' '}
                Add New
            </Link>
        </Button>,
    ];
    const [extras, setExtras] = useState(defaultExtra);

    useEffect(() => {
        if (location.pathname === '/marina') {
            setTitle('Marinas');
            setExtras(defaultExtra);
        }
    }, [location.pathname]);

    return (
        <div>
            <Switch>
                <Route key="rates" path={`${match.url}/:marina/rates`} component={RatesContainer} />
                <Route key="user" path={`${match.url}/:marina/users/:user`} component={User} />
                <Route key="users" path={`${match.url}/:marina/users`} component={Users} />
                <PageHeader
                    title={title}
                    className="site-page-header"
                    onBack={() => history.goBack()}
                    subTitle=""
                    extra={extras}
                >
                    <Switch>
                        <Route key="1" path={`${match.url}/`} exact render={(props) => <MarinaList {...props} setTitle={setTitle} />} />
                        <Route key="2" path={`${match.url}/new`} render={(props) => <Marina {...props} setTitle={setTitle} setExtras={setExtras} isNew />} />
                        <Route key="11" path={`${match.url}/:marina/lock-closures`} render={(props) => <Locks {...props} setTitle={setTitle} setExtras={setExtras} />} />
                        <Route key="3" path={`${match.url}/:marina/edit`} render={(props) => <Marina {...props} setTitle={setTitle} setExtras={setExtras} />} />
                        <Route key="4" path={`${match.url}/:marina/sponsors/new`} exact render={(props) => <Sponsor {...props} setTitle={setTitle} setExtras={setExtras} isNew />} />
                        <Route key="5" path={`${match.url}/:marina/sponsors/:sponsor`} render={(props) => <Sponsor {...props} setTitle={setTitle} setExtras={setExtras} />} />
                        <Route key="6" path={`${match.url}/:marina/sponsors`} render={(props) => <Sponsors {...props} setTitle={setTitle} setExtras={setExtras} />} />
                        <Route key="7" path={`${match.url}/:marina/adverts/new`} exact render={(props) => <Advert {...props} setTitle={setTitle} setExtras={setExtras} isNew />} />
                        <Route key="8" path={`${match.url}/:marina/adverts/:business`} render={(props) => <Advert {...props} setTitle={setTitle} setExtras={setExtras} />} />
                        <Route key="9" path={`${match.url}/:marina/adverts`} render={(props) => <Adverts {...props} setTitle={setTitle} setExtras={setExtras} />} />
                        <Route key="10" path={`${match.url}/:marina`} render={(props) => <MarinaProfile {...props} setTitle={setTitle} setExtras={setExtras} />} />
                    </Switch>
                </PageHeader>
            </Switch>
        </div>
    );
}
