import {
    NOTIFICATIONS_REQUEST,
    NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_FAILURE,
} from '../actions';

export const NOTIFICATIONS_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

const businessesReducers = {
    [NOTIFICATIONS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [NOTIFICATIONS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [NOTIFICATIONS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function businesses(state = NOTIFICATIONS_INITIAL_STATE, action) {
    const reducer = businessesReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
