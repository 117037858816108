import {
    SPONSORS_REQUEST,
    SPONSORS_SUCCESS,
    SPONSORS_FAILURE,
} from '../actions';

export const SPONSORS_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

const sponsorsReducers = {
    [SPONSORS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [SPONSORS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [SPONSORS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function sponsors(state = SPONSORS_INITIAL_STATE, action) {
    const reducer = sponsorsReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
