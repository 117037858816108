import React, { useState } from 'react';
import { Menu, Layout } from 'antd';
import { Link } from 'react-router-dom';
import {
    RiseOutlined,
    AccountBookOutlined,
    BarsOutlined,
    ProfileOutlined,
    TeamOutlined,
    NotificationOutlined,
    ClockCircleOutlined,
    FileDoneOutlined,
    ScheduleOutlined,
    CalendarOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import { permissionChecker } from '../../utils/permission';

const { Sider } = Layout;

export default function Sidebar(props) {
    const [collapsed, setCollapsed] = useState(false);

    const {
        auth: { isAuthenticated },
        location: { pathname },
        me: { role, permissions },
    } = props;

    if (!isAuthenticated) {
        return <div />;
    }

    const menuItems = [{
        key: 'marina.profile', label: 'Profile', icon: ProfileOutlined, to: '/',
    }, {
        key: 'rates', label: 'Rates', icon: AccountBookOutlined, to: '/rates',
    }, {
        key: 'locks', label: 'Lock Closures', icon: BarsOutlined, to: '/locks',
    }, {
        key: 'tides', label: 'Tide Times', icon: RiseOutlined, to: '/tides',
    }, {
        key: 'notifications', label: 'Notifications', icon: NotificationOutlined, to: '/notifications',
    }, {
        key: 'users', label: 'Users', icon: TeamOutlined, to: '/users',
    }, {
        key: 'swings', label: 'Swings', icon: ClockCircleOutlined, to: '/swings',
    }, {
        key: 'book_visits', label: 'Booked Visits', icon: FileDoneOutlined, to: '/book_visits',
    }, {
        key: 'house_diary', label: 'Watch House Diary', icon: ScheduleOutlined, to: '/house_diary',
    }, {
        key: 'berth_holders', label: 'Berth Holders', icon: UsergroupAddOutlined, to: '/berth_holders',
    }];

    let availableMenuItems = menuItems;

    const hasPermission = permissionChecker(role, permissions);
    availableMenuItems = menuItems.filter((m) => (
        hasPermission(`view.${m.key}`)
        || hasPermission(`add.${m.key}`)
        || hasPermission(`edit.${m.key}`)
        || hasPermission(`delete.${m.key}`)
        || hasPermission(`calculate.${m.key}`)
    ));

    let selectedKeys = ['marina.profile'];
    if (/^\/rates.*/.test(pathname)) {
        selectedKeys = ['rates'];
    } else if (/^\/locks.*/.test(pathname)) {
        selectedKeys = ['locks'];
    } else if (/^\/tides.*/.test(pathname)) {
        selectedKeys = ['tides'];
    } else if (/^\/users.*/.test(pathname)) {
        selectedKeys = ['users'];
    } else if (/^\/notifications.*/.test(pathname)) {
        selectedKeys = ['notifications'];
    }

    return (
        <Sider
            id="sider"
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            breakpoint="sm"
            theme="light"
        >
            <Menu
                selectedKeys={selectedKeys}
                style={{ height: '100%', paddingTop: '24px' }}
                mode="inline"
            >
                {availableMenuItems.map((i) => (
                    <Menu.Item key={i.key} style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                        <Link to={i.to} style={{ display: 'inline-block', width: '100%' }}>
                            <i.icon style={{ marginRight: 14 }} />
                            {' '}
                            {i.label}
                        </Link>
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    );
}
