import { combineReducers } from 'redux';

import {
    RATE_FIELD_CATEGORIES_REQUEST,
    RATE_FIELD_CATEGORIES_SUCCESS,
    RATE_FIELD_CATEGORIES_FAILURE,
    STORE_RATE_FIELD_CAT_REQUEST,
    STORE_RATE_FIELD_CAT_SUCCESS,
    STORE_RATE_FIELD_CAT_FAILURE,
    DELETE_RATE_FIELD_CAT_REQUEST,
    DELETE_RATE_FIELD_CAT_SUCCESS,
    DELETE_RATE_FIELD_CAT_FAILURE,
    RATE_FIELDS_REQUEST,
    RATE_FIELDS_SUCCESS,
    RATE_FIELDS_FAILURE,
    STORE_RATE_FIELD_REQUEST,
    STORE_RATE_FIELD_SUCCESS,
    STORE_RATE_FIELD_FAILURE,
    UPDATE_RATE_FIELD_REQUEST,
    UPDATE_RATE_FIELD_SUCCESS,
    UPDATE_RATE_FIELD_FAILURE,
    DELETE_RATE_FIELD_REQUEST,
    DELETE_RATE_FIELD_SUCCESS,
    DELETE_RATE_FIELD_FAILURE,
    CALCULATE_RATE_REQUEST,
    CALCULATE_RATE_SUCCESS,
    CALCULATE_RATE_FAILURE,
} from '../actions';

export const CATEGORIES_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

export const FIELDS_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

export const CATEGORY_INITIAL_STATE = {
    isFetching: false,
    error: null,
};

export const FIELD_INITIAL_STATE = {
    isFetching: false,
    error: null,
};

export const CALCULATE_INITIAL_STATE = {
    isFetching: false,
    error: null,
    result: 0,
    type: null,
    message: '',
    values: [],
};

const categoriesReducer = {
    [RATE_FIELD_CATEGORIES_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [RATE_FIELD_CATEGORIES_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [RATE_FIELD_CATEGORIES_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export function categories(state = CATEGORIES_INITIAL_STATE, action) {
    const reducer = categoriesReducer[action.type] || ((state) => state);
    return reducer(state, action);
}

const categoryReducers = {
    [STORE_RATE_FIELD_CAT_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null, draft: true,
    }),
    [STORE_RATE_FIELD_CAT_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [STORE_RATE_FIELD_CAT_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [DELETE_RATE_FIELD_CAT_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [DELETE_RATE_FIELD_CAT_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [DELETE_RATE_FIELD_CAT_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export function category(state = CATEGORY_INITIAL_STATE, action) {
    const reducer = categoryReducers[action.type] || ((state) => state);
    return reducer(state, action);
}

const fieldsReducer = {
    [RATE_FIELDS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [RATE_FIELDS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [RATE_FIELDS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export function fields(state = FIELDS_INITIAL_STATE, action) {
    const reducer = fieldsReducer[action.type] || ((state) => state);
    return reducer(state, action);
}

const fieldReducers = {
    [STORE_RATE_FIELD_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null, draft: true,
    }),
    [STORE_RATE_FIELD_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [STORE_RATE_FIELD_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [UPDATE_RATE_FIELD_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [UPDATE_RATE_FIELD_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [UPDATE_RATE_FIELD_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [DELETE_RATE_FIELD_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [DELETE_RATE_FIELD_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [DELETE_RATE_FIELD_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export function field(state = FIELD_INITIAL_STATE, action) {
    const reducer = fieldReducers[action.type] || ((state) => state);
    return reducer(state, action);
}

const calculationReducer = {
    [CALCULATE_RATE_REQUEST]: (state) => ({
        ...state, isFetching: true, error: null, type: null, message: '',
    }),
    [CALCULATE_RATE_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [CALCULATE_RATE_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export function calculate(state = CALCULATE_INITIAL_STATE, action) {
    const reducer = calculationReducer[action.type] || ((state) => state);
    return reducer(state, action);
}

export default combineReducers({
    categories,
    category,
    fields,
    field,
    calculate,
});
