import {
    CALL_API,
    API_ROOT,
    METHOD_PUT,
    METHOD_POST,
    METHOD_GET,
    METHOD_PATCH,
    METHOD_DELETE,
    TYPE_FORM,
} from './middleware/api';
import { saveData, removeData, SESSION_KEY } from './storage/local-storage';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

function requestLogin(creds) {
    return {
        type: LOGIN_REQUEST,
        creds,
    };
}

function receiveLogin() {
    return {
        type: LOGIN_SUCCESS,
        message: 'Successfully logged',
    };
}

function loginError(error) {
    return {
        type: LOGIN_FAILURE,
        error,
    };
}

export const ME_REQUEST = 'ME_REQUEST';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAILURE = 'ME_FAILURE';

export function fetchMe() {
    const endpoint = '/user/me';

    return {
        [CALL_API]: {
            types: [ME_REQUEST, ME_SUCCESS, ME_FAILURE],
            endpoint,
        },
    };
}

// TODO: re-factor
// possible candidate to generalize with api middleware
export function loginUser(creds) {
    const config = {
        method: METHOD_POST,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            accept: 'application/json',
        },
        body: JSON.stringify(creds),
        credentials: 'include',
        redirect: 'manual',
    };

    return (dispatch) => {
        dispatch(requestLogin(creds));
        fetch('/auth/login', config).then(
            (res) => res.text().then((text) => (text ? { res, json: JSON.parse(text) } : { res, json: {} })),
        ).then(({ res, json }) => {
            if (!res.ok) {
                const { status } = res;
                return Promise.reject({ status, ...({ res: json }) });
            }
            saveData(SESSION_KEY, { authenticated: true });
            dispatch(receiveLogin());
            dispatch(fetchMe());
        }).catch((error) => dispatch(loginError(error.res)));
    };
}

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
    };
}

function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
        message: 'Successfully logged out',
    };
}

export const RESET_ME = 'RESET_ME';
function resetMe() {
    return {
        type: RESET_ME,
    };
}

export function logoutUser() {
    const config = {
        method: METHOD_POST,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            accept: 'application/json',
        },
        credentials: 'include',
        redirect: 'manual',
    };

    return (dispatch) => {
        dispatch(requestLogout());
        return fetch('/auth/logout', config).then(
            (res) => res.text().then((text) => (text ? { res, json: JSON.parse(text) } : { res, json: {} })),
        ).then(({ res, json }) => {
            if (!res.ok) {
                const { status } = res;
                return Promise.reject({ status, ...({ res: json }) });
            }
            dispatch(receiveLogout());
            removeData(SESSION_KEY);
            dispatch(resetMe());
        }).catch(() => dispatch(receiveLogout()));
    };
}

export const RESET_REQUEST_REQUEST = 'RESET_REQUEST_REQUEST';
export const RESET_REQUEST_SUCCESS = 'RESET_REQUEST_SUCCESS';
export const RESET_REQUEST_FAILURE = 'RESET_REQUEST_FAILURE';

export function requestResetUser(creds) {
    const endpoint = '/auth/password/email';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [RESET_REQUEST_REQUEST, RESET_REQUEST_SUCCESS, RESET_REQUEST_FAILURE],
            endpoint,
            requireAuth: false,
        },
        ...creds,
    };
}

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export function resetPassword(creds) {
    const endpoint = '/auth/password/reset';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
            endpoint,
            requireAuth: false,
        },
        ...creds,
    };
}

export const MARINAS_REQUEST = 'MARINAS_REQUEST';
export const MARINAS_SUCCESS = 'MARINAS_SUCCESS';
export const MARINAS_FAILURE = 'MARINAS_FAILURE';

export function fetchMarinas(page = 1) {
    const endpoint = `/marinas?page=${page}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [MARINAS_REQUEST, MARINAS_SUCCESS, MARINAS_FAILURE],
            endpoint,
        },
    };
}

export const MARINA_REQUEST = 'MARINA_REQUEST';
export const MARINA_SUCCESS = 'MARINA_SUCCESS';
export const MARINA_FAILURE = 'MARINA_FAILURE';

export function fetchMarina(id) {
    const endpoint = `/marinas/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [MARINA_REQUEST, MARINA_SUCCESS, MARINA_FAILURE],
            endpoint,
        },
    };
}

export const FETCH_LOCATION_REQUEST = 'FETCH_LOCATION_REQUEST';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAILURE = 'FETCH_LOCATION_FAILURE';

export function fetchLocations(text) {
    let endpoint = '/locations';
    if (text) {
        endpoint += `?query=${encodeURIComponent(text)}`;
    }

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [FETCH_LOCATION_REQUEST, FETCH_LOCATION_SUCCESS, FETCH_LOCATION_FAILURE],
            endpoint,
        },
    };
}

export const SYNC_LOCATION_REQUEST = 'SYNC_LOCATION_REQUEST';
export const SYNC_LOCATION_SUCCESS = 'SYNC_LOCATION_SUCCESS';
export const SYNC_LOCATION_FAILURE = 'SYNC_LOCATION_FAILURE';

export function syncLocations() {
    const endpoint = '/sync-locations';

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [SYNC_LOCATION_REQUEST, SYNC_LOCATION_SUCCESS, SYNC_LOCATION_FAILURE],
            endpoint,
        },
    };
}

export const CLEAR_LOCATION = 'CLEAR_LOCATION';
export function clearLocations() {
    return {
        type: CLEAR_LOCATION,
    };
}

export const CLEAR_MARINA = 'CLEAR_MARINA';
export function resetMarina() {
    return {
        type: CLEAR_MARINA,
    };
}

export const STORE_MARINA_REQUEST = 'STORE_MARINA_REQUEST';
export const STORE_MARINA_SUCCESS = 'STORE_MARINA_SUCCESS';
export const STORE_MARINA_FAILURE = 'STORE_MARINA_FAILURE';

export function storeMarina(data) {
    const endpoint = '/marinas';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [STORE_MARINA_REQUEST, STORE_MARINA_SUCCESS, STORE_MARINA_FAILURE],
            endpoint,
        },
        ...data,
    };
}

export const UPDATE_MARINA_REQUEST = 'UPDATE_MARINA_REQUEST';
export const UPDATE_MARINA_SUCCESS = 'UPDATE_MARINA_SUCCESS';
export const UPDATE_MARINA_FAILURE = 'UPDATE_MARINA_FAILURE';

export function updateMarina(id, object) {
    const endpoint = `/marinas/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_PUT,
            types: [UPDATE_MARINA_REQUEST, UPDATE_MARINA_SUCCESS, UPDATE_MARINA_FAILURE],
            endpoint,
        },
        id,
        ...object,
    };
}

export const APPROVE_MARINA_REQUEST = 'APPROVE_MARINA_REQUEST';
export const APPROVE_MARINA_SUCCESS = 'APPROVE_MARINA_SUCCESS';
export const APPROVE_MARINA_FAILURE = 'APPROVE_MARINA_FAILURE';

export function approveMarina(id, sendEmail = true) {
    const endpoint = `/marinas/${id}/approve?send_signup_email=${sendEmail}`;

    return {
        [CALL_API]: {
            method: METHOD_PATCH,
            types: [APPROVE_MARINA_REQUEST, APPROVE_MARINA_SUCCESS, APPROVE_MARINA_FAILURE],
            endpoint,
        },
        id,
    };
}

export const DELETE_MARINA_REQUEST = 'DELETE_MARINA_REQUEST';
export const DELETE_MARINA_SUCCESS = 'DELETE_MARINA_SUCCESS';
export const DELETE_MARINA_FAILURE = 'DELETE_MARINA_FAILURE';

export function deleteMarina(marina) {
    const endpoint = `/marinas/${marina}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [DELETE_MARINA_REQUEST, DELETE_MARINA_SUCCESS, DELETE_MARINA_FAILURE],
            endpoint,
        },
        marina,
    };
}

export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export function sessionExpired(message) {
    return {
        type: SESSION_EXPIRED,
        message,
    };
}

export const BUSINESSES_REQUEST = 'BUSINESSES_REQUEST';
export const BUSINESSES_SUCCESS = 'BUSINESSES_SUCCESS';
export const BUSINESSES_FAILURE = 'BUSINESSES_FAILURE';

export function fetchBusinesses(marina) {
    const endpoint = `/businesses?marina_id=${marina}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [BUSINESSES_REQUEST, BUSINESSES_SUCCESS, BUSINESSES_FAILURE],
            endpoint,
        },
    };
}

export const BUSINESS_REQUEST = 'BUSINESS_REQUEST';
export const BUSINESS_SUCCESS = 'BUSINESS_SUCCESS';
export const BUSINESS_FAILURE = 'BUSINESS_FAILURE';

export function fetchBusiness(id) {
    const endpoint = `/businesses/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [BUSINESS_REQUEST, BUSINESS_SUCCESS, BUSINESS_FAILURE],
            endpoint,
        },
    };
}

export const STORE_BUSINESS_REQUEST = 'STORE_BUSINESS_REQUEST';
export const STORE_BUSINESS_SUCCESS = 'STORE_BUSINESS_SUCCESS';
export const STORE_BUSINESS_FAILURE = 'STORE_BUSINESS_FAILURE';

export function storeBusiness(data) {
    const endpoint = '/businesses';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [STORE_BUSINESS_REQUEST, STORE_BUSINESS_SUCCESS, STORE_BUSINESS_FAILURE],
            endpoint,
        },
        ...data,
    };
}

export const UPDATE_BUSINESS_REQUEST = 'UPDATE_BUSINESS_REQUEST';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_FAILURE = 'UPDATE_BUSINESS_FAILURE';

export function updateBusiness(id, object) {
    const endpoint = `/businesses/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_PUT,
            types: [UPDATE_BUSINESS_REQUEST, UPDATE_BUSINESS_SUCCESS, UPDATE_BUSINESS_FAILURE],
            endpoint,
        },
        id,
        ...object,
    };
}

export const DELETE_BUSINESS_REQUEST = 'DELETE_BUSINESS_REQUEST';
export const DELETE_BUSINESS_SUCCESS = 'DELETE_BUSINESS_SUCCESS';
export const DELETE_BUSINESS_FAILURE = 'DELETE_BUSINESS_FAILURE';

export function deleteBusiness(id) {
    const endpoint = `/businesses/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [DELETE_BUSINESS_REQUEST, DELETE_BUSINESS_SUCCESS, DELETE_BUSINESS_FAILURE],
            endpoint,
        },
    };
}

export const CLEAR_BUSINESS = 'CLEAR_BUSINESS';
export function resetBusiness() {
    return {
        type: CLEAR_BUSINESS,
    };
}

export const BUSINESS_TYPES_REQUEST = 'BUSINESS_TYPES_REQUEST';
export const BUSINESS_TYPES_SUCCESS = 'BUSINESS_TYPES_SUCCESS';
export const BUSINESS_TYPES_FAILURE = 'BUSINESS_TYPES_FAILURE';

export function fetchBusinessTypes() {
    const endpoint = '/businessTypes';

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [BUSINESS_TYPES_REQUEST, BUSINESS_TYPES_SUCCESS, BUSINESS_TYPES_FAILURE],
            endpoint,
        },
    };
}

export const BUSINESS_TYPE_REQUEST = 'BUSINESS_TYPE_REQUEST';
export const BUSINESS_TYPE_SUCCESS = 'BUSINESS_TYPE_SUCCESS';
export const BUSINESS_TYPE_FAILURE = 'BUSINESS_TYPE_FAILURE';

export function fetchBusinessType(id) {
    const endpoint = `/businessTypes/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [BUSINESS_TYPE_REQUEST, BUSINESS_TYPE_SUCCESS, BUSINESS_TYPE_FAILURE],
            endpoint,
        },
    };
}

export const STORE_BUSINESS_TYPE_REQUEST = 'STORE_BUSINESS_TYPE_REQUEST';
export const STORE_BUSINESS_TYPE_SUCCESS = 'STORE_BUSINESS_TYPE_SUCCESS';
export const STORE_BUSINESS_TYPE_FAILURE = 'STORE_BUSINESS_TYPE_FAILURE';

export function storeBusinessType(data) {
    const endpoint = '/businessTypes';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [STORE_BUSINESS_TYPE_REQUEST, STORE_BUSINESS_TYPE_SUCCESS, STORE_BUSINESS_TYPE_FAILURE],
            endpoint,
        },
        ...data,
    };
}

export const UPDATE_BUSINESS_TYPE_REQUEST = 'UPDATE_BUSINESS_TYPE_REQUEST';
export const UPDATE_BUSINESS_TYPE_SUCCESS = 'UPDATE_BUSINESS_TYPE_SUCCESS';
export const UPDATE_BUSINESS_TYPE_FAILURE = 'UPDATE_BUSINESS_TYPE_FAILURE';

export function updateBusinessType(id, object) {
    const endpoint = `/businessTypes/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_PUT,
            types: [UPDATE_BUSINESS_TYPE_REQUEST, UPDATE_BUSINESS_TYPE_SUCCESS, UPDATE_BUSINESS_TYPE_FAILURE],
            endpoint,
        },
        id,
        ...object,
    };
}

export const DELETE_BUSINESS_TYPE_REQUEST = 'DELETE_BUSINESS_TYPE_REQUEST';
export const DELETE_BUSINESS_TYPE_SUCCESS = 'DELETE_BUSINESS_TYPE_SUCCESS';
export const DELETE_BUSINESS_TYPE_FAILURE = 'DELETE_BUSINESS_TYPE_FAILURE';

export function deleteBusinessType(id) {
    const endpoint = `/businessTypes/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [DELETE_BUSINESS_TYPE_REQUEST, DELETE_BUSINESS_TYPE_SUCCESS, DELETE_BUSINESS_TYPE_FAILURE],
            endpoint,
        },
    };
}

export const SPONSORS_REQUEST = 'SPONSORS_REQUEST';
export const SPONSORS_SUCCESS = 'SPONSORS_SUCCESS';
export const SPONSORS_FAILURE = 'SPONSORS_FAILURE';

export function fetchSponsors(marina) {
    const endpoint = `/sponsors?marina_id=${marina}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [SPONSORS_REQUEST, SPONSORS_SUCCESS, SPONSORS_FAILURE],
            endpoint,
        },
    };
}

export const SPONSOR_REQUEST = 'SPONSOR_REQUEST';
export const SPONSOR_SUCCESS = 'SPONSOR_SUCCESS';
export const SPONSOR_FAILURE = 'SPONSOR_FAILURE';

export function fetchSponsor(id) {
    const endpoint = `/sponsors/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [SPONSOR_REQUEST, SPONSOR_SUCCESS, SPONSOR_FAILURE],
            endpoint,
        },
    };
}

export const STORE_SPONSOR_REQUEST = 'STORE_SPONSOR_REQUEST';
export const STORE_SPONSOR_SUCCESS = 'STORE_SPONSOR_SUCCESS';
export const STORE_SPONSOR_FAILURE = 'STORE_SPONSOR_FAILURE';

export function storeSponsor(data) {
    const endpoint = '/sponsors';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [STORE_SPONSOR_REQUEST, STORE_SPONSOR_SUCCESS, STORE_SPONSOR_FAILURE],
            endpoint,
        },
        ...data,
    };
}

export const UPDATE_SPONSOR_REQUEST = 'UPDATE_SPONSOR_REQUEST';
export const UPDATE_SPONSOR_SUCCESS = 'UPDATE_SPONSOR_SUCCESS';
export const UPDATE_SPONSOR_FAILURE = 'UPDATE_SPONSOR_FAILURE';

export function updateSponsor(id, object) {
    const endpoint = `/sponsors/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_PUT,
            types: [UPDATE_SPONSOR_REQUEST, UPDATE_SPONSOR_SUCCESS, UPDATE_SPONSOR_FAILURE],
            endpoint,
        },
        id,
        ...object,
    };
}

export const DELETE_SPONSOR_REQUEST = 'DELETE_SPONSOR_REQUEST';
export const DELETE_SPONSOR_SUCCESS = 'DELETE_SPONSOR_SUCCESS';
export const DELETE_SPONSOR_FAILURE = 'DELETE_SPONSOR_FAILURE';

export function deleteSponsor(id) {
    const endpoint = `/sponsors/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [DELETE_SPONSOR_REQUEST, DELETE_SPONSOR_SUCCESS, DELETE_SPONSOR_FAILURE],
            endpoint,
        },
    };
}

export const CLEAR_SPONSOR = 'CLEAR_SPONSOR';
export function resetSponsor() {
    return {
        type: CLEAR_SPONSOR,
    };
}

export const STATIONS_REQUEST = 'STATIONS_REQUEST';
export const STATIONS_SUCCESS = 'STATIONS_SUCCESS';
export const STATIONS_FAILURE = 'STATIONS_FAILURE';

export function fetchStations(page = 1, query) {
    let endpoint;
    if (page && query) {
        endpoint = `/stations?page=${page}&query=${query}`;
    } else if (page) {
        endpoint = `/stations?page=${page}`;
    } else if (query) {
        endpoint = `/stations?query=${query}`;
    }

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [STATIONS_REQUEST, STATIONS_SUCCESS, STATIONS_FAILURE],
            endpoint,
        },
    };
}

export const STATION_REQUEST = 'STATION_REQUEST';
export const STATION_SUCCESS = 'STATION_SUCCESS';
export const STATION_FAILURE = 'STATION_FAILURE';

export function fetchStation(id) {
    const endpoint = `/stations/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [STATION_REQUEST, STATION_SUCCESS, STATION_FAILURE],
            endpoint,
        },
    };
}

export const SYNC_STATIONS_REQUEST = 'SYNC_STATIONS_REQUEST';
export const SYNC_STATIONS_SUCCESS = 'SYNC_STATIONS_SUCCESS';
export const SYNC_STATIONS_FAILURE = 'SYNC_STATIONS_FAILURE';

export function syncStations() {
    const endpoint = '/sync-stations';

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [SYNC_STATIONS_REQUEST, SYNC_STATIONS_SUCCESS, SYNC_STATIONS_FAILURE],
            endpoint,
        },
    };
}

export const DELETE_STATION_REQUEST = 'DELETE_STATION_REQUEST';
export const DELETE_STATION_SUCCESS = 'DELETE_STATION_SUCCESS';
export const DELETE_STATION_FAILURE = 'DELETE_STATION_FAILURE';

export function deleteStation(station) {
    const endpoint = `/stations/${station}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [DELETE_STATION_REQUEST, DELETE_STATION_SUCCESS, DELETE_STATION_FAILURE],
            endpoint,
        },
        station,
    };
}

export const CLEAR_STATION = 'CLEAR_STATION';
export function clearStation() {
    return {
        type: CLEAR_STATION,
    };
}

export const TIDES_REQUEST = 'TIDES_REQUEST';
export const TIDES_SUCCESS = 'TIDES_SUCCESS';
export const TIDES_FAILURE = 'TIDES_FAILURE';

export function fetchTides(station, page = 1) {
    const endpoint = `/tides?page=${page}&station_id=${station}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [TIDES_REQUEST, TIDES_SUCCESS, TIDES_FAILURE],
            endpoint,
        },
    };
}

export const SYNC_TIDES_REQUEST = 'SYNC_TIDES_REQUEST';
export const SYNC_TIDES_SUCCESS = 'SYNC_TIDES_SUCCESS';
export const SYNC_TIDES_FAILURE = 'SYNC_TIDES_FAILURE';

export function syncTides(station, from, to) {
    const endpoint = `/sync-tides/${station}?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [SYNC_TIDES_REQUEST, SYNC_TIDES_SUCCESS, SYNC_TIDES_FAILURE],
            endpoint,
        },
    };
}

export const CLEAR_TIDES = 'CLEAR_TIDES';
export function clearTides() {
    return {
        type: CLEAR_TIDES,
    };
}

export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';

export function fetchTokenData(token, type = 'signup') {
    const endpoint = `/user/token-data?token=${token}&type=${type}`;

    return {
        [CALL_API]: {
            types: [TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE],
            endpoint,
            requireAuth: false,
        },
    };
}

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export function signupUser(creds) {
    const endpoint = '/user/signup';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE],
            endpoint,
            requireAuth: false,
        },
        ...creds,
    };
}

export const PROCESS_LOCK_REQUEST = 'PROCESS_LOCK_REQUEST';
export const PROCESS_LOCK_SUCCESS = 'PROCESS_LOCK_SUCCESS';
export const PROCESS_LOCK_FAILURE = 'PROCESS_LOCK_FAILURE';

export function processLock(marina, filePath) {
    const endpoint = `/marinas/${marina}/update-locks?file=${encodeURIComponent(filePath)}`;

    return {
        [CALL_API]: {
            method: METHOD_PATCH,
            types: [PROCESS_LOCK_REQUEST, PROCESS_LOCK_SUCCESS, PROCESS_LOCK_FAILURE],
            endpoint,
        },
    };
}

export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAILURE = 'USERS_FAILURE';

export function fetchUsers(marina) {
    let endpoint = '/users';

    if (marina) {
        endpoint += `?marina_id=${marina}`;
    }

    return {
        [CALL_API]: {
            types: [USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE],
            endpoint,
        },
    };
}

export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';

export function inviteUser(body) {
    const endpoint = '/user/invite';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [INVITE_USER_REQUEST, INVITE_USER_SUCCESS, INVITE_USER_FAILURE],
            endpoint,
        },
        ...body,
    };
}

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export function addUser(body) {
    const endpoint = '/users';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE],
            endpoint,
        },
        ...body,
    };
}

export const RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE';

export function resendEmail(user) {
    const endpoint = `/users/${user}/resend-email`;

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [RESEND_EMAIL_REQUEST, RESEND_EMAIL_SUCCESS, RESEND_EMAIL_FAILURE],
            endpoint,
        },
    };
}

export const PERMISSIONS_REQUEST = 'PERMISSIONS_REQUEST';
export const PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS';
export const PERMISSIONS_FAILURE = 'PERMISSIONS_FAILURE';

export function fetchPermissions() {
    const endpoint = '/user/permissions';

    return {
        [CALL_API]: {
            types: [PERMISSIONS_REQUEST, PERMISSIONS_SUCCESS, PERMISSIONS_FAILURE],
            endpoint,
        },
    };
}

export const LOCKS_REQUEST = 'LOCKS_REQUEST';
export const LOCKS_SUCCESS = 'LOCKS_SUCCESS';
export const LOCKS_FAILURE = 'LOCKS_FAILURE';

export function fetchLocks(page, query, marina = null) {
    let endpoint;
    if (page && query) {
        endpoint = `/lock-closures?page=${page}&query=${query}`;
    } else if (page) {
        endpoint = `/lock-closures?page=${page}`;
    } else if (query) {
        endpoint = `/lock-closures?query=${query}`;
    }
    if (marina) {
        endpoint += `&marina_id=${marina}`;
    }

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [LOCKS_REQUEST, LOCKS_SUCCESS, LOCKS_FAILURE],
            endpoint,
        },
    };
}

export const STORE_LOCK_REQUEST = 'STORE_LOCK_REQUEST';
export const STORE_LOCK_SUCCESS = 'STORE_LOCK_SUCCESS';
export const STORE_LOCK_FAILURE = 'STORE_LOCK_FAILURE';

export function storeLock(payload, marina = null) {
    let endpoint = '/lock-closures';
    if (marina) {
        endpoint += `?marina_id=${marina}`;
    }

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [STORE_LOCK_REQUEST, STORE_LOCK_SUCCESS, STORE_LOCK_FAILURE],
            endpoint,
        },
        ...payload,
    };
}

export const UPDATE_LOCK_REQUEST = 'UPDATE_LOCK_REQUEST';
export const UPDATE_LOCK_SUCCESS = 'UPDATE_LOCK_SUCCESS';
export const UPDATE_LOCK_FAILURE = 'UPDATE_LOCK_FAILURE';

export function updateLock(id, object) {
    const endpoint = `/lock-closures/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_PUT,
            types: [UPDATE_LOCK_REQUEST, UPDATE_LOCK_SUCCESS, UPDATE_LOCK_FAILURE],
            endpoint,
        },
        ...object,
    };
}

export const DELETE_LOCK_REQUEST = 'DELETE_LOCK_REQUEST';
export const DELETE_LOCK_SUCCESS = 'DELETE_LOCK_SUCCESS';
export const DELETE_LOCK_FAILURE = 'DELETE_LOCK_FAILURE';

export function deleteLock(id) {
    const endpoint = `/lock-closures/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [DELETE_LOCK_REQUEST, DELETE_LOCK_SUCCESS, DELETE_LOCK_FAILURE],
            endpoint,
        },
    };
}

export const RATE_FIELD_CATEGORIES_REQUEST = 'RATE_FIELD_CATEGORIES_REQUEST';
export const RATE_FIELD_CATEGORIES_SUCCESS = 'RATE_FIELD_CATEGORIES_SUCCESS';
export const RATE_FIELD_CATEGORIES_FAILURE = 'RATE_FIELD_CATEGORIES_FAILURE';

export function fetchRateFieldCategories(marina) {
    let endpoint = '/rateFieldCategories';
    if (marina) {
        endpoint += `?marina_id=${marina}`;
    }

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [RATE_FIELD_CATEGORIES_REQUEST, RATE_FIELD_CATEGORIES_SUCCESS, RATE_FIELD_CATEGORIES_FAILURE],
            endpoint,
        },
    };
}

export const STORE_RATE_FIELD_CAT_REQUEST = 'STORE_RATE_FIELD_CAT_REQUEST';
export const STORE_RATE_FIELD_CAT_SUCCESS = 'STORE_RATE_FIELD_CAT_SUCCESS';
export const STORE_RATE_FIELD_CAT_FAILURE = 'STORE_RATE_FIELD_CAT_FAILURE';

export function storeRateFieldCategory(data) {
    const endpoint = '/rateFieldCategories';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [STORE_RATE_FIELD_CAT_REQUEST, STORE_RATE_FIELD_CAT_SUCCESS, STORE_RATE_FIELD_CAT_FAILURE],
            endpoint,
        },
        ...data,
    };
}

export const RATE_FIELDS_REQUEST = 'RATE_FIELDS_REQUEST';
export const RATE_FIELDS_SUCCESS = 'RATE_FIELDS_SUCCESS';
export const RATE_FIELDS_FAILURE = 'RATE_FIELDS_FAILURE';

export function fetchRateFields(marina) {
    let endpoint = '/rateFields';

    if (marina) {
        endpoint += `?marina_id=${marina}`;
    }

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [RATE_FIELDS_REQUEST, RATE_FIELDS_SUCCESS, RATE_FIELDS_FAILURE],
            endpoint,
        },
    };
}

export const STORE_RATE_FIELD_REQUEST = 'STORE_RATE_FIELD_REQUEST';
export const STORE_RATE_FIELD_SUCCESS = 'STORE_RATE_FIELD_SUCCESS';
export const STORE_RATE_FIELD_FAILURE = 'STORE_RATE_FIELD_FAILURE';

export function storeRateField(data) {
    const endpoint = '/rateFields';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [STORE_RATE_FIELD_REQUEST, STORE_RATE_FIELD_SUCCESS, STORE_RATE_FIELD_FAILURE],
            endpoint,
        },
        ...data,
    };
}

export const UPDATE_RATE_FIELD_REQUEST = 'UPDATE_RATE_FIELD_REQUEST';
export const UPDATE_RATE_FIELD_SUCCESS = 'UPDATE_RATE_FIELD_SUCCESS';
export const UPDATE_RATE_FIELD_FAILURE = 'UPDATE_RATE_FIELD_FAILURE';

export function updateRateField(id, object) {
    const endpoint = `/rateFields/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_PUT,
            types: [UPDATE_RATE_FIELD_REQUEST, UPDATE_RATE_FIELD_SUCCESS, UPDATE_RATE_FIELD_FAILURE],
            endpoint,
        },
        ...object,
    };
}

export const DELETE_RATE_FIELD_REQUEST = 'DELETE_RATE_FIELD_REQUEST';
export const DELETE_RATE_FIELD_SUCCESS = 'DELETE_RATE_FIELD_SUCCESS';
export const DELETE_RATE_FIELD_FAILURE = 'DELETE_RATE_FIELD_FAILURE';

export function deleteRateField(id) {
    const endpoint = `/rateFields/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [
                DELETE_RATE_FIELD_REQUEST,
                DELETE_RATE_FIELD_SUCCESS,
                DELETE_RATE_FIELD_FAILURE,
            ],
            endpoint,
        },
    };
}

export const DELETE_RATE_FIELD_CAT_REQUEST = 'DELETE_RATE_FIELD_CAT_REQUEST';
export const DELETE_RATE_FIELD_CAT_SUCCESS = 'DELETE_RATE_FIELD_CAT_SUCCESS';
export const DELETE_RATE_FIELD_CAT_FAILURE = 'DELETE_RATE_FIELD_CAT_FAILURE';

export function deleteRateFieldCategory(id) {
    const endpoint = `/rateFieldCategories/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [
                DELETE_RATE_FIELD_CAT_REQUEST,
                DELETE_RATE_FIELD_CAT_SUCCESS,
                DELETE_RATE_FIELD_CAT_FAILURE,
            ],
            endpoint,
        },
    };
}

export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';

export function sendNotification(object) {
    const endpoint = '/notifications';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [SEND_NOTIFICATION_REQUEST, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_FAILURE],
            endpoint,
        },
        ...object,
    };
}

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

export function fetchNotifications(page = 1) {
    const endpoint = `/notifications?page=${page}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [NOTIFICATIONS_REQUEST, NOTIFICATIONS_SUCCESS, NOTIFICATIONS_FAILURE],
            endpoint,
        },
    };
}

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export function fetchUser(id) {
    const endpoint = `/users/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [USER_REQUEST, USER_SUCCESS, USER_FAILURE],
            endpoint,
        },
    };
}

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export function deleteUser(user) {
    const endpoint = `/users/${user}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
            endpoint,
        },
    };
}

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export function updateUser(id, object) {
    const endpoint = `/users/${id}`;

    return {
        [CALL_API]: {
            method: METHOD_PUT,
            types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
            endpoint,
        },
        ...object,
    };
}

export const TOKENS_REQUEST = 'TOKENS_REQUEST';
export const TOKENS_SUCCESS = 'TOKENS_SUCCESS';
export const TOKENS_FAILURE = 'TOKENS_FAILURE';

export function fetchTokens(id) {
    const endpoint = `/users/${id}/tokens`;

    return {
        [CALL_API]: {
            method: METHOD_GET,
            types: [TOKENS_REQUEST, TOKENS_SUCCESS, TOKENS_FAILURE],
            endpoint,
        },
    };
}

export const DELETE_TOKEN_REQUEST = 'DELETE_TOKEN_REQUEST';
export const DELETE_TOKEN_SUCCESS = 'DELETE_TOKEN_SUCCESS';
export const DELETE_TOKEN_FAILURE = 'DELETE_TOKEN_FAILURE';

export function deleteToken(user, token) {
    const endpoint = `/users/${user}/tokens/${token}`;

    return {
        [CALL_API]: {
            method: METHOD_DELETE,
            types: [DELETE_TOKEN_REQUEST, DELETE_TOKEN_SUCCESS, DELETE_TOKEN_FAILURE],
            endpoint,
        },
    };
}

export const NEW_TOKEN_REQUEST = 'NEW_TOKEN_REQUEST';
export const NEW_TOKEN_SUCCESS = 'NEW_TOKEN_SUCCESS';
export const NEW_TOKEN_FAILURE = 'NEW_TOKEN_FAILURE';

export function generateToken(id, payload) {
    const endpoint = `/users/${id}/tokens`;

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [NEW_TOKEN_REQUEST, NEW_TOKEN_SUCCESS, NEW_TOKEN_FAILURE],
            endpoint,
        },
        ...payload,
    };
}

export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export function clearToken() {
    return {
        type: CLEAR_TOKEN,
    };
}

export const CALCULATE_RATE_REQUEST = 'CALCULATE_RATE_REQUEST';
export const CALCULATE_RATE_SUCCESS = 'CALCULATE_RATE_SUCCESS';
export const CALCULATE_RATE_FAILURE = 'CALCULATE_RATE_FAILURE';

export function computeRate(payload) {
    const endpoint = '/rates/calculate';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [CALCULATE_RATE_REQUEST, CALCULATE_RATE_SUCCESS, CALCULATE_RATE_FAILURE],
            endpoint,
        },
        ...payload,
    };
}

export const VERIFY_PAYMENT_REQUEST = 'VERIFY_PAYMENT_REQUEST';
export const VERIFY_PAYMENT_SUCCESS = 'VERIFY_PAYMENT_SUCCESS';
export const VERIFY_PAYMENT_FAILURE = 'VERIFY_PAYMENT_FAILURE';

export function verifyPayment(payload) {
    const endpoint = '/payment/capture';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [VERIFY_PAYMENT_REQUEST, VERIFY_PAYMENT_SUCCESS, VERIFY_PAYMENT_FAILURE],
            endpoint,
            requireAuth: false,
        },
        ...payload,
    };
}

export const AUTHENTICATE_DEVICE_REQUEST = 'AUTHENTICATE_DEVICE_REQUEST';
export const AUTHENTICATE_DEVICE_SUCCESS = 'AUTHENTICATE_DEVICE_SUCCESS';
export const AUTHENTICATE_DEVICE_FAILURE = 'AUTHENTICATE_DEVICE_FAILURE';

export function authenticateDevice(payload) {
    const endpoint = '/payment/authenticate-device';

    return {
        [CALL_API]: {
            method: METHOD_POST,
            types: [AUTHENTICATE_DEVICE_REQUEST, AUTHENTICATE_DEVICE_SUCCESS, AUTHENTICATE_DEVICE_FAILURE],
            endpoint,
            requireAuth: false,
        },
        ...payload,
    };
}
