/* eslint-disable camelcase */
import React, { useState } from 'react';

import {
    PageHeader, Row, Col, Typography, List, Card, Empty, Button, Drawer, Tag, Skeleton,
} from 'antd';
import {
    EditTwoTone, PlusOutlined, DeleteTwoTone,
} from '@ant-design/icons';
import RateField from './RateField';
import useConfirm from './hooks/useConfirm';

const { Text } = Typography;

export default function Rates(props) {
    const [editVisible, setEditVisible] = useState(false);
    const [currentField, setCurrentField] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const {
        field,
        category,
        fields,
        categories,
        history,
        marina: { id, rates_freezed: ratesFreezed, marina_name },
        canAddFields,
        onRequestComplete,
        extra = [],
        children,
        deleteRateField,
        deleteRateFieldCategory,
        hasPermission = () => true,
    } = props;

    const confirmFieldDelete = useConfirm(
        field,
        onRequestComplete,
    );
    const confirmCatDelete = useConfirm(
        category,
        onRequestComplete,
    );
    const cats = categories.data.map(({ id, name }) => ({
        id,
        name,
        fields: fields.data.filter((f) => f.category_id === id),
    }));

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    const onAddNew = () => {
        setEditVisible(false);
        setCurrentField(null);
        toggleDrawer();
    };

    const onEdit = (field) => (e) => {
        e.preventDefault();
        setCurrentField(field);
        setEditVisible(true);
        toggleDrawer();
    };

    const onDeleteField = ({ id, category, label }) => (e) => {
        e.preventDefault();
        confirmFieldDelete(
            `Do you want to delete "${category}: ${label}"?`,
            () => deleteRateField(id),
        );
    };

    const onDeleteCat = (id, name) => (e) => {
        e.preventDefault();
        confirmCatDelete(
            `Do you want to delete "${name}"?`,
            () => deleteRateFieldCategory(id),
        );
    };

    const onCancel = () => {
        setCurrentField(null);
        toggleDrawer();
    };

    const drawerTitle = () => (currentField ? `Edit "${currentField.category}: ${currentField.label}"` : 'Add New Field');

    const getValue = (field) => {
        if (field.type === 'numeric') {
            return (
                <span>
                    &pound;
                    {' '}
                    {field.numeric_value}
                </span>
            );
        }

        return field[`${field.type}_value`];
    };

    const renderCategoryList = ({ id, name, fields }) => (
        <List.Item>
            <Card
                title={name}
                size="small"
                extra={!ratesFreezed && canAddFields ? (
                    <Button
                        type="link"
                        onClick={onDeleteCat(id, name)}
                        style={{ paddingRight: 6 }}
                    >
                        <DeleteTwoTone twoToneColor="#eb2f96" />
                    </Button>
                ) : null}
            >
                {fields.length ? fields.map((field) => {
                    const {
                        id, label, used_in_calculation: calc,
                    } = field;
                    return (
                        <Card.Grid
                            key={id}
                            style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}
                            hoverable={false}
                            className={currentField && currentField.id === id ? 'active' : ''}
                        >
                            <Text style={{ width: '70%' }} type={calc ? 'primary' : 'secondary'}>
                                {label}
                            </Text>
                            <Text style={{ width: '20%', paddingRight: '1rem', textAlign: 'end' }} type={calc ? 'primary' : 'secondary'}>{getValue(field)}</Text>
                            {hasPermission('edit.rates') ? <span style={{ width: ratesFreezed ? '10%' : '5%' }}><a href="#" onClick={onEdit({ ...field, category: name })}><EditTwoTone /></a></span> : null}
                            {!ratesFreezed && canAddFields ? (
                                <span style={{ width: '5%' }}><a href="#" onClick={onDeleteField({ ...field, category: name })}><DeleteTwoTone twoToneColor="#eb2f96" /></a></span>
                            ) : null}
                        </Card.Grid>
                    );
                }) : <Empty />}
            </Card>
        </List.Item>
    );

    if (!ratesFreezed && canAddFields) {
        extra.push(
            <Button key="add_field" type="primary" onClick={onAddNew}>
                <PlusOutlined />
                {' '}
                New Field
            </Button>,
        );
    }

    return (
        <PageHeader
            title={`${marina_name} Rates`}
            tags={ratesFreezed && canAddFields ? <Tag color="green">Rates Frozen</Tag> : null}
            className="rates-page-header"
            onBack={() => history.goBack()}
            extra={extra}
        >
            <Row gutter={[16, 16]} id="rates">
                <Col span={24}>
                    <Skeleton
                        loading={fields.isFetching || categories.isFetching}
                        active={fields.isFetching || categories.isFetching}
                    >
                        <List
                            loading={fields.isFetching || categories.isFetching}
                            grid={{
                                gutter: 16, xxl: 3, xl: 2, lg: 2, md: 2,
                            }}
                            dataSource={cats}
                            renderItem={renderCategoryList}
                        />
                    </Skeleton>
                </Col>
                <Drawer
                    title={drawerTitle()}
                    width="35%"
                    onClose={toggleDrawer}
                    visible={drawerVisible}
                    maskClosable={false}
                    destroyOnClose
                >
                    <RateField
                        {...props}
                        onFinish={onRequestComplete}
                        isEdit={editVisible}
                        currentField={currentField}
                        onCancel={onCancel}
                    />
                </Drawer>
                {children}
            </Row>
        </PageHeader>
    );
}
