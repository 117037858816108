import React from 'react';
import { connect } from 'react-redux';
import {
    Switch,
} from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PrivateRoute from '../PrivateRoute';
import Layout from '../Layout';
import Sidebar from './Sidebar';
import MarinaIndex from './Index';
import StationsIndex from './StationsIndex';
import Users from '../shared/Users';
import User from '../shared/User';

function Root(props) {
    const renderRoutes = ({ match }) => (
        <Switch>
            <PrivateRoute path="/marina" component={MarinaIndex} />
            <PrivateRoute path="/stations" component={StationsIndex} />
            <PrivateRoute path="/users/:user" component={User} />
            <PrivateRoute path="/users" component={Users} />
        </Switch>
    );

    const renderLoading = () => {
        const icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
            }}
            >
                <Spin size="large" tip="LOADING" indicator={icon} />
            </div>
        );
    };

    const { me: { isFetching } } = props;
    const renderSidebar = (props) => <Sidebar {...props} />;

    return (
        <Layout renderSidebar={renderSidebar} renderFooter={false}>
            {isFetching ? renderLoading() : renderRoutes(props)}
        </Layout>
    );
}

function mapStateToProps(state) {
    return { me: state.me, auth: state.auth };
}

export default connect(mapStateToProps)(Root);
