import React, { useState } from 'react';
import { Menu, Layout } from 'antd';
import {
    ShopOutlined, EnvironmentOutlined, TeamOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Sider } = Layout;

export default function Sidebar(props) {
    const [collapsed, setCollapsed] = useState(false);

    const { auth: { isAuthenticated }, location: { pathname } } = props;

    if (!isAuthenticated) {
        return <div />;
    }
    const menuItems = [
        {
            key: 'marina', label: 'Marinas', icon: ShopOutlined, to: '/marina',
        },
        {
            key: 'stations', label: 'Tide Stations', icon: EnvironmentOutlined, to: '/stations',
        },
        {
            key: 'users', label: 'Admin Users', icon: TeamOutlined, to: '/users',
        },
    ];

    let selectedKeys = [];
    if (/^\/marina.*/.test(pathname)) {
        selectedKeys = ['marina'];
    } else if (/^\/stations.*/.test(pathname)) {
        selectedKeys = ['stations'];
    } else if (/^\/users.*/.test(pathname)) {
        selectedKeys = ['users'];
    }

    return (
        <Sider
            id="sider"
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            breakpoint="sm"
            theme="light"
        >
            <Menu
                selectedKeys={selectedKeys}
                style={{ height: '100%', paddingTop: '24px' }}
                mode="inline"
            >
                {menuItems.map((i) => (
                    <Menu.Item key={i.key} style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                        <Link to={i.to} style={{ display: 'inline-block', width: '100%' }}>
                            <i.icon style={{ marginRight: 14 }} />
                            {' '}
                            {i.label}
                        </Link>
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    );
}
