/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button, Form, message, Input, Alert,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import {
    generateToken, deleteToken, clearToken,
} from '../../actions';

dayjs.extend(customParse);

function AccessToken(props) {
    const {
        userId,
        token,
        generateToken,
        clearToken,
        onAddNew,
    } = props;
    const { id, error, isFetching } = token;
    const [values, setValues] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!error) return;

        const { res: { errors: serverErrors, message: msg } } = error;
        message.error(msg);

        if (!serverErrors) return;

        const fields = [];
        Object.keys(serverErrors).forEach((name) => {
            const fieldErrors = serverErrors[name];
            fields.push({
                name,
                value: values[name],
                errors: fieldErrors,
            });
        });
        form.setFields(fields);
    }, [error]);

    useEffect(() => {
        if (!submitted || isFetching) return;
        if (!error) {
            setSubmitted(false);
            onAddNew(true);
            form.resetFields();
            message.success('Successfully Generated Access Token');
        }
        // eslint-disable-next-line consistent-return
        return () => clearToken();
    }, [isFetching]);

    const onFinish = (formValues) => {
        setSubmitted(true);
        onAddNew(false);
        setValues(formValues);

        generateToken(userId, { ...formValues });
    };

    const makeProperCase = (text) => text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    const CreateForm = (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            colon={false}
        >
            <Form.Item
                label="Token Name"
                name="name"
                hasFeedback
                rules={[
                    { required: true, message: 'Token Name is required', whitespace: true },
                ]}
                normalize={makeProperCase}
            >
                <Input placeholder="Token Name" />
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={token.isFetching} style={{ float: 'right' }}>
                {token.isFetching ? null : <SaveOutlined />}
                {' '}
                Generate
            </Button>
        </Form>
    );

    return (
        <>
            {token.plainTextToken ? (
                <Alert
                    message={`Access Token: ${token.name}`}
                    description={(
                        <div>
                            <p>{token.plainTextToken}</p>
                            <p>Token is visible this time only, please make sure to copy it to a secret place before navigating away.</p>
                        </div>
                    )}
                    type="info"
                    showIcon
                />
            ) : null}
            {CreateForm}
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { user: { token } } = state;
    const { userId } = ownProps;

    return {
        ...ownProps,
        token,
        userId,
    };
};

export default connect(mapStateToProps, {
    generateToken, deleteToken, clearToken,
})(AccessToken);
