import {
    STORE_LOCK_REQUEST,
    STORE_LOCK_SUCCESS,
    STORE_LOCK_FAILURE,
    UPDATE_LOCK_REQUEST,
    UPDATE_LOCK_SUCCESS,
    UPDATE_LOCK_FAILURE,
    DELETE_LOCK_REQUEST,
    DELETE_LOCK_SUCCESS,
    DELETE_LOCK_FAILURE,
} from '../actions';

export const LOCK_INITIAL_STATE = {
    id: null,
    day: null,
    lock_date: null,
    lock_open: '',
    lock_close: '',
    lock_1_open: '',
    lock_1_close: '',
    notes: '',
    isFetching: false,
    error: null,
};

const lockReducers = {
    [STORE_LOCK_REQUEST]: (state, action) => ({
        ...state, ...action, isFetching: true, error: null, draft: true,
    }),
    [STORE_LOCK_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [STORE_LOCK_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [UPDATE_LOCK_REQUEST]: (state, action) => ({ ...state, isFetching: true, error: null }),
    [UPDATE_LOCK_SUCCESS]: (state, { res }) => ({
        ...state, ...res.data, isFetching: false, error: null,
    }),
    [UPDATE_LOCK_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
    [DELETE_LOCK_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [DELETE_LOCK_SUCCESS]: (state) => ({ ...LOCK_INITIAL_STATE }),
    [DELETE_LOCK_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

const lockReducer = (state = LOCK_INITIAL_STATE, action) => {
    const reducer = lockReducers[action.type] || ((state) => state);
    return reducer(state, action);
};

export default lockReducer;
