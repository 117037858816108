import React, { Component } from 'react';
import {
    Link,
    NavLink,
    withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Layout as AntLayout, Menu,
} from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { logoutUser } from '../actions';

const { Header, Content } = AntLayout;
const { SubMenu } = Menu;

class Layout extends Component {
    constructor(props) {
        super(props);
        this.logoutUser = this.logoutUser.bind(this);
    }

    logoutUser({ item, key, keyPath }) {
        const { history, logoutUser } = this.props;
        if (key !== 'logout') return;
        logoutUser().then(() => history.replace('/'));
    }

    renderLogin() {
        const {
            auth: { isAuthenticated },
            me: { name, first_name: first, last_name: last },
        } = this.props;
        const fullName = [first, last].join(' ');
        if (isAuthenticated) {
            return (
                <SubMenu title={(
                    <span>
                        <UserOutlined />
                        {fullName || name}
                    </span>
                )}
                >
                    <Menu.Item key="logout">
                        <LogoutOutlined />
                        <span>Logout</span>
                    </Menu.Item>
                </SubMenu>
            );
        }
        return <Menu.Item key="2"><NavLink to="/login"><span>LOGIN</span></NavLink></Menu.Item>;
    }

    render() {
        const { renderSidebar = () => {}, auth: { isAuthenticated }, children } = this.props;

        return (
            <AntLayout style={{ height: '100vh' }}>
                <Header id="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to="/" id="logo">
                        <img src="/img/logo.jpg" alt="Myhomeport Logo" />
                        My Home Port
                    </Link>
                    <Menu
                        theme="light"
                        mode="horizontal"
                        style={{ lineHeight: '64px' }}
                        onClick={this.logoutUser}
                    >
                        {this.renderLogin()}
                    </Menu>
                </Header>
                <AntLayout>
                    {renderSidebar({ ...this.props })}
                    <AntLayout style={{ background: '#fff', overflowX: 'hidden' }}>
                        <Content style={{ margin: 0, position: 'relative' }}>
                            {children}
                        </Content>
                    </AntLayout>
                </AntLayout>
            </AntLayout>
        );
    }
}

function mapStateToProps(state) {
    const { auth, me } = state;
    return { auth, me };
}

export default withRouter(connect(mapStateToProps, { logoutUser })(Layout));
