import React, { useState, useEffect } from 'react';
import { Table } from 'antd';

function PaginatedList(props) {
    const {
        meta, fetchData, location: { search }, history, match: { url }, query = '',
    } = props;
    const [full, page, term] = search.match(/page=(\d+)(?:&query=([\w/-]+))?/) || ['', 1, ''];
    const { current_page: current = +page, per_page: pageSize = 15, total = 0 } = meta;
    const [pagination, setPagination] = useState({ current, pageSize, total });

    useEffect(() => {
        if (+page === pagination.current) fetchData(pagination.current, term);
    }, [pagination.current, pagination.pageSize, term]);

    useEffect(() => {
        setPagination({
            current,
            total,
            pageSize,
        });
    }, [meta.total]);

    useEffect(() => {
        setPagination({ ...pagination, current: +page });
    }, [page]);

    useEffect(() => {
        if (term !== query) {
            setPagination({ ...pagination, current: 1 });
            if (query) {
                history.push(`${url}?page=1&query=${query}`);
            } else {
                history.push(`${url}?page=1`);
            }
        }
    }, [query]);

    const handleTableChange = (paginationOptions) => {
        history.push(`${url}?page=${paginationOptions.current}`);
    };

    const { source, columns, loading } = props;
    return (
        <Table
            columns={columns}
            dataSource={source}
            loading={loading}
            pagination={pagination}
            rowKey={(record) => record.id}
            onChange={handleTableChange}
            size="small"
        />
    );
}
export default PaginatedList;
