import React, {
    useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { Alert, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {
    withRouter,
} from 'react-router-dom';
import { verifyPayment } from '../actions';

const checkoutScriptUrl = process.env.WP_SCRIPT_SRC;
const merchant = process.env.WP_CHECKOUT_ID;

const loadingIcon = <LoadingOutlined style={{ fontSize: '1rem', color: '#bbb' }} spin />;

function scriptAlreadyLoaded(src) {
    return document.querySelector(`script[src="${src}"]`);
}

function loadCheckoutScript(src) {
    return new Promise((resolve, reject) => {
        if (scriptAlreadyLoaded(src)) {
            resolve();
            return;
        }

        const checkoutScript = document.createElement('script');
        checkoutScript.src = src;
        checkoutScript.onload = resolve;
        checkoutScript.onerror = reject;
        document.head.appendChild(checkoutScript);
    });
}

function addWorldpayCheckoutToPage() {
    return new Promise((resolve, reject) => {
        (function () {
            window.Worldpay.checkout.init(
                {
                    id: merchant,
                    form: '#wp-checkout',
                    acceptedCardBrands: ['amex', 'visa', 'mastercard'],
                    fields: {
                        pan: {
                            selector: '#card-pan',
                        },
                        expiry: {
                            selector: '#card-expiry',
                        },
                        cvv: {
                            selector: '#card-cvv',
                        },
                    },
                    styles: {
                        'input.is-valid': {
                            color: 'green',
                        },
                        'input.is-invalid': {
                            color: 'red',
                        },
                        'input.is-onfocus': {
                            color: 'black',
                        },
                    },
                    enablePanFormatting: true,
                },
                (error, checkout) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(checkout);
                    }
                },
            );
        }());
    });
}

function Verification(props) {
    const {
        jwt, url, bin, onVerificationResponse,
    } = props;
    const formRef = useRef();

    useEffect(() => {
        if (!formRef.current) return;

        const messageHandler = (event) => {
            if (event.origin !== url) return;

            onVerificationResponse(event);
        };
        window.addEventListener('message', messageHandler);
        formRef.current.submit();

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, []);

    return (
        <iframe height="1" width="1" style={{ display: 'none' }} title="customer-verification">
            <form id="collectionForm" name="devicedata" method="POST" action={url} ref={formRef}>
                <input type="hidden" name="Bin" value={bin} />
                <input type="hidden" name="JWT" value={jwt} />
            </form>
        </iframe>
    );
}

function Checkout(props) {
    const {
        capturePayment, transactionRef, amount, checkout: checkoutState,
    } = props;
    const [error, setError] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const capturePaymentRequested = useRef();
    const checkoutInstance = useRef();

    useEffect(() => {
        if (!capturePaymentRequested.current) return;
        if (checkoutState.isFetching || checkoutState.error) return;

        capturePaymentRequested.current = false;
        setIsSuccess(true);
    }, [checkoutState.error, checkoutState.isFetching]);

    function generateSession() {
        checkoutInstance.current.generateSessionState(
            (error, session) => {
                if (error) {
                    setError(error);
                    console.warn(`Failed to generate session: ${error}`);
                    return;
                }

                capturePaymentRequested.current = true;
                capturePayment({ session, transaction_ref: transactionRef });
            },
        );
    }

    function clearForm() {
        checkoutInstance.current.clearForm();
    }

    useEffect(() => {
        loadCheckoutScript(checkoutScriptUrl)
            .then(() => {
                addWorldpayCheckoutToPage()
                    .then((instance) => {
                        checkoutInstance.current = instance;
                    })
                    .catch(console.warn);
            })
            .catch(console.warn);
    }, []);

    // Make sure to call the remove method (once) in order to deallocate the SDK from memory
    useLayoutEffect(() => () => checkoutInstance.current.remove(), []);

    return (
        <section className="container" id="wp-checkout">
            <section className="card">
                <section className="amount">
                    <span>
                        GBP
                        {' '}
                        {amount}
                    </span>
                </section>
                <section id="card-pan" className="field" />
                <section className="columns">
                    <section id="card-expiry" className="field" />
                    <section id="card-cvv" className="field" />
                </section>
                <section className="buttons">
                    <button className="clear" type="button" onClick={clearForm}>
                        Clear
                    </button>
                    <button className="submit" type="button" onClick={generateSession} disabled={checkoutState.isFetching}>
                        {checkoutState.isFetching ? <Spin indicator={loadingIcon} style={{ paddingRight: 5 }} /> : null}
                        {' '}
                        Pay now
                    </button>
                </section>
            </section>
            <div id="info" className="info">
                {(error || checkoutState.error) ? (
                    <Alert
                        message="Error in processing the payment"
                        description={error || checkoutState.error.message}
                        type="warning"
                        showIcon
                        closable
                    />
                ) : null}
                {(isSuccess) ? (
                    <Alert
                        message="Payment success"
                        description="Payment request processed successfully"
                        type="success"
                        showIcon
                        closable
                    />
                ) : null}
            </div>
        </section>
    );
}

const mapStateToProps = (state, ownProps) => {
    const encoded = ownProps.match.params.ref;
    let transactionRef;
    let amount;
    try {
        ({ ref: transactionRef, amount } = JSON.parse(atob(decodeURIComponent(encoded))));
    } catch (e) {
        console.error(e);
    }
    return {
        checkout: state.checkout,
        transactionRef,
        amount,
    };
};

export default withRouter(connect(mapStateToProps, {
    capturePayment: verifyPayment,
})(Checkout));
