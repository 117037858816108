import {
    BUSINESS_TYPES_REQUEST,
    BUSINESS_TYPES_SUCCESS,
    BUSINESS_TYPES_FAILURE,
} from '../actions';

export const BUSINESS_TYPES_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

const businessTypesReducers = {
    [BUSINESS_TYPES_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [BUSINESS_TYPES_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [BUSINESS_TYPES_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function businessTypes(state = BUSINESS_TYPES_INITIAL_STATE, action) {
    const reducer = businessTypesReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
