/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { AutoComplete, Input } from 'antd';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { fetchLocations } from '../../actions';

function Lookup(props) {
    const {
        locations, onSearch, onChange, placeholder, style, isFetching, onUpdate,
        value = { },
    } = props;
    const [options, setOptions] = useState([]);
    const [currentValue, setCurrentValue] = useState(value && value.location_name);
    const debounced = debounce(onSearch, 300);

    useEffect(() => {
        setOptions(locations.map(({
            location_id, location_name, ...rest
        }) => ({ value: `${location_id}: ${location_name}`, ...rest })));
    }, [isFetching]);

    const handleSelect = (value, option) => {
        onUpdate(option);
        onChange(option);
    };

    const handleChange = (value, option) => {
        setCurrentValue(option.label);
    };

    // eslint-disable-next-line no-underscore-dangle
    const _onSearch = (text) => {
        debounced(text);
    };

    return (
        <AutoComplete
            onSelect={handleSelect}
            onChange={handleChange}
            onSearch={_onSearch}
            style={style}
            options={options}
            value={currentValue}
        >
            <Input.Search placeholder={placeholder} loading={isFetching} />
        </AutoComplete>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { locations } = state;

    return {
        locations: locations.data,
        isFetching: locations.isFetching,
        error: locations.error,
        ...ownProps,
    };
};

export default connect(mapStateToProps, {
    onSearch: fetchLocations,
})(Lookup);
